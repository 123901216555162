export default {
  points: {
    type: 'FeatureCollection',
    // crs: { type: 'name', properties: { name: 'EPSG:3857' } },
    features: [
      {
        type: 'Feature',
        id: 71133158,
        geometry: {
          type: 'Point',
          coordinates: [-13055513.442549346, 8027971.9682196463],
        },
        properties: {
          LABEL: 'HWF-042-2019',
          LATITUDE: 58.287567000000003,
          LONGITUDE: -117.27965500000001,
          FIRE_NUMBER: 'HWF042',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2020/10/13 13:00:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 334772.59999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1557691080000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133158,
        },
      },
      {
        type: 'Feature',
        id: 71132337,
        geometry: {
          type: 'Point',
          coordinates: [-12735658.395037383, 7502159.6863255454],
        },
        properties: {
          LABEL: 'SWF-049-2019',
          LATITUDE: 55.716366999999998,
          LONGITUDE: -114.40635,
          FIRE_NUMBER: 'SWF049',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2020/10/19 10:48:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 222837.20000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558212360000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132337,
        },
      },
      {
        type: 'Feature',
        id: 71132911,
        geometry: {
          type: 'Point',
          coordinates: [-13072698.802657463, 8312727.9772893786],
        },
        properties: {
          LABEL: 'HWF-066-2019',
          LATITUDE: 59.606867000000001,
          LONGITUDE: -117.434033,
          FIRE_NUMBER: 'HWF066',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2020/05/08 09:47:54',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 64659.099999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1558999440000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132911,
        },
      },
      {
        type: 'Feature',
        id: 71130378,
        geometry: {
          type: 'Point',
          coordinates: [-12730899.294010308, 7638983.7882972108],
        },
        properties: {
          LABEL: 'SWF-069-2019',
          LATITUDE: 56.402597,
          LONGITUDE: -114.363598,
          FIRE_NUMBER: 'SWF069',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/02 15:01:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 58578.900000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558914960000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130378,
        },
      },
      {
        type: 'Feature',
        id: 71132980,
        geometry: {
          type: 'Point',
          coordinates: [-13127779.559635287, 7778008.3238527123],
        },
        properties: {
          LABEL: 'PWF-052-2019',
          LATITUDE: 57.087417000000002,
          LONGITUDE: -117.928833,
          FIRE_NUMBER: 'PWF052',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2020/10/16 15:45:59',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 55179,
          ASSESSMENT_ASSISTANCE_DATE: 1557634680000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132980,
        },
      },
      {
        type: 'Feature',
        id: 71132455,
        geometry: {
          type: 'Point',
          coordinates: [-12280050.011367017, 8104668.6230235994],
        },
        properties: {
          LABEL: 'MWF-051-2019',
          LATITUDE: 58.647883,
          LONGITUDE: -110.31355000000001,
          FIRE_NUMBER: 'MWF051',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/30 16:44:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 27729.299999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563401940000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132455,
        },
      },
      {
        type: 'Feature',
        id: 71134101,
        geometry: {
          type: 'Point',
          coordinates: [-12778737.311366418, 8150190.3626702726],
        },
        properties: {
          LABEL: 'HWF-133-2019',
          LATITUDE: 58.859999999999999,
          LONGITUDE: -114.793333,
          FIRE_NUMBER: 'HWF133',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/09/27 10:30:55',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 9143.7999999999993,
          ASSESSMENT_ASSISTANCE_DATE: 1563572100000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71134101,
        },
      },
      {
        type: 'Feature',
        id: 71132494,
        geometry: {
          type: 'Point',
          coordinates: [-12348175.704087095, 8260283.4288888946],
        },
        properties: {
          LABEL: 'MWF-054-2019',
          LATITUDE: 59.367666999999997,
          LONGITUDE: -110.925533,
          FIRE_NUMBER: 'MWF054',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/30 12:42:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 6495.6000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1563407940000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132494,
        },
      },
      {
        type: 'Feature',
        id: 71135419,
        geometry: {
          type: 'Point',
          coordinates: [-12683245.664349126, 8213914.9895734508],
        },
        properties: {
          LABEL: 'HNZ-001-2019',
          LATITUDE: 59.154767,
          LONGITUDE: -113.935517,
          FIRE_NUMBER: 'HNZ001',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2020/06/23 09:11:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 5706.3000000000002,
          ASSESSMENT_ASSISTANCE_DATE: null,
          GENERAL_CAUSE: null,
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71135419,
        },
      },
      {
        type: 'Feature',
        id: 71132358,
        geometry: {
          type: 'Point',
          coordinates: [-12261394.759443916, 8134521.3559765518],
        },
        properties: {
          LABEL: 'MWF-052-2019',
          LATITUDE: 58.787132999999997,
          LONGITUDE: -110.145967,
          FIRE_NUMBER: 'MWF052',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/29 08:15:01',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 1598.3,
          ASSESSMENT_ASSISTANCE_DATE: 1563402720000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132358,
        },
      },
      {
        type: 'Feature',
        id: 71133538,
        geometry: {
          type: 'Point',
          coordinates: [-12375478.547956366, 7578822.5543473382],
        },
        properties: {
          LABEL: 'LWF-123-2019',
          LATITUDE: 56.102367000000001,
          LONGITUDE: -111.1708,
          FIRE_NUMBER: 'LWF123',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/27 16:00:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 1547.4000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1560714300000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133538,
        },
      },
      {
        type: 'Feature',
        id: 71135417,
        geometry: {
          type: 'Point',
          coordinates: [-12255923.059219176, 6417785.3726146445],
        },
        properties: {
          LABEL: 'CCU-003-2019',
          LATITUDE: 49.8352,
          LONGITUDE: -110.096817,
          FIRE_NUMBER: 'CCU003',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2020/06/23 08:46:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 1522.04,
          ASSESSMENT_ASSISTANCE_DATE: 1564007400000,
          GENERAL_CAUSE: null,
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71135417,
        },
      },
      {
        type: 'Feature',
        id: 71132127,
        geometry: {
          type: 'Point',
          coordinates: [-12750988.982141253, 7490074.5693765432],
        },
        properties: {
          LABEL: 'SWF-050-2019',
          LATITUDE: 55.655166999999999,
          LONGITUDE: -114.544067,
          FIRE_NUMBER: 'SWF050',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/01 09:38:44',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 1456.4000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558212360000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132127,
        },
      },
      {
        type: 'Feature',
        id: 71132477,
        geometry: {
          type: 'Point',
          coordinates: [-12352121.982735423, 8266778.5107169552],
        },
        properties: {
          LABEL: 'MWF-055-2019',
          LATITUDE: 59.397382999999998,
          LONGITUDE: -110.960983,
          FIRE_NUMBER: 'MWF055',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 18:07:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 1038.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563408360000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132477,
        },
      },
      {
        type: 'Feature',
        id: 71132984,
        geometry: {
          type: 'Point',
          coordinates: [-12687507.163978226, 7616599.8907728717],
        },
        properties: {
          LABEL: 'SWF-079-2019',
          LATITUDE: 56.291167000000002,
          LONGITUDE: -113.9738,
          FIRE_NUMBER: 'SWF079',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/13 10:00:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 1001.3,
          ASSESSMENT_ASSISTANCE_DATE: 1559226960000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132984,
        },
      },
      {
        type: 'Feature',
        id: 71134236,
        geometry: {
          type: 'Point',
          coordinates: [-12863989.586309282, 8164731.159756518],
        },
        properties: {
          LABEL: 'HWF-132-2019',
          LATITUDE: 58.927483000000002,
          LONGITUDE: -115.559167,
          FIRE_NUMBER: 'HWF132',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/09/27 10:30:36',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 728.79999999999995,
          ASSESSMENT_ASSISTANCE_DATE: 1563571380000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71134236,
        },
      },
      {
        type: 'Feature',
        id: 71131389,
        geometry: {
          type: 'Point',
          coordinates: [-12365725.225433635, 8256166.2922032932],
        },
        properties: {
          LABEL: 'MWF-056-2019',
          LATITUDE: 59.348816999999997,
          LONGITUDE: -111.08318300000001,
          FIRE_NUMBER: 'MWF056',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/30 12:42:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 672.60000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563480300000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71131389,
        },
      },
      {
        type: 'Feature',
        id: 71135416,
        geometry: {
          type: 'Point',
          coordinates: [-12280338.079702264, 6284599.9658871368],
        },
        properties: {
          LABEL: 'CCU-002-2019',
          LATITUDE: 49.057353999999997,
          LONGITUDE: -110.316141,
          FIRE_NUMBER: 'CCU002',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2020/06/23 08:38:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 589.53999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1562792400000,
          GENERAL_CAUSE: null,
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71135416,
        },
      },
      {
        type: 'Feature',
        id: 71131538,
        geometry: {
          type: 'Point',
          coordinates: [-12321722.525550235, 8311771.1118759336],
        },
        properties: {
          LABEL: 'MWF-063-2019',
          LATITUDE: 59.602516999999999,
          LONGITUDE: -110.6879,
          FIRE_NUMBER: 'MWF063',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/09 14:23:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 436.89999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1563555300000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71131538,
        },
      },
      {
        type: 'Feature',
        id: 71132224,
        geometry: {
          type: 'Point',
          coordinates: [-12994997.780223491, 7413811.2162906639],
        },
        properties: {
          LABEL: 'SWF-018-2019',
          LATITUDE: 55.266750000000002,
          LONGITUDE: -116.736035,
          FIRE_NUMBER: 'SWF018',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/07 13:10:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 234.19999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1556240340000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132224,
        },
      },
      {
        type: 'Feature',
        id: 71132934,
        geometry: {
          type: 'Point',
          coordinates: [-12736291.157017916, 8062875.5956726093],
        },
        properties: {
          LABEL: 'HWF-007-2019',
          LATITUDE: 58.451999999999998,
          LONGITUDE: -114.41203299999999,
          FIRE_NUMBER: 'HWF007',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/05 10:55:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 225.5,
          ASSESSMENT_ASSISTANCE_DATE: 1554227160000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132934,
        },
      },
      {
        type: 'Feature',
        id: 71132897,
        geometry: {
          type: 'Point',
          coordinates: [-12574423.531644788, 7943501.3823949117],
        },
        properties: {
          LABEL: 'MWF-075-2019',
          LATITUDE: 57.88644,
          LONGITUDE: -112.95795200000001,
          FIRE_NUMBER: 'MWF075',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/12 09:15:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 224.90000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563929820000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132897,
        },
      },
      {
        type: 'Feature',
        id: 71130370,
        geometry: {
          type: 'Point',
          coordinates: [-12718933.232776808, 7671787.9717036514],
        },
        properties: {
          LABEL: 'SWF-090-2019',
          LATITUDE: 56.565313000000003,
          LONGITUDE: -114.25610500000001,
          FIRE_NUMBER: 'SWF090',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/05 09:44:10',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 215.69999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1559509080000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130370,
        },
      },
      {
        type: 'Feature',
        id: 71130288,
        geometry: {
          type: 'Point',
          coordinates: [-12786715.185406938, 7904900.8498796914],
        },
        properties: {
          LABEL: 'HWF-140-2019',
          LATITUDE: 57.701633000000001,
          LONGITUDE: -114.86499999999999,
          FIRE_NUMBER: 'HWF140',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/15 09:50:00',
          SIZE_CLASS: 'E',
          AREA_ESTIMATE: 214.30000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563740100000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130288,
        },
      },
      {
        type: 'Feature',
        id: 71130550,
        geometry: {
          type: 'Point',
          coordinates: [-13084105.334745452, 8147270.372160092],
        },
        properties: {
          LABEL: 'HWF-088-2019',
          LATITUDE: 58.846432999999998,
          LONGITUDE: -117.5365,
          FIRE_NUMBER: 'HWF088',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/13 10:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 188,
          ASSESSMENT_ASSISTANCE_DATE: 1560898320000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130550,
        },
      },
      {
        type: 'Feature',
        id: 71133448,
        geometry: {
          type: 'Point',
          coordinates: [-12590887.469631404, 7959522.612844361],
        },
        properties: {
          LABEL: 'MWF-076-2019',
          LATITUDE: 57.962867000000003,
          LONGITUDE: -113.10585,
          FIRE_NUMBER: 'MWF076',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/12 09:15:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 183.19999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563930780000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71133448,
        },
      },
      {
        type: 'Feature',
        id: 71133471,
        geometry: {
          type: 'Point',
          coordinates: [-13173687.767022068, 7936282.3138868595],
        },
        properties: {
          LABEL: 'PWF-070-2019',
          LATITUDE: 57.851950000000002,
          LONGITUDE: -118.341233,
          FIRE_NUMBER: 'PWF070',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/05 08:25:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 174.09999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1558904520000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133471,
        },
      },
      {
        type: 'Feature',
        id: 71130513,
        geometry: {
          type: 'Point',
          coordinates: [-12898199.818504756, 7046514.4053816758],
        },
        properties: {
          LABEL: 'EWF-043-2019',
          LATITUDE: 53.342011999999997,
          LONGITUDE: -115.866485,
          FIRE_NUMBER: 'EWF043',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/20 14:57:36',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 163.90000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1559160360000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71130513,
        },
      },
      {
        type: 'Feature',
        id: 71132937,
        geometry: {
          type: 'Point',
          coordinates: [-13207055.803849738, 7984452.0840515876],
        },
        properties: {
          LABEL: 'HWF-113-2019',
          LATITUDE: 58.081467000000004,
          LONGITUDE: -118.64098300000001,
          FIRE_NUMBER: 'HWF113',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/30 18:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 156.30000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563308880000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132937,
        },
      },
      {
        type: 'Feature',
        id: 71130548,
        geometry: {
          type: 'Point',
          coordinates: [-12658943.913890459, 7625733.1833228292],
        },
        properties: {
          LABEL: 'SWF-102-2019',
          LATITUDE: 56.336672999999998,
          LONGITUDE: -113.717212,
          FIRE_NUMBER: 'SWF102',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/24 14:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 149.80000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1560116580000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130548,
        },
      },
      {
        type: 'Feature',
        id: 71131553,
        geometry: {
          type: 'Point',
          coordinates: [-12540385.435849916, 7783237.8021720257],
        },
        properties: {
          LABEL: 'MWF-073-2019',
          LATITUDE: 57.112932999999998,
          LONGITUDE: -112.65218299999999,
          FIRE_NUMBER: 'MWF073',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/09 12:03:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 145.09999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563920640000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71131553,
        },
      },
      {
        type: 'Feature',
        id: 71133055,
        geometry: {
          type: 'Point',
          coordinates: [-12687613.47685067, 7628600.4861042872],
        },
        properties: {
          LABEL: 'SWF-078-2019',
          LATITUDE: 56.350948000000002,
          LONGITUDE: -113.974755,
          FIRE_NUMBER: 'SWF078',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 08:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 113,
          ASSESSMENT_ASSISTANCE_DATE: 1559226900000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133055,
        },
      },
      {
        type: 'Feature',
        id: 71130493,
        geometry: {
          type: 'Point',
          coordinates: [-12635715.450178716, 7727191.4728521081],
        },
        properties: {
          LABEL: 'SWF-093-2019',
          LATITUDE: 56.838545000000003,
          LONGITUDE: -113.50854699999999,
          FIRE_NUMBER: 'SWF093',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/09 08:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 100.3,
          ASSESSMENT_ASSISTANCE_DATE: 1559515980000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130493,
        },
      },
      {
        type: 'Feature',
        id: 71135409,
        geometry: {
          type: 'Point',
          coordinates: [-12356130.025966385, 7212678.5064779976],
        },
        properties: {
          LABEL: 'LMD-001-2019',
          LATITUDE: 54.223911999999999,
          LONGITUDE: -110.99699,
          FIRE_NUMBER: 'LMD001',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2019/06/07 19:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 94.299999999999997,
          ASSESSMENT_ASSISTANCE_DATE: 1559605500000,
          GENERAL_CAUSE: null,
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71135409,
        },
      },
      {
        type: 'Feature',
        id: 71132175,
        geometry: {
          type: 'Point',
          coordinates: [-13112651.242429586, 7797553.954284139],
        },
        properties: {
          LABEL: 'PWF-054-2019',
          LATITUDE: 57.182698000000002,
          LONGITUDE: -117.792933,
          FIRE_NUMBER: 'PWF054',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/28 19:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 74.230000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1557662700000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132175,
        },
      },
      {
        type: 'Feature',
        id: 71135418,
        geometry: {
          type: 'Point',
          coordinates: [-13271489.326204266, 7561911.4256725218],
        },
        properties: {
          LABEL: 'GCU-001-2019',
          LATITUDE: 56.01755,
          LONGITUDE: -119.21980000000001,
          FIRE_NUMBER: 'GCU001',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2020/06/23 08:53:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 71.299999999999997,
          ASSESSMENT_ASSISTANCE_DATE: 1557619200000,
          GENERAL_CAUSE: null,
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71135418,
        },
      },
      {
        type: 'Feature',
        id: 71133506,
        geometry: {
          type: 'Point',
          coordinates: [-12425906.278489402, 7527444.2203293694],
        },
        properties: {
          LABEL: 'LWF-086-2019',
          LATITUDE: 55.844099999999997,
          LONGITUDE: -111.6238,
          FIRE_NUMBER: 'LWF086',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/31 15:00:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 66.700000000000003,
          ASSESSMENT_ASSISTANCE_DATE: 1557694800000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133506,
        },
      },
      {
        type: 'Feature',
        id: 71130307,
        geometry: {
          type: 'Point',
          coordinates: [-13314444.023807947, 8230121.9070853153],
        },
        properties: {
          LABEL: 'HWF-150-2019',
          LATITUDE: 59.229332999999997,
          LONGITUDE: -119.605667,
          FIRE_NUMBER: 'HWF150',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/30 16:55:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 64.799999999999997,
          ASSESSMENT_ASSISTANCE_DATE: 1563925920000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130307,
        },
      },
      {
        type: 'Feature',
        id: 71131534,
        geometry: {
          type: 'Point',
          coordinates: [-13270595.974995602, 8373437.2282969644],
        },
        properties: {
          LABEL: 'HWF-123-2019',
          LATITUDE: 59.881650999999998,
          LONGITUDE: -119.21177299999999,
          FIRE_NUMBER: 'HWF123',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/31 16:03:21',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 54.100000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563391020000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131534,
        },
      },
      {
        type: 'Feature',
        id: 71130458,
        geometry: {
          type: 'Point',
          coordinates: [-12336633.786586555, 8355449.1055623181],
        },
        properties: {
          LABEL: 'MWF-068-2019',
          LATITUDE: 59.800466999999998,
          LONGITUDE: -110.82185,
          FIRE_NUMBER: 'MWF068',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/30 13:31:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 46.700000000000003,
          ASSESSMENT_ASSISTANCE_DATE: 1563571140000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71130458,
        },
      },
      {
        type: 'Feature',
        id: 71130320,
        geometry: {
          type: 'Point',
          coordinates: [-12727326.27723763, 8121597.3405710589],
        },
        properties: {
          LABEL: 'HWF-127-2019',
          LATITUDE: 58.726917,
          LONGITUDE: -114.33150000000001,
          FIRE_NUMBER: 'HWF127',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/15 09:50:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 43.100000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563493380000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130320,
        },
      },
      {
        type: 'Feature',
        id: 71131504,
        geometry: {
          type: 'Point',
          coordinates: [-13244168.016050743, 8236376.7814614102],
        },
        properties: {
          LABEL: 'HWF-126-2019',
          LATITUDE: 59.258066999999997,
          LONGITUDE: -118.974367,
          FIRE_NUMBER: 'HWF126',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/28 13:27:00',
          SIZE_CLASS: 'D',
          AREA_ESTIMATE: 40.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563484680000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131504,
        },
      },
      {
        type: 'Feature',
        id: 71132247,
        geometry: {
          type: 'Point',
          coordinates: [-13058153.880908608, 8220469.8858172717],
        },
        properties: {
          LABEL: 'HWF-041-2019',
          LATITUDE: 59.184944999999999,
          LONGITUDE: -117.30337400000001,
          FIRE_NUMBER: 'HWF041',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/22 14:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 38.200000000000003,
          ASSESSMENT_ASSISTANCE_DATE: 1557683460000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132247,
        },
      },
      {
        type: 'Feature',
        id: 71134168,
        geometry: {
          type: 'Point',
          coordinates: [-12976258.853850944, 7607291.4848617353],
        },
        properties: {
          LABEL: 'PWF-076-2019',
          LATITUDE: 56.244732999999997,
          LONGITUDE: -116.5677,
          FIRE_NUMBER: 'PWF076',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/06 16:45:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 36.399999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1560363060000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71134168,
        },
      },
      {
        type: 'Feature',
        id: 71132201,
        geometry: {
          type: 'Point',
          coordinates: [-12423279.976397831, 7280660.8684394248],
        },
        properties: {
          LABEL: 'LWF-099-2019',
          LATITUDE: 54.579394999999998,
          LONGITUDE: -111.60020799999999,
          FIRE_NUMBER: 'LWF099',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/31 13:45:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 33.670000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1558201020000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132201,
        },
      },
      {
        type: 'Feature',
        id: 71130343,
        geometry: {
          type: 'Point',
          coordinates: [-12908516.399055613, 7260944.9174424009],
        },
        properties: {
          LABEL: 'WWF-032-2019',
          LATITUDE: 54.476616999999997,
          LONGITUDE: -115.95916,
          FIRE_NUMBER: 'WWF032',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/18 17:30:50',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 33.600000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1559079360000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71130343,
        },
      },
      {
        type: 'Feature',
        id: 71131391,
        geometry: {
          type: 'Point',
          coordinates: [-12922135.292742927, 8012319.7275990881],
        },
        properties: {
          LABEL: 'HWF-171-2019',
          LATITUDE: 58.213579000000003,
          LONGITUDE: -116.08149899999999,
          FIRE_NUMBER: 'HWF171',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/09/11 14:58:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 29.800000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1568165580000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131391,
        },
      },
      {
        type: 'Feature',
        id: 71133957,
        geometry: {
          type: 'Point',
          coordinates: [-12378929.427991876, 7462738.9155222131],
        },
        properties: {
          LABEL: 'LWF-107-2019',
          LATITUDE: 55.516382999999998,
          LONGITUDE: -111.20180000000001,
          FIRE_NUMBER: 'LWF107',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/03 18:45:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 28.5,
          ASSESSMENT_ASSISTANCE_DATE: 1558464600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133957,
        },
      },
      {
        type: 'Feature',
        id: 71133041,
        geometry: {
          type: 'Point',
          coordinates: [-12802853.130248414, 6642457.3363229698],
        },
        properties: {
          LABEL: 'CWF-018-2019',
          LATITUDE: 51.119483000000002,
          LONGITUDE: -115.009972,
          FIRE_NUMBER: 'CWF018',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/12 12:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 28.149999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557587700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71133041,
        },
      },
      {
        type: 'Feature',
        id: 71130474,
        geometry: {
          type: 'Point',
          coordinates: [-12734589.86107911, 8063854.3630130179],
        },
        properties: {
          LABEL: 'HWF-091-2019',
          LATITUDE: 58.456600000000002,
          LONGITUDE: -114.39675,
          FIRE_NUMBER: 'HWF091',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/02 17:00:31',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 27.600000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1561043100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130474,
        },
      },
      {
        type: 'Feature',
        id: 71133488,
        geometry: {
          type: 'Point',
          coordinates: [-12997317.176379105, 7649492.738765778],
        },
        properties: {
          LABEL: 'PWF-099-2019',
          LATITUDE: 56.454799999999999,
          LONGITUDE: -116.75687000000001,
          FIRE_NUMBER: 'PWF099',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 17:30:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 27,
          ASSESSMENT_ASSISTANCE_DATE: 1563313080000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133488,
        },
      },
      {
        type: 'Feature',
        id: 71132200,
        geometry: {
          type: 'Point',
          coordinates: [-13265638.731819777, 7667563.4075902514],
        },
        properties: {
          LABEL: 'PWF-061-2019',
          LATITUDE: 56.544398000000001,
          LONGITUDE: -119.167243,
          FIRE_NUMBER: 'PWF061',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/17 21:10:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 26.100000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558059240000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132200,
        },
      },
      {
        type: 'Feature',
        id: 71133938,
        geometry: {
          type: 'Point',
          coordinates: [-13000951.299538611, 7083003.9936281731],
        },
        properties: {
          LABEL: 'EWF-033-2019',
          LATITUDE: 53.537267,
          LONGITUDE: -116.789517,
          FIRE_NUMBER: 'EWF033',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/06 09:20:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 23.100000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558305780000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71133938,
        },
      },
      {
        type: 'Feature',
        id: 71133198,
        geometry: {
          type: 'Point',
          coordinates: [-12384760.859078849, 7261336.068420954],
        },
        properties: {
          LABEL: 'LWF-085-2019',
          LATITUDE: 54.478658000000003,
          LONGITUDE: -111.25418500000001,
          FIRE_NUMBER: 'LWF085',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/01 12:15:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 21,
          ASSESSMENT_ASSISTANCE_DATE: 1557687300000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133198,
        },
      },
      {
        type: 'Feature',
        id: 71130560,
        geometry: {
          type: 'Point',
          coordinates: [-12554872.570318183, 7337051.2851938708],
        },
        properties: {
          LABEL: 'LWF-007-2019',
          LATITUDE: 54.871929999999999,
          LONGITUDE: -112.782324,
          FIRE_NUMBER: 'LWF007',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/12 12:51:23',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 20.899999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1554856740000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130560,
        },
      },
      {
        type: 'Feature',
        id: 71134151,
        geometry: {
          type: 'Point',
          coordinates: [-12418564.825911697, 7330760.1278202049],
        },
        properties: {
          LABEL: 'LWF-087-2019',
          LATITUDE: 54.839398000000003,
          LONGITUDE: -111.557851,
          FIRE_NUMBER: 'LWF087',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/02 13:05:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 20.600000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1557777600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71134151,
        },
      },
      {
        type: 'Feature',
        id: 71134005,
        geometry: {
          type: 'Point',
          coordinates: [-12929784.533884343, 7190105.0822126623],
        },
        properties: {
          LABEL: 'WWF-028-2019',
          LATITUDE: 54.105193,
          LONGITUDE: -116.150215,
          FIRE_NUMBER: 'WWF028',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/03 17:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 20.300000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558463460000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71134005,
        },
      },
      {
        type: 'Feature',
        id: 71133073,
        geometry: {
          type: 'Point',
          coordinates: [-12777895.227023795, 7897766.861002652],
        },
        properties: {
          LABEL: 'HWF-075-2019',
          LATITUDE: 57.667374000000002,
          LONGITUDE: -114.785769,
          FIRE_NUMBER: 'HWF075',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/13 18:40:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 18,
          ASSESSMENT_ASSISTANCE_DATE: 1560117420000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133073,
        },
      },
      {
        type: 'Feature',
        id: 71134189,
        geometry: {
          type: 'Point',
          coordinates: [-12960086.195907144, 8302240.0501529071],
        },
        properties: {
          LABEL: 'HWF-136-2019',
          LATITUDE: 59.559167000000002,
          LONGITUDE: -116.422417,
          FIRE_NUMBER: 'HWF136',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/25 18:55:16',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 17.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563661620000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71134189,
        },
      },
      {
        type: 'Feature',
        id: 71131349,
        geometry: {
          type: 'Point',
          coordinates: [-12558357.96411309, 7749250.8259964334],
        },
        properties: {
          LABEL: 'MWF-069-2019',
          LATITUDE: 56.946783000000003,
          LONGITUDE: -112.813633,
          FIRE_NUMBER: 'MWF069',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/27 18:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 17.260000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563746940000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71131349,
        },
      },
      {
        type: 'Feature',
        id: 71131290,
        geometry: {
          type: 'Point',
          coordinates: [-12588994.985162912, 7737860.3866177788],
        },
        properties: {
          LABEL: 'SWF-156-2019',
          LATITUDE: 56.890932999999997,
          LONGITUDE: -113.08884999999999,
          FIRE_NUMBER: 'SWF156',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 15:21:35',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 17.18,
          ASSESSMENT_ASSISTANCE_DATE: 1563561960000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71131290,
        },
      },
      {
        type: 'Feature',
        id: 71130371,
        geometry: {
          type: 'Point',
          coordinates: [-13306262.043476366, 8244496.7369954763],
        },
        properties: {
          LABEL: 'HWF-149-2019',
          LATITUDE: 59.295332999999999,
          LONGITUDE: -119.532167,
          FIRE_NUMBER: 'HWF149',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/27 13:30:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 16.899999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563923700000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130371,
        },
      },
      {
        type: 'Feature',
        id: 71133024,
        geometry: {
          type: 'Point',
          coordinates: [-13254044.470551547, 7667569.8720183214],
        },
        properties: {
          LABEL: 'PWF-048-2019',
          LATITUDE: 56.544429999999998,
          LONGITUDE: -119.06309,
          FIRE_NUMBER: 'PWF048',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/10 13:57:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 15.800000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1557450120000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133024,
        },
      },
      {
        type: 'Feature',
        id: 71134188,
        geometry: {
          type: 'Point',
          coordinates: [-12808491.064532166, 7429186.7389184162],
        },
        properties: {
          LABEL: 'SWF-114-2019',
          LATITUDE: 55.345367000000003,
          LONGITUDE: -115.06061699999999,
          FIRE_NUMBER: 'SWF114',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/28 14:40:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 15.300000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1560799920000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134188,
        },
      },
      {
        type: 'Feature',
        id: 71133015,
        geometry: {
          type: 'Point',
          coordinates: [-12344451.759306403, 7915518.3236846002],
        },
        properties: {
          LABEL: 'MWF-058-2019',
          LATITUDE: 57.752560000000003,
          LONGITUDE: -110.892081,
          FIRE_NUMBER: 'MWF058',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 12:42:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 14.699999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563482400000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71133015,
        },
      },
      {
        type: 'Feature',
        id: 71131552,
        geometry: {
          type: 'Point',
          coordinates: [-12395083.889100563, 7440607.3655886548],
        },
        properties: {
          LABEL: 'LWF-136-2019',
          LATITUDE: 55.403661,
          LONGITUDE: -111.346918,
          FIRE_NUMBER: 'LWF136',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/07 13:50:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 14.4,
          ASSESSMENT_ASSISTANCE_DATE: 1563828300000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71131552,
        },
      },
      {
        type: 'Feature',
        id: 71131121,
        geometry: {
          type: 'Point',
          coordinates: [-12426743.26645419, 8361293.8613422625],
        },
        properties: {
          LABEL: 'MWF-071-2019',
          LATITUDE: 59.826867,
          LONGITUDE: -111.631317,
          FIRE_NUMBER: 'MWF071',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/28 14:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 12.84,
          ASSESSMENT_ASSISTANCE_DATE: 1563915420000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71131121,
        },
      },
      {
        type: 'Feature',
        id: 71130326,
        geometry: {
          type: 'Point',
          coordinates: [-12782086.210099764, 8002397.5728135444],
        },
        properties: {
          LABEL: 'HWF-141-2019',
          LATITUDE: 58.166597000000003,
          LONGITUDE: -114.82341700000001,
          FIRE_NUMBER: 'HWF141',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/30 17:47:26',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 12.6,
          ASSESSMENT_ASSISTANCE_DATE: 1563746340000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130326,
        },
      },
      {
        type: 'Feature',
        id: 71130551,
        geometry: {
          type: 'Point',
          coordinates: [-13062922.012102509, 8154573.9149382887],
        },
        properties: {
          LABEL: 'HWF-089-2019',
          LATITUDE: 58.880358000000001,
          LONGITUDE: -117.34620700000001,
          FIRE_NUMBER: 'HWF089',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/19 17:45:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 12.1,
          ASSESSMENT_ASSISTANCE_DATE: 1560898860000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130551,
        },
      },
      {
        type: 'Feature',
        id: 71134187,
        geometry: {
          type: 'Point',
          coordinates: [-12399488.329463229, 7817945.3664568597],
        },
        properties: {
          LABEL: 'MWF-012-2019',
          LATITUDE: 57.281840000000003,
          LONGITUDE: -111.386483,
          FIRE_NUMBER: 'MWF012',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/31 16:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 10.6,
          ASSESSMENT_ASSISTANCE_DATE: 1558294620000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71134187,
        },
      },
      {
        type: 'Feature',
        id: 71134001,
        geometry: {
          type: 'Point',
          coordinates: [-13039361.997290315, 7635882.8690659758],
        },
        properties: {
          LABEL: 'PWF-066-2019',
          LATITUDE: 56.387180000000001,
          LONGITUDE: -117.13456499999999,
          FIRE_NUMBER: 'PWF066',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/31 14:30:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 10.5,
          ASSESSMENT_ASSISTANCE_DATE: 1558218300000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71134001,
        },
      },
      {
        type: 'Feature',
        id: 71132378,
        geometry: {
          type: 'Point',
          coordinates: [-13188560.310521333, 8066292.1441813],
        },
        properties: {
          LABEL: 'HWF-116-2019',
          LATITUDE: 58.468055,
          LONGITUDE: -118.474835,
          FIRE_NUMBER: 'HWF116',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 17:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 10.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563311220000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132378,
        },
      },
      {
        type: 'Feature',
        id: 71134006,
        geometry: {
          type: 'Point',
          coordinates: [-13155266.199243071, 7608557.1091644568],
        },
        properties: {
          LABEL: 'PWF-038-2019',
          LATITUDE: 56.251049999999999,
          LONGITUDE: -118.17574999999999,
          FIRE_NUMBER: 'PWF038',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/30 14:50:05',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 10,
          ASSESSMENT_ASSISTANCE_DATE: 1555888740000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71134006,
        },
      },
      {
        type: 'Feature',
        id: 71132202,
        geometry: {
          type: 'Point',
          coordinates: [-12873762.259514133, 7424885.9048549458],
        },
        properties: {
          LABEL: 'SWF-057-2019',
          LATITUDE: 55.323391999999998,
          LONGITUDE: -115.646958,
          FIRE_NUMBER: 'SWF057',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/20 15:43:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 9.8000000000000007,
          ASSESSMENT_ASSISTANCE_DATE: 1558374480000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132202,
        },
      },
      {
        type: 'Feature',
        id: 71132350,
        geometry: {
          type: 'Point',
          coordinates: [-12547498.238101993, 7958438.8199352408],
        },
        properties: {
          LABEL: 'MWF-070-2019',
          LATITUDE: 57.957701999999998,
          LONGITUDE: -112.716078,
          FIRE_NUMBER: 'MWF070',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/29 16:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 9.0999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1563910500000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132350,
        },
      },
      {
        type: 'Feature',
        id: 71131320,
        geometry: {
          type: 'Point',
          coordinates: [-12551486.804753691, 7909973.4967997083],
        },
        properties: {
          LABEL: 'MWF-074-2019',
          LATITUDE: 57.725973000000003,
          LONGITUDE: -112.751908,
          FIRE_NUMBER: 'MWF074',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/27 15:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 8.9700000000000006,
          ASSESSMENT_ASSISTANCE_DATE: 1563923520000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71131320,
        },
      },
      {
        type: 'Feature',
        id: 71130327,
        geometry: {
          type: 'Point',
          coordinates: [-12758139.469833637, 7922887.8841442969],
        },
        properties: {
          LABEL: 'HWF-143-2019',
          LATITUDE: 57.787866999999999,
          LONGITUDE: -114.6083,
          FIRE_NUMBER: 'HWF143',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/28 17:56:13',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 7.7999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1563751860000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130327,
        },
      },
      {
        type: 'Feature',
        id: 71130479,
        geometry: {
          type: 'Point',
          coordinates: [-12916142.382773474, 7445824.5203547804],
        },
        properties: {
          LABEL: 'SWF-027-2019',
          LATITUDE: 55.430262999999997,
          LONGITUDE: -116.027665,
          FIRE_NUMBER: 'SWF027',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/11 10:07:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 7.75,
          ASSESSMENT_ASSISTANCE_DATE: 1557584880000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130479,
        },
      },
      {
        type: 'Feature',
        id: 71133029,
        geometry: {
          type: 'Point',
          coordinates: [-13056772.92450436, 7629703.8216902874],
        },
        properties: {
          LABEL: 'PWF-036-2019',
          LATITUDE: 56.356439999999999,
          LONGITUDE: -117.29097,
          FIRE_NUMBER: 'PWF036',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/28 12:10:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 7.2999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1555707000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133029,
        },
      },
      {
        type: 'Feature',
        id: 71131325,
        geometry: {
          type: 'Point',
          coordinates: [-12451394.724185599, 7289540.5479154624],
        },
        properties: {
          LABEL: 'LWF-071-2019',
          LATITUDE: 54.625599999999999,
          LONGITUDE: -111.852767,
          FIRE_NUMBER: 'LWF071',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/03 14:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 7.2000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1556847780000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71131325,
        },
      },
      {
        type: 'Feature',
        id: 71134326,
        geometry: {
          type: 'Point',
          coordinates: [-12332493.36389632, 7378543.8360527055],
        },
        properties: {
          LABEL: 'LWF-126-2019',
          LATITUDE: 55.085833000000001,
          LONGITUDE: -110.78465799999999,
          FIRE_NUMBER: 'LWF126',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/22 15:50:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 7,
          ASSESSMENT_ASSISTANCE_DATE: 1560812340000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71134326,
        },
      },
      {
        type: 'Feature',
        id: 71130397,
        geometry: {
          type: 'Point',
          coordinates: [-13263413.139907468, 7745842.7533843983],
        },
        properties: {
          LABEL: 'PWF-110-2019',
          LATITUDE: 56.930081999999999,
          LONGITUDE: -119.14725,
          FIRE_NUMBER: 'PWF110',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/26 17:07:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.7999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1563922140000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71130397,
        },
      },
      {
        type: 'Feature',
        id: 71131446,
        geometry: {
          type: 'Point',
          coordinates: [-12963420.21639538, 8305778.3474921063],
        },
        properties: {
          LABEL: 'HWF-155-2019',
          LATITUDE: 59.575266999999997,
          LONGITUDE: -116.452367,
          FIRE_NUMBER: 'HWF155',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/26 18:46:09',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.5300000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1564016820000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131446,
        },
      },
      {
        type: 'Feature',
        id: 71131511,
        geometry: {
          type: 'Point',
          coordinates: [-12529772.92430616, 7387872.3581832955],
        },
        properties: {
          LABEL: 'LWF-075-2019',
          LATITUDE: 55.133766999999999,
          LONGITUDE: -112.55685,
          FIRE_NUMBER: 'LWF075',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/09 18:57:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.5,
          ASSESSMENT_ASSISTANCE_DATE: 1557439500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71131511,
        },
      },
      {
        type: 'Feature',
        id: 71132408,
        geometry: {
          type: 'Point',
          coordinates: [-12327035.960739845, 7549686.2266231971],
        },
        properties: {
          LABEL: 'LWF-145-2019',
          LATITUDE: 55.956116999999999,
          LONGITUDE: -110.73563300000001,
          FIRE_NUMBER: 'LWF145',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/10/08 13:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.5,
          ASSESSMENT_ASSISTANCE_DATE: 1570050960000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132408,
        },
      },
      {
        type: 'Feature',
        id: 71133228,
        geometry: {
          type: 'Point',
          coordinates: [-13233839.194027772, 7644300.4902134044],
        },
        properties: {
          LABEL: 'PWF-044-2019',
          LATITUDE: 56.429017000000002,
          LONGITUDE: -118.88158300000001,
          FIRE_NUMBER: 'PWF044',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/10 14:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.4000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1557424500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133228,
        },
      },
      {
        type: 'Feature',
        id: 71130531,
        geometry: {
          type: 'Point',
          coordinates: [-13050781.859921372, 8230245.3535850206],
        },
        properties: {
          LABEL: 'HWF-090-2019',
          LATITUDE: 59.229900000000001,
          LONGITUDE: -117.23715,
          FIRE_NUMBER: 'HWF090',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/22 16:48:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.25,
          ASSESSMENT_ASSISTANCE_DATE: 1560904020000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130531,
        },
      },
      {
        type: 'Feature',
        id: 71130517,
        geometry: {
          type: 'Point',
          coordinates: [-12790813.368280744, 6642835.8026472554],
        },
        properties: {
          LABEL: 'CWF-010-2019',
          LATITUDE: 51.121617000000001,
          LONGITUDE: -114.90181699999999,
          FIRE_NUMBER: 'CWF010',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/03 11:31:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.2000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1554233700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71130517,
        },
      },
      {
        type: 'Feature',
        id: 71130377,
        geometry: {
          type: 'Point',
          coordinates: [-12833945.915865878, 6756011.101411039],
        },
        properties: {
          LABEL: 'RWF-028-2019',
          LATITUDE: 51.75535,
          LONGITUDE: -115.289283,
          FIRE_NUMBER: 'RWF028',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/10 15:28:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6.0800000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1559495100000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71130377,
        },
      },
      {
        type: 'Feature',
        id: 71132185,
        geometry: {
          type: 'Point',
          coordinates: [-12812908.437613022, 6848675.7559081009],
        },
        properties: {
          LABEL: 'RWF-008-2019',
          LATITUDE: 52.267699999999998,
          LONGITUDE: -115.1003,
          FIRE_NUMBER: 'RWF008',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/03/23 11:46:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 6,
          ASSESSMENT_ASSISTANCE_DATE: 1553359200000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71132185,
        },
      },
      {
        type: 'Feature',
        id: 71130394,
        geometry: {
          type: 'Point',
          coordinates: [-13321533.170960115, 8181011.4196032677],
        },
        properties: {
          LABEL: 'HWF-096-2019',
          LATITUDE: 59.002882999999997,
          LONGITUDE: -119.66934999999999,
          FIRE_NUMBER: 'HWF096',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/24 17:20:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5.7999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1561336800000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130394,
        },
      },
      {
        type: 'Feature',
        id: 71133947,
        geometry: {
          type: 'Point',
          coordinates: [-13022489.959318466, 8057576.0203453284],
        },
        properties: {
          LABEL: 'HWF-124-2019',
          LATITUDE: 58.427083000000003,
          LONGITUDE: -116.983,
          FIRE_NUMBER: 'HWF124',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/27 11:40:08',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5.5199999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1563408660000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133947,
        },
      },
      {
        type: 'Feature',
        id: 71132504,
        geometry: {
          type: 'Point',
          coordinates: [-13137968.123330494, 7957990.7176502896],
        },
        properties: {
          LABEL: 'PWF-108-2019',
          LATITUDE: 57.955567000000002,
          LONGITUDE: -118.020358,
          FIRE_NUMBER: 'PWF108',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/22 17:58:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563666120000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132504,
        },
      },
      {
        type: 'Feature',
        id: 71134040,
        geometry: {
          type: 'Point',
          coordinates: [-13304668.134252783, 7675321.6682723286],
        },
        properties: {
          LABEL: 'PWF-039-2019',
          LATITUDE: 56.582799999999999,
          LONGITUDE: -119.51785,
          FIRE_NUMBER: 'PWF039',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/02 13:30:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5.2999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1556070300000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71134040,
        },
      },
      {
        type: 'Feature',
        id: 71134100,
        geometry: {
          type: 'Point',
          coordinates: [-13158689.413835432, 8161643.9853060367],
        },
        properties: {
          LABEL: 'HWF-101-2019',
          LATITUDE: 58.913167000000001,
          LONGITUDE: -118.20650000000001,
          FIRE_NUMBER: 'HWF101',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/03 11:15:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5.2000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1561760940000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71134100,
        },
      },
      {
        type: 'Feature',
        id: 71133507,
        geometry: {
          type: 'Point',
          coordinates: [-13030720.18133105, 8195309.7181373527],
        },
        properties: {
          LABEL: 'HWF-130-2019',
          LATITUDE: 59.068967000000001,
          LONGITUDE: -117.056933,
          FIRE_NUMBER: 'HWF130',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/27 15:42:43',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5.04,
          ASSESSMENT_ASSISTANCE_DATE: 1563553320000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133507,
        },
      },
      {
        type: 'Feature',
        id: 71135400,
        geometry: {
          type: 'Point',
          coordinates: [-12601271.982675586, 7292673.15173948],
        },
        properties: {
          LABEL: 'LCU-001-2019',
          LATITUDE: 54.641888000000002,
          LONGITUDE: -113.19913699999999,
          FIRE_NUMBER: 'LCU001',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2019/05/15 08:27:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5,
          ASSESSMENT_ASSISTANCE_DATE: 1557689400000,
          GENERAL_CAUSE: null,
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71135400,
        },
      },
      {
        type: 'Feature',
        id: 71130410,
        geometry: {
          type: 'Point',
          coordinates: [-12294428.701501168, 7590791.1711172629],
        },
        properties: {
          LABEL: 'LWF-132-2019',
          LATITUDE: 56.162283000000002,
          LONGITUDE: -110.442717,
          FIRE_NUMBER: 'LWF132',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/20 13:15:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 5,
          ASSESSMENT_ASSISTANCE_DATE: 1563414600000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130410,
        },
      },
      {
        type: 'Feature',
        id: 71134323,
        geometry: {
          type: 'Point',
          coordinates: [-12901658.644044325, 7635998.127483692],
        },
        properties: {
          LABEL: 'SWF-056-2019',
          LATITUDE: 56.387752999999996,
          LONGITUDE: -115.897555,
          FIRE_NUMBER: 'SWF056',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/22 17:30:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 4.9000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1558321500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134323,
        },
      },
      {
        type: 'Feature',
        id: 71130319,
        geometry: {
          type: 'Point',
          coordinates: [-13224692.627328195, 8089486.0139477057],
        },
        properties: {
          LABEL: 'HWF-085-2019',
          LATITUDE: 58.57685,
          LONGITUDE: -118.79941700000001,
          FIRE_NUMBER: 'HWF085',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/19 10:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 4.7999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1560713400000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130319,
        },
      },
      {
        type: 'Feature',
        id: 71130396,
        geometry: {
          type: 'Point',
          coordinates: [-13212254.09024688, 7980731.1448274981],
        },
        properties: {
          LABEL: 'PWF-098-2019',
          LATITUDE: 58.063789999999997,
          LONGITUDE: -118.68768,
          FIRE_NUMBER: 'PWF098',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 15:50:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 4.4000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1563309060000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71130396,
        },
      },
      {
        type: 'Feature',
        id: 71132439,
        geometry: {
          type: 'Point',
          coordinates: [-12861993.068577573, 7708380.9009716511],
        },
        properties: {
          LABEL: 'SWF-144-2019',
          LATITUDE: 56.746000000000002,
          LONGITUDE: -115.54123300000001,
          FIRE_NUMBER: 'SWF144',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 15:50:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 4.0999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1563311880000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132439,
        },
      },
      {
        type: 'Feature',
        id: 71132230,
        geometry: {
          type: 'Point',
          coordinates: [-12351800.895860024, 7622071.0844622524],
        },
        properties: {
          LABEL: 'LWF-039-2019',
          LATITUDE: 56.318432999999999,
          LONGITUDE: -110.9581,
          FIRE_NUMBER: 'LWF039',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/23 10:10:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 4.0999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1555969500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132230,
        },
      },
      {
        type: 'Feature',
        id: 71132520,
        geometry: {
          type: 'Point',
          coordinates: [-12655877.752817968, 7728773.5086716795],
        },
        properties: {
          LABEL: 'SWF-150-2019',
          LATITUDE: 56.846317999999997,
          LONGITUDE: -113.689668,
          FIRE_NUMBER: 'SWF150',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/22 12:00:00',
          SIZE_CLASS: 'C',
          AREA_ESTIMATE: 4.0999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1563402540000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132520,
        },
      },
      {
        type: 'Feature',
        id: 71132476,
        geometry: {
          type: 'Point',
          coordinates: [-12294649.564339813, 8095958.7046157792],
        },
        properties: {
          LABEL: 'MWF-053-2019',
          LATITUDE: 58.607149999999997,
          LONGITUDE: -110.4447,
          FIRE_NUMBER: 'MWF053',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/29 08:15:01',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 4,
          ASSESSMENT_ASSISTANCE_DATE: 1563403740000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132476,
        },
      },
      {
        type: 'Feature',
        id: 71132129,
        geometry: {
          type: 'Point',
          coordinates: [-13311642.445693366, 8230484.6386241242],
        },
        properties: {
          LABEL: 'HWF-152-2019',
          LATITUDE: 59.231000000000002,
          LONGITUDE: -119.5805,
          FIRE_NUMBER: 'HWF152',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 18:47:06',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 4,
          ASSESSMENT_ASSISTANCE_DATE: 1563926520000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132129,
        },
      },
      {
        type: 'Feature',
        id: 71130280,
        geometry: {
          type: 'Point',
          coordinates: [-12726823.416180154, 7999588.1688483758],
        },
        properties: {
          LABEL: 'HWF-134-2019',
          LATITUDE: 58.153283000000002,
          LONGITUDE: -114.326983,
          FIRE_NUMBER: 'HWF134',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 18:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 4,
          ASSESSMENT_ASSISTANCE_DATE: 1563646560000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130280,
        },
      },
      {
        type: 'Feature',
        id: 71130273,
        geometry: {
          type: 'Point',
          coordinates: [-12506635.068297943, 8394193.9110356309],
        },
        properties: {
          LABEL: 'MWF-010-2019',
          LATITUDE: 59.975082,
          LONGITUDE: -112.348997,
          FIRE_NUMBER: 'MWF010',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/15 18:12:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.8999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557953400000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71130273,
        },
      },
      {
        type: 'Feature',
        id: 71130453,
        geometry: {
          type: 'Point',
          coordinates: [-13044531.306396734, 8366566.822825904],
        },
        properties: {
          LABEL: 'HWF-121-2019',
          LATITUDE: 59.850667000000001,
          LONGITUDE: -117.181,
          FIRE_NUMBER: 'HWF121',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 13:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.8599999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563326640000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130453,
        },
      },
      {
        type: 'Feature',
        id: 71133013,
        geometry: {
          type: 'Point',
          coordinates: [-13091940.228609549, 7723189.7981301732],
        },
        properties: {
          LABEL: 'PWF-013-2019',
          LATITUDE: 56.818877000000001,
          LONGITUDE: -117.606883,
          FIRE_NUMBER: 'PWF013',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/03/29 15:15:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.7999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1553823600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133013,
        },
      },
      {
        type: 'Feature',
        id: 71130419,
        geometry: {
          type: 'Point',
          coordinates: [-13156778.401581576, 8198277.3186541451],
        },
        properties: {
          LABEL: 'HWF-105-2019',
          LATITUDE: 59.082667000000001,
          LONGITUDE: -118.189333,
          FIRE_NUMBER: 'HWF105',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/09 11:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.7400000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1562361480000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130419,
        },
      },
      {
        type: 'Feature',
        id: 71132496,
        geometry: {
          type: 'Point',
          coordinates: [-12574320.62690242, 7751613.4371507009],
        },
        properties: {
          LABEL: 'MWF-045-2019',
          LATITUDE: 56.958356999999999,
          LONGITUDE: -112.95702799999999,
          FIRE_NUMBER: 'MWF045',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/15 16:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1562973480000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132496,
        },
      },
      {
        type: 'Feature',
        id: 71132916,
        geometry: {
          type: 'Point',
          coordinates: [-12906439.956663948, 7263351.8276208127],
        },
        properties: {
          LABEL: 'WWF-033-2019',
          LATITUDE: 54.489178000000003,
          LONGITUDE: -115.940507,
          FIRE_NUMBER: 'WWF033',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/31 17:40:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1559079600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71132916,
        },
      },
      {
        type: 'Feature',
        id: 71134013,
        geometry: {
          type: 'Point',
          coordinates: [-12982710.407646434, 7739501.0146531342],
        },
        properties: {
          LABEL: 'PWF-117-2019',
          LATITUDE: 56.898983000000001,
          LONGITUDE: -116.62565499999999,
          FIRE_NUMBER: 'PWF117',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/26 12:57:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563925320000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71134013,
        },
      },
      {
        type: 'Feature',
        id: 71131295,
        geometry: {
          type: 'Point',
          coordinates: [-13030004.07946088, 8256926.6861814093],
        },
        properties: {
          LABEL: 'HWF-154-2019',
          LATITUDE: 59.3523,
          LONGITUDE: -117.0505,
          FIRE_NUMBER: 'HWF154',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/26 15:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.5099999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1564016040000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131295,
        },
      },
      {
        type: 'Feature',
        id: 71130499,
        geometry: {
          type: 'Point',
          coordinates: [-12328433.019393843, 7543643.6789424717],
        },
        properties: {
          LABEL: 'LWF-009-2019',
          LATITUDE: 55.925716999999999,
          LONGITUDE: -110.748183,
          FIRE_NUMBER: 'LWF009',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/13 14:16:32',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.5,
          ASSESSMENT_ASSISTANCE_DATE: 1555016700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130499,
        },
      },
      {
        type: 'Feature',
        id: 71135399,
        geometry: {
          type: 'Point',
          coordinates: [-13055112.031560594, 7605098.0320759816],
        },
        properties: {
          LABEL: 'PCU-001-2019',
          LATITUDE: 56.233783000000003,
          LONGITUDE: -117.27605,
          FIRE_NUMBER: 'PCU001',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2019/05/09 17:13:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.3100000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1557434400000,
          GENERAL_CAUSE: null,
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71135399,
        },
      },
      {
        type: 'Feature',
        id: 71131040,
        geometry: {
          type: 'Point',
          coordinates: [-13211620.050293449, 8117741.080085882],
        },
        properties: {
          LABEL: 'HWF-010-2019',
          LATITUDE: 58.708930000000002,
          LONGITUDE: -118.681984,
          FIRE_NUMBER: 'HWF010',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/10 17:10:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3.1000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1554934500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131040,
        },
      },
      {
        type: 'Feature',
        id: 71130449,
        geometry: {
          type: 'Point',
          coordinates: [-13193172.313421734, 7290809.9859296074],
        },
        properties: {
          LABEL: 'GWF-031-2019',
          LATITUDE: 54.632201999999999,
          LONGITUDE: -118.516267,
          FIRE_NUMBER: 'GWF031',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/05 14:12:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3,
          ASSESSMENT_ASSISTANCE_DATE: 1559492160000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71130449,
        },
      },
      {
        type: 'Feature',
        id: 71130308,
        geometry: {
          type: 'Point',
          coordinates: [-13312625.730692778, 8229505.5017789602],
        },
        properties: {
          LABEL: 'HWF-151-2019',
          LATITUDE: 59.226500000000001,
          LONGITUDE: -119.589333,
          FIRE_NUMBER: 'HWF151',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 18:58:04',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3,
          ASSESSMENT_ASSISTANCE_DATE: 1563926040000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130308,
        },
      },
      {
        type: 'Feature',
        id: 71130325,
        geometry: {
          type: 'Point',
          coordinates: [-12913062.947254201, 8330645.3619094165],
        },
        properties: {
          LABEL: 'HWF-110-2019',
          LATITUDE: 59.688200000000002,
          LONGITUDE: -116,
          FIRE_NUMBER: 'HWF110',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/12 20:11:51',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 3,
          ASSESSMENT_ASSISTANCE_DATE: 1562896320000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130325,
        },
      },
      {
        type: 'Feature',
        id: 71130411,
        geometry: {
          type: 'Point',
          coordinates: [-12866697.99867609, 7753692.0305349175],
        },
        properties: {
          LABEL: 'SWF-157-2019',
          LATITUDE: 56.968536999999998,
          LONGITUDE: -115.58349800000001,
          FIRE_NUMBER: 'SWF157',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/28 13:50:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.8999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563922860000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130411,
        },
      },
      {
        type: 'Feature',
        id: 71133196,
        geometry: {
          type: 'Point',
          coordinates: [-12260533.537398443, 7400000.0819693655],
        },
        properties: {
          LABEL: 'LWF-110-2019',
          LATITUDE: 55.195998000000003,
          LONGITUDE: -110.138232,
          FIRE_NUMBER: 'LWF110',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/25 18:08:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.8999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1558554600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133196,
        },
      },
      {
        type: 'Feature',
        id: 71133932,
        geometry: {
          type: 'Point',
          coordinates: [-12988619.015289107, 7699480.5299760327],
        },
        properties: {
          LABEL: 'PWF-111-2019',
          LATITUDE: 56.702131999999999,
          LONGITUDE: -116.67873299999999,
          FIRE_NUMBER: 'PWF111',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/26 14:01:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.7999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1563923280000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133932,
        },
      },
      {
        type: 'Feature',
        id: 71132466,
        geometry: {
          type: 'Point',
          coordinates: [-13320162.701102357, 8124502.552132831],
        },
        properties: {
          LABEL: 'HWF-138-2019',
          LATITUDE: 58.740462999999998,
          LONGITUDE: -119.657039,
          FIRE_NUMBER: 'HWF138',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/22 17:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.7000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563680280000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132466,
        },
      },
      {
        type: 'Feature',
        id: 71130391,
        geometry: {
          type: 'Point',
          coordinates: [-12981356.446203467, 7813007.4437438762],
        },
        properties: {
          LABEL: 'PWF-086-2019',
          LATITUDE: 57.257857000000001,
          LONGITUDE: -116.61349199999999,
          FIRE_NUMBER: 'PWF086',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/19 16:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1560895500000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71130391,
        },
      },
      {
        type: 'Feature',
        id: 71134050,
        geometry: {
          type: 'Point',
          coordinates: [-12668605.891279127, 7625408.8560481928],
        },
        properties: {
          LABEL: 'SWF-077-2019',
          LATITUDE: 56.335057999999997,
          LONGITUDE: -113.804007,
          FIRE_NUMBER: 'SWF077',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/31 18:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1559225880000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134050,
        },
      },
      {
        type: 'Feature',
        id: 71133168,
        geometry: {
          type: 'Point',
          coordinates: [-12940005.604818908, 6872452.156080069],
        },
        properties: {
          LABEL: 'RWF-013-2019',
          LATITUDE: 52.398217000000002,
          LONGITUDE: -116.24203300000001,
          FIRE_NUMBER: 'RWF013',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/29 11:11:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.5,
          ASSESSMENT_ASSISTANCE_DATE: 1556231100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71133168,
        },
      },
      {
        type: 'Feature',
        id: 71132936,
        geometry: {
          type: 'Point',
          coordinates: [-12925660.587687682, 8123019.8043774702],
        },
        properties: {
          LABEL: 'HWF-072-2019',
          LATITUDE: 58.733550000000001,
          LONGITUDE: -116.113167,
          FIRE_NUMBER: 'HWF072',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/06 13:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.5,
          ASSESSMENT_ASSISTANCE_DATE: 1559680800000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132936,
        },
      },
      {
        type: 'Feature',
        id: 71133046,
        geometry: {
          type: 'Point',
          coordinates: [-13321684.916033274, 8241368.7087443797],
        },
        properties: {
          LABEL: 'HWF-082-2019',
          LATITUDE: 59.280982000000002,
          LONGITUDE: -119.67071300000001,
          FIRE_NUMBER: 'HWF082',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/18 11:55:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.5,
          ASSESSMENT_ASSISTANCE_DATE: 1560618420000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133046,
        },
      },
      {
        type: 'Feature',
        id: 71130292,
        geometry: {
          type: 'Point',
          coordinates: [-12911203.134809781, 7440954.6738787945],
        },
        properties: {
          LABEL: 'SWF-022-2019',
          LATITUDE: 55.405433000000002,
          LONGITUDE: -115.983295,
          FIRE_NUMBER: 'SWF022',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/03 11:49:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.5,
          ASSESSMENT_ASSISTANCE_DATE: 1556841960000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130292,
        },
      },
      {
        type: 'Feature',
        id: 71130294,
        geometry: {
          type: 'Point',
          coordinates: [-12932359.760684842, 7984808.6210696883],
        },
        properties: {
          LABEL: 'HWF-045-2019',
          LATITUDE: 58.083159999999999,
          LONGITUDE: -116.17334700000001,
          FIRE_NUMBER: 'HWF045',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/17 14:40:08',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.3799999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557959280000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130294,
        },
      },
      {
        type: 'Feature',
        id: 71132497,
        geometry: {
          type: 'Point',
          coordinates: [-12578375.997484595, 7753999.6583197042],
        },
        properties: {
          LABEL: 'MWF-046-2019',
          LATITUDE: 56.970042999999997,
          LONGITUDE: -112.993458,
          FIRE_NUMBER: 'MWF046',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/15 18:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.2999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1562973600000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132497,
        },
      },
      {
        type: 'Feature',
        id: 71134149,
        geometry: {
          type: 'Point',
          coordinates: [-12853843.801841322, 7664699.7324130917],
        },
        properties: {
          LABEL: 'SWF-100-2019',
          LATITUDE: 56.530213000000003,
          LONGITUDE: -115.46802700000001,
          FIRE_NUMBER: 'SWF100',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/08 13:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.2000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1559935200000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134149,
        },
      },
      {
        type: 'Feature',
        id: 71133563,
        geometry: {
          type: 'Point',
          coordinates: [-12751551.991822271, 7285248.8042302802],
        },
        properties: {
          LABEL: 'WWF-021-2019',
          LATITUDE: 54.603274999999996,
          LONGITUDE: -114.549125,
          FIRE_NUMBER: 'WWF021',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 19:56:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.1000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558134600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71133563,
        },
      },
      {
        type: 'Feature',
        id: 71133407,
        geometry: {
          type: 'Point',
          coordinates: [-13027417.623613894, 7582965.2394237407],
        },
        properties: {
          LABEL: 'PWF-034-2019',
          LATITUDE: 56.123117000000001,
          LONGITUDE: -117.02726699999999,
          FIRE_NUMBER: 'PWF034',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/19 14:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.1000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1555462860000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133407,
        },
      },
      {
        type: 'Feature',
        id: 71132381,
        geometry: {
          type: 'Point',
          coordinates: [-13297113.893866172, 8159535.2972515589],
        },
        properties: {
          LABEL: 'HWF-119-2019',
          LATITUDE: 58.903385,
          LONGITUDE: -119.449988,
          FIRE_NUMBER: 'HWF119',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 17:40:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.1000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563323580000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132381,
        },
      },
      {
        type: 'Feature',
        id: 71132174,
        geometry: {
          type: 'Point',
          coordinates: [-12938230.110628067, 7131836.6100533986],
        },
        properties: {
          LABEL: 'EWF-025-2019',
          LATITUDE: 53.797167000000002,
          LONGITUDE: -116.226083,
          FIRE_NUMBER: 'EWF025',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 13:04:18',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2.0800000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558131000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71132174,
        },
      },
      {
        type: 'Feature',
        id: 71132373,
        geometry: {
          type: 'Point',
          coordinates: [-13162158.851824403, 7942700.7225387143],
        },
        properties: {
          LABEL: 'PWF-107-2019',
          LATITUDE: 57.882617000000003,
          LONGITUDE: -118.237667,
          FIRE_NUMBER: 'PWF107',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 12:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2,
          ASSESSMENT_ASSISTANCE_DATE: 1563664560000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132373,
        },
      },
      {
        type: 'Feature',
        id: 71133005,
        geometry: {
          type: 'Point',
          coordinates: [-12876657.151318345, 7029292.2942557512],
        },
        properties: {
          LABEL: 'EWF-015-2019',
          LATITUDE: 53.249544999999998,
          LONGITUDE: -115.67296399999999,
          FIRE_NUMBER: 'EWF015',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/24 15:47:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2,
          ASSESSMENT_ASSISTANCE_DATE: 1556065080000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71133005,
        },
      },
      {
        type: 'Feature',
        id: 71135381,
        geometry: {
          type: 'Point',
          coordinates: [-13373229.91494658, 8039137.0035091583],
        },
        properties: {
          LABEL: 'HBZ-002-2019',
          LATITUDE: 58.340249999999997,
          LONGITUDE: -120.13375000000001,
          FIRE_NUMBER: 'HBZ002',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2019/05/21 14:35:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 2,
          ASSESSMENT_ASSISTANCE_DATE: null,
          GENERAL_CAUSE: null,
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71135381,
        },
      },
      {
        type: 'Feature',
        id: 71132949,
        geometry: {
          type: 'Point',
          coordinates: [-12822709.081630686, 7218158.5236224802],
        },
        properties: {
          LABEL: 'WWF-015-2019',
          LATITUDE: 54.252682,
          LONGITUDE: -115.18834,
          FIRE_NUMBER: 'WWF015',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/10 17:13:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.9299999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557514140000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71132949,
        },
      },
      {
        type: 'Feature',
        id: 71131264,
        geometry: {
          type: 'Point',
          coordinates: [-12803375.861476289, 7091841.2821523556],
        },
        properties: {
          LABEL: 'EWF-004-2019',
          LATITUDE: 53.584420000000001,
          LONGITUDE: -115.014667,
          FIRE_NUMBER: 'EWF004',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/08 09:44:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.9199999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1554677700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71131264,
        },
      },
      {
        type: 'Feature',
        id: 71134172,
        geometry: {
          type: 'Point',
          coordinates: [-13183012.586528512, 7602161.1841236148],
        },
        properties: {
          LABEL: 'PWF-062-2019',
          LATITUDE: 56.219116999999997,
          LONGITUDE: -118.425,
          FIRE_NUMBER: 'PWF062',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/22 18:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.8999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1558121520000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71134172,
        },
      },
      {
        type: 'Feature',
        id: 71130389,
        geometry: {
          type: 'Point',
          coordinates: [-13289671.638265859, 8198212.2850305447],
        },
        properties: {
          LABEL: 'HWF-083-2019',
          LATITUDE: 59.082366999999998,
          LONGITUDE: -119.383133,
          FIRE_NUMBER: 'HWF083',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/18 19:41:28',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.8300000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1560630300000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130389,
        },
      },
      {
        type: 'Feature',
        id: 71133561,
        geometry: {
          type: 'Point',
          coordinates: [-12943759.302219048, 7423277.7312641414],
        },
        properties: {
          LABEL: 'SWF-047-2019',
          LATITUDE: 55.315171999999997,
          LONGITUDE: -116.275752,
          FIRE_NUMBER: 'SWF047',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 14:07:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.8,
          ASSESSMENT_ASSISTANCE_DATE: 1558132380000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133561,
        },
      },
      {
        type: 'Feature',
        id: 71130306,
        geometry: {
          type: 'Point',
          coordinates: [-13290185.834717607, 8240535.6574919298],
        },
        properties: {
          LABEL: 'HWF-148-2019',
          LATITUDE: 59.277158999999997,
          LONGITUDE: -119.38775200000001,
          FIRE_NUMBER: 'HWF148',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/25 14:27:04',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.77,
          ASSESSMENT_ASSISTANCE_DATE: 1563925140000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130306,
        },
      },
      {
        type: 'Feature',
        id: 71131307,
        geometry: {
          type: 'Point',
          coordinates: [-12981254.844274146, 7479293.069644073],
        },
        properties: {
          LABEL: 'SWF-005-2019',
          LATITUDE: 55.600487999999999,
          LONGITUDE: -116.61257999999999,
          FIRE_NUMBER: 'SWF005',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/12 13:45:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.7,
          ASSESSMENT_ASSISTANCE_DATE: 1555094940000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71131307,
        },
      },
      {
        type: 'Feature',
        id: 71131565,
        geometry: {
          type: 'Point',
          coordinates: [-12775137.064791609, 7423739.4441536972],
        },
        properties: {
          LABEL: 'SWF-062-2019',
          LATITUDE: 55.317532,
          LONGITUDE: -114.760993,
          FIRE_NUMBER: 'SWF062',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/22 18:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.7,
          ASSESSMENT_ASSISTANCE_DATE: 1558487940000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71131565,
        },
      },
      {
        type: 'Feature',
        id: 71132197,
        geometry: {
          type: 'Point',
          coordinates: [-12748300.75332397, 8068732.4421508424],
        },
        properties: {
          LABEL: 'HWF-037-2019',
          LATITUDE: 58.479517000000001,
          LONGITUDE: -114.51991700000001,
          FIRE_NUMBER: 'HWF037',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/11 19:50:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.7,
          ASSESSMENT_ASSISTANCE_DATE: 1557524160000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132197,
        },
      },
      {
        type: 'Feature',
        id: 71134044,
        geometry: {
          type: 'Point',
          coordinates: [-12806429.42737907, 7430432.514333901],
        },
        properties: {
          LABEL: 'SWF-081-2019',
          LATITUDE: 55.351730000000003,
          LONGITUDE: -115.042097,
          FIRE_NUMBER: 'SWF081',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/03 19:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.7,
          ASSESSMENT_ASSISTANCE_DATE: 1559291220000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134044,
        },
      },
      {
        type: 'Feature',
        id: 71133053,
        geometry: {
          type: 'Point',
          coordinates: [-12549689.93602768, 7650842.6684689587],
        },
        properties: {
          LABEL: 'MWF-042-2019',
          LATITUDE: 56.461500000000001,
          LONGITUDE: -112.735767,
          FIRE_NUMBER: 'MWF042',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/07 14:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1561672200000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71133053,
        },
      },
      {
        type: 'Feature',
        id: 71133189,
        geometry: {
          type: 'Point',
          coordinates: [-13090921.653923193, 7718517.6570921214],
        },
        properties: {
          LABEL: 'PWF-014-2019',
          LATITUDE: 56.795900000000003,
          LONGITUDE: -117.59773300000001,
          FIRE_NUMBER: 'PWF014',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/03/29 13:25:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1553824800000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133189,
        },
      },
      {
        type: 'Feature',
        id: 71132237,
        geometry: {
          type: 'Point',
          coordinates: [-12378230.565555247, 7469773.5780426208],
        },
        properties: {
          LABEL: 'LWF-103-2019',
          LATITUDE: 55.552145000000003,
          LONGITUDE: -111.195522,
          FIRE_NUMBER: 'LWF103',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/20 12:27:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558284120000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132237,
        },
      },
      {
        type: 'Feature',
        id: 71130299,
        geometry: {
          type: 'Point',
          coordinates: [-12601416.024308076, 7769569.30070181],
        },
        properties: {
          LABEL: 'SWF-125-2019',
          LATITUDE: 57.046202000000001,
          LONGITUDE: -113.20043,
          FIRE_NUMBER: 'SWF125',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/23 13:15:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.6000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1561256040000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130299,
        },
      },
      {
        type: 'Feature',
        id: 71133229,
        geometry: {
          type: 'Point',
          coordinates: [-12841895.409150442, 7561596.4576682327],
        },
        properties: {
          LABEL: 'SWF-048-2019',
          LATITUDE: 56.015968000000001,
          LONGITUDE: -115.360693,
          FIRE_NUMBER: 'SWF048',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 18:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.5700000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558208700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133229,
        },
      },
      {
        type: 'Feature',
        id: 71133364,
        geometry: {
          type: 'Point',
          coordinates: [-12319209.504821055, 7929001.9639227986],
        },
        properties: {
          LABEL: 'MWF-060-2019',
          LATITUDE: 57.817132000000001,
          LONGITUDE: -110.66532599999999,
          FIRE_NUMBER: 'MWF060',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/22 15:15:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563483120000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71133364,
        },
      },
      {
        type: 'Feature',
        id: 71130475,
        geometry: {
          type: 'Point',
          coordinates: [-12828823.688373081, 7918524.5219758255],
        },
        properties: {
          LABEL: 'HWF-093-2019',
          LATITUDE: 57.766967000000001,
          LONGITUDE: -115.243267,
          FIRE_NUMBER: 'HWF093',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/22 16:50:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.5,
          ASSESSMENT_ASSISTANCE_DATE: 1561065000000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130475,
        },
      },
      {
        type: 'Feature',
        id: 71132379,
        geometry: {
          type: 'Point',
          coordinates: [-13228340.128454065, 8111714.4317928888],
        },
        properties: {
          LABEL: 'HWF-117-2019',
          LATITUDE: 58.680799999999998,
          LONGITUDE: -118.832183,
          FIRE_NUMBER: 'HWF117',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/17 13:12:47',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563313260000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132379,
        },
      },
      {
        type: 'Feature',
        id: 71130566,
        geometry: {
          type: 'Point',
          coordinates: [-12785919.73838177, 7613807.8241564194],
        },
        properties: {
          LABEL: 'SWF-099-2019',
          LATITUDE: 56.277245000000001,
          LONGITUDE: -114.857855,
          FIRE_NUMBER: 'SWF099',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/20 11:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.3999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1559762460000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130566,
        },
      },
      {
        type: 'Feature',
        id: 71133537,
        geometry: {
          type: 'Point',
          coordinates: [-12568537.376150584, 7342733.0255403565],
        },
        properties: {
          LABEL: 'LWF-078-2019',
          LATITUDE: 54.901288000000001,
          LONGITUDE: -112.90507700000001,
          FIRE_NUMBER: 'LWF078',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/12 13:10:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.3999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557517800000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133537,
        },
      },
      {
        type: 'Feature',
        id: 71134061,
        geometry: {
          type: 'Point',
          coordinates: [-12279051.068293074, 7274061.2337521994],
        },
        properties: {
          LABEL: 'LWF-088-2019',
          LATITUDE: 54.545020000000001,
          LONGITUDE: -110.30457800000001,
          FIRE_NUMBER: 'LWF088',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/14 10:54:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.3500000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1557778440000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71134061,
        },
      },
      {
        type: 'Feature',
        id: 71130470,
        geometry: {
          type: 'Point',
          coordinates: [-13247768.98231839, 7352977.1701511778],
        },
        properties: {
          LABEL: 'GWF-011-2019',
          LATITUDE: 54.954166999999998,
          LONGITUDE: -119.00671699999999,
          FIRE_NUMBER: 'GWF011',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/15 15:25:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.3,
          ASSESSMENT_ASSISTANCE_DATE: 1557613800000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71130470,
        },
      },
      {
        type: 'Feature',
        id: 71130462,
        geometry: {
          type: 'Point',
          coordinates: [-12801535.225474715, 6680761.9544760464],
        },
        properties: {
          LABEL: 'CWF-014-2019',
          LATITUDE: 51.334966999999999,
          LONGITUDE: -114.998133,
          FIRE_NUMBER: 'CWF014',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/22 15:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.3,
          ASSESSMENT_ASSISTANCE_DATE: 1555885200000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71130462,
        },
      },
      {
        type: 'Feature',
        id: 71131525,
        geometry: {
          type: 'Point',
          coordinates: [-12326447.859422907, 7548294.4303411804],
        },
        properties: {
          LABEL: 'LWF-006-2019',
          LATITUDE: 55.949117000000001,
          LONGITUDE: -110.73035,
          FIRE_NUMBER: 'LWF006',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/08 16:45:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.3,
          ASSESSMENT_ASSISTANCE_DATE: 1554406500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71131525,
        },
      },
      {
        type: 'Feature',
        id: 71133981,
        geometry: {
          type: 'Point',
          coordinates: [-12819073.669510083, 6968519.6639814572],
        },
        properties: {
          LABEL: 'RWF-016-2019',
          LATITUDE: 52.92165,
          LONGITUDE: -115.155683,
          FIRE_NUMBER: 'RWF016',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/12 18:37:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.26,
          ASSESSMENT_ASSISTANCE_DATE: 1557684360000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71133981,
        },
      },
      {
        type: 'Feature',
        id: 71134265,
        geometry: {
          type: 'Point',
          coordinates: [-12971195.667345818, 8325379.5085353833],
        },
        properties: {
          LABEL: 'HWF-163-2019',
          LATITUDE: 59.664316999999997,
          LONGITUDE: -116.522215,
          FIRE_NUMBER: 'HWF163',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/04 16:35:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.26,
          ASSESSMENT_ASSISTANCE_DATE: 1564865460000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71134265,
        },
      },
      {
        type: 'Feature',
        id: 71132950,
        geometry: {
          type: 'Point',
          coordinates: [-12436376.264151456, 7756197.2865705984],
        },
        properties: {
          LABEL: 'MWF-019-2019',
          LATITUDE: 56.980801999999997,
          LONGITUDE: -111.71785300000001,
          FIRE_NUMBER: 'MWF019',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/29 13:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.23,
          ASSESSMENT_ASSISTANCE_DATE: 1559109540000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71132950,
        },
      },
      {
        type: 'Feature',
        id: 71132130,
        geometry: {
          type: 'Point',
          coordinates: [-12732773.107916811, 6372482.2207396692],
        },
        properties: {
          LABEL: 'CWF-017-2019',
          LATITUDE: 49.572000000000003,
          LONGITUDE: -114.380433,
          FIRE_NUMBER: 'CWF017',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/26 18:07:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.1200000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1556314260000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71132130,
        },
      },
      {
        type: 'Feature',
        id: 71131503,
        geometry: {
          type: 'Point',
          coordinates: [-12467869.60280985, 7947963.6856328333],
        },
        properties: {
          LABEL: 'MWF-072-2019',
          LATITUDE: 57.907743000000004,
          LONGITUDE: -112.00076199999999,
          FIRE_NUMBER: 'MWF072',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/26 15:21:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.1000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1563920400000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71131503,
        },
      },
      {
        type: 'Feature',
        id: 71132214,
        geometry: {
          type: 'Point',
          coordinates: [-12983151.645600058, 8055339.6977219619],
        },
        properties: {
          LABEL: 'HWF-050-2019',
          LATITUDE: 58.416562999999996,
          LONGITUDE: -116.62961799999999,
          FIRE_NUMBER: 'HWF050',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 15:35:50',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1.0900000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1558137600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132214,
        },
      },
      {
        type: 'Feature',
        id: 71130482,
        geometry: {
          type: 'Point',
          coordinates: [-12796546.326154849, 6658881.2729691491],
        },
        properties: {
          LABEL: 'CWF-044-2019',
          LATITUDE: 51.212000000000003,
          LONGITUDE: -114.953317,
          FIRE_NUMBER: 'CWF044',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/03 18:10:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1,
          ASSESSMENT_ASSISTANCE_DATE: 1559520840000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71130482,
        },
      },
      {
        type: 'Feature',
        id: 71130351,
        geometry: {
          type: 'Point',
          coordinates: [-12680312.013097068, 6416025.0545109855],
        },
        properties: {
          LABEL: 'CWF-043-2019',
          LATITUDE: 49.825000000000003,
          LONGITUDE: -113.909167,
          FIRE_NUMBER: 'CWF043',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/10 14:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1,
          ASSESSMENT_ASSISTANCE_DATE: 1559509860000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71130351,
        },
      },
      {
        type: 'Feature',
        id: 71133000,
        geometry: {
          type: 'Point',
          coordinates: [-12975313.161529385, 7463117.2837977624],
        },
        properties: {
          LABEL: 'SWF-109-2019',
          LATITUDE: 55.518307999999998,
          LONGITUDE: -116.55920500000001,
          FIRE_NUMBER: 'SWF109',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/18 17:10:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1,
          ASSESSMENT_ASSISTANCE_DATE: 1560550920000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133000,
        },
      },
      {
        type: 'Feature',
        id: 71135420,
        geometry: {
          type: 'Point',
          coordinates: [-13092660.308561286, 8399947.106776949],
        },
        properties: {
          LABEL: 'HTZ-002-2019',
          LATITUDE: 60.000933000000003,
          LONGITUDE: -117.61335,
          FIRE_NUMBER: 'HTZ002',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Mutual Aid',
          FIRE_STATUS: 'AE',
          FIRE_STATUS_DATE: '2019/08/31 11:13:04',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 1,
          ASSESSMENT_ASSISTANCE_DATE: null,
          GENERAL_CAUSE: null,
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71135420,
        },
      },
      {
        type: 'Feature',
        id: 71132409,
        geometry: {
          type: 'Point',
          coordinates: [-13174159.136663157, 8100843.232101623],
        },
        properties: {
          LABEL: 'HWF-173-2019',
          LATITUDE: 58.630000000000003,
          LONGITUDE: -118.345467,
          FIRE_NUMBER: 'HWF173',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/10/10 10:45:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.98999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1570209780000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132409,
        },
      },
      {
        type: 'Feature',
        id: 71130295,
        geometry: {
          type: 'Point',
          coordinates: [-12817532.080009799, 7818012.6144859754],
        },
        properties: {
          LABEL: 'SWF-094-2019',
          LATITUDE: 57.282167000000001,
          LONGITUDE: -115.14183300000001,
          FIRE_NUMBER: 'SWF094',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/03 17:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.97999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1559517960000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130295,
        },
      },
      {
        type: 'Feature',
        id: 71130456,
        geometry: {
          type: 'Point',
          coordinates: [-12562259.71501933, 7757054.898310964],
        },
        properties: {
          LABEL: 'MWF-066-2019',
          LATITUDE: 56.984999999999999,
          LONGITUDE: -112.84868299999999,
          FIRE_NUMBER: 'MWF066',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/20 17:17:52',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.97999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1563563160000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71130456,
        },
      },
      {
        type: 'Feature',
        id: 71130451,
        geometry: {
          type: 'Point',
          coordinates: [-12618691.318056608, 7558553.0406037318],
        },
        properties: {
          LABEL: 'SWF-101-2019',
          LATITUDE: 56.000683000000002,
          LONGITUDE: -113.355617,
          FIRE_NUMBER: 'SWF101',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/08 14:50:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.94999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1559948760000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130451,
        },
      },
      {
        type: 'Feature',
        id: 71130330,
        geometry: {
          type: 'Point',
          coordinates: [-12336479.652302725, 7602701.9596802834],
        },
        properties: {
          LABEL: 'LWF-106-2019',
          LATITUDE: 56.221817000000001,
          LONGITUDE: -110.82046699999999,
          FIRE_NUMBER: 'LWF106',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/23 11:55:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.94999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1558452660000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130330,
        },
      },
      {
        type: 'Feature',
        id: 71133266,
        geometry: {
          type: 'Point',
          coordinates: [-12895670.894937053, 7207257.4714856558],
        },
        properties: {
          LABEL: 'WWF-004-2019',
          LATITUDE: 54.195433000000001,
          LONGITUDE: -115.843767,
          FIRE_NUMBER: 'WWF004',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/22 17:21:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.93000000000000005,
          ASSESSMENT_ASSISTANCE_DATE: 1555966800000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71133266,
        },
      },
      {
        type: 'Feature',
        id: 71130423,
        geometry: {
          type: 'Point',
          coordinates: [-12328471.980856996, 7541935.1342560221],
        },
        properties: {
          LABEL: 'LWF-013-2019',
          LATITUDE: 55.917116999999998,
          LONGITUDE: -110.74853299999999,
          FIRE_NUMBER: 'LWF013',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/18 14:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.91000000000000003,
          ASSESSMENT_ASSISTANCE_DATE: 1555099980000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130423,
        },
      },
      {
        type: 'Feature',
        id: 71130335,
        geometry: {
          type: 'Point',
          coordinates: [-12749912.993433028, 8066908.5091287373],
        },
        properties: {
          LABEL: 'HWF-021-2019',
          LATITUDE: 58.470950000000002,
          LONGITUDE: -114.53440000000001,
          FIRE_NUMBER: 'HWF021',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/22 13:40:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.90000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1555956780000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130335,
        },
      },
      {
        type: 'Feature',
        id: 71130309,
        geometry: {
          type: 'Point',
          coordinates: [-13312978.279134851, 8227837.5679530064],
        },
        properties: {
          LABEL: 'HWF-153-2019',
          LATITUDE: 59.218832999999997,
          LONGITUDE: -119.5925,
          FIRE_NUMBER: 'HWF153',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/25 12:43:54',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.90000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563926820000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130309,
        },
      },
      {
        type: 'Feature',
        id: 71132438,
        geometry: {
          type: 'Point',
          coordinates: [-12856185.977205068, 7718924.2795049194],
        },
        properties: {
          LABEL: 'SWF-142-2019',
          LATITUDE: 56.797899999999998,
          LONGITUDE: -115.48906700000001,
          FIRE_NUMBER: 'SWF142',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/17 14:07:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.90000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563307980000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132438,
        },
      },
      {
        type: 'Feature',
        id: 71131090,
        geometry: {
          type: 'Point',
          coordinates: [-12665907.488346193, 7546242.7100087628],
        },
        properties: {
          LABEL: 'SWF-002-2019',
          LATITUDE: 55.938796000000004,
          LONGITUDE: -113.77976700000001,
          FIRE_NUMBER: 'SWF002',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/03/29 16:40:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.90000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1553895600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71131090,
        },
      },
      {
        type: 'Feature',
        id: 71132974,
        geometry: {
          type: 'Point',
          coordinates: [-13048199.115604362, 7714165.3135344768],
        },
        properties: {
          LABEL: 'PWF-021-2019',
          LATITUDE: 56.774482999999996,
          LONGITUDE: -117.21395,
          FIRE_NUMBER: 'PWF021',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/12 12:05:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.90000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1554332520000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132974,
        },
      },
      {
        type: 'Feature',
        id: 71134129,
        geometry: {
          type: 'Point',
          coordinates: [-12672614.487677336, 7539329.8602439901],
        },
        properties: {
          LABEL: 'SWF-021-2019',
          LATITUDE: 55.904000000000003,
          LONGITUDE: -113.840017,
          FIRE_NUMBER: 'SWF021',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/02 17:35:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.90000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1556835000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134129,
        },
      },
      {
        type: 'Feature',
        id: 71134127,
        geometry: {
          type: 'Point',
          coordinates: [-13102472.866910977, 7378300.5531171355],
        },
        properties: {
          LABEL: 'GWF-014-2019',
          LATITUDE: 55.084583000000002,
          LONGITUDE: -117.7015,
          FIRE_NUMBER: 'GWF014',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/22 17:25:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.89000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1557639420000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71134127,
        },
      },
      {
        type: 'Feature',
        id: 71131180,
        geometry: {
          type: 'Point',
          coordinates: [-13210983.636806699, 8118409.8169780364],
        },
        properties: {
          LABEL: 'HWF-035-2019',
          LATITUDE: 58.712049999999998,
          LONGITUDE: -118.676267,
          FIRE_NUMBER: 'HWF035',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/10 17:35:26',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.84999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1557510900000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131180,
        },
      },
      {
        type: 'Feature',
        id: 71131289,
        geometry: {
          type: 'Point',
          coordinates: [-13285415.537355253, 8117230.9744671816],
        },
        properties: {
          LABEL: 'HWF-135-2019',
          LATITUDE: 58.70655,
          LONGITUDE: -119.3449,
          FIRE_NUMBER: 'HWF135',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/22 12:45:58',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1563652860000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131289,
        },
      },
      {
        type: 'Feature',
        id: 71131568,
        geometry: {
          type: 'Point',
          coordinates: [-12804858.424017731, 8103263.8003814239],
        },
        properties: {
          LABEL: 'HWF-002-2019',
          LATITUDE: 58.641317000000001,
          LONGITUDE: -115.02798300000001,
          FIRE_NUMBER: 'HWF002',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/25 16:21:37',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1547748300000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131568,
        },
      },
      {
        type: 'Feature',
        id: 71130304,
        geometry: {
          type: 'Point',
          coordinates: [-13041252.693654867, 7348416.2916360842],
        },
        properties: {
          LABEL: 'GWF-038-2019',
          LATITUDE: 54.930633,
          LONGITUDE: -117.15155,
          FIRE_NUMBER: 'GWF038',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/30 15:45:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1563396300000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71130304,
        },
      },
      {
        type: 'Feature',
        id: 71130315,
        geometry: {
          type: 'Point',
          coordinates: [-12606195.664585581, 7385840.8000775408],
        },
        properties: {
          LABEL: 'LWF-073-2019',
          LATITUDE: 55.123333000000002,
          LONGITUDE: -113.24336700000001,
          FIRE_NUMBER: 'LWF073',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/07 11:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1557094620000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130315,
        },
      },
      {
        type: 'Feature',
        id: 71130356,
        geometry: {
          type: 'Point',
          coordinates: [-12947194.392658746, 7867326.1591581237],
        },
        properties: {
          LABEL: 'HWF-087-2019',
          LATITUDE: 57.520826999999997,
          LONGITUDE: -116.30660899999999,
          FIRE_NUMBER: 'HWF087',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/18 17:01:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1560820440000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130356,
        },
      },
      {
        type: 'Feature',
        id: 71130457,
        geometry: {
          type: 'Point',
          coordinates: [-12541047.7850718, 7775031.4248873964],
        },
        properties: {
          LABEL: 'MWF-067-2019',
          LATITUDE: 57.072882999999997,
          LONGITUDE: -112.65813300000001,
          FIRE_NUMBER: 'MWF067',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 16:48:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1563563400000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71130457,
        },
      },
      {
        type: 'Feature',
        id: 71130454,
        geometry: {
          type: 'Point',
          coordinates: [-12294208.512211815, 7594100.2282923311],
        },
        properties: {
          LABEL: 'LWF-133-2019',
          LATITUDE: 56.178832,
          LONGITUDE: -110.44073899999999,
          FIRE_NUMBER: 'LWF133',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 17:21:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1563414960000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130454,
        },
      },
      {
        type: 'Feature',
        id: 71134054,
        geometry: {
          type: 'Point',
          coordinates: [-12376953.241126526, 7256643.3285054676],
        },
        properties: {
          LABEL: 'LWF-104-2019',
          LATITUDE: 54.454158,
          LONGITUDE: -111.184048,
          FIRE_NUMBER: 'LWF104',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/20 16:41:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1558370460000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71134054,
        },
      },
      {
        type: 'Feature',
        id: 71134022,
        geometry: {
          type: 'Point',
          coordinates: [-13256424.14936129, 7674068.5746582458],
        },
        properties: {
          LABEL: 'PWF-049-2019',
          LATITUDE: 56.576599999999999,
          LONGITUDE: -119.084467,
          FIRE_NUMBER: 'PWF049',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/10 19:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1557533040000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71134022,
        },
      },
      {
        type: 'Feature',
        id: 71134029,
        geometry: {
          type: 'Point',
          coordinates: [-12861024.474254807, 7193572.2835015208],
        },
        properties: {
          LABEL: 'WWF-012-2019',
          LATITUDE: 54.123449999999998,
          LONGITUDE: -115.532533,
          FIRE_NUMBER: 'WWF012',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/26 16:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1556283000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71134029,
        },
      },
      {
        type: 'Feature',
        id: 71133429,
        geometry: {
          type: 'Point',
          coordinates: [-12324844.85808045, 7547018.7720713029],
        },
        properties: {
          LABEL: 'LWF-081-2019',
          LATITUDE: 55.942700000000002,
          LONGITUDE: -110.71595000000001,
          FIRE_NUMBER: 'LWF081',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/11 18:25:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.80000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1557599820000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133429,
        },
      },
      {
        type: 'Feature',
        id: 71133980,
        geometry: {
          type: 'Point',
          coordinates: [-12629197.98197577, 7531390.8119171634],
        },
        properties: {
          LABEL: 'SWF-023-2019',
          LATITUDE: 55.863999999999997,
          LONGITUDE: -113.45,
          FIRE_NUMBER: 'SWF023',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/05 11:29:31',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.78000000000000003,
          ASSESSMENT_ASSISTANCE_DATE: 1557017100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133980,
        },
      },
      {
        type: 'Feature',
        id: 71133437,
        geometry: {
          type: 'Point',
          coordinates: [-13210180.243975366, 8118806.3720744671],
        },
        properties: {
          LABEL: 'HWF-018-2019',
          LATITUDE: 58.713900000000002,
          LONGITUDE: -118.66905,
          FIRE_NUMBER: 'HWF018',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/19 16:16:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.75,
          ASSESSMENT_ASSISTANCE_DATE: 1555694100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133437,
        },
      },
      {
        type: 'Feature',
        id: 71133014,
        geometry: {
          type: 'Point',
          coordinates: [-12746371.140309591, 8066763.1170029119],
        },
        properties: {
          LABEL: 'HWF-058-2019',
          LATITUDE: 58.470267,
          LONGITUDE: -114.502583,
          FIRE_NUMBER: 'HWF058',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/24 13:00:27',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.75,
          ASSESSMENT_ASSISTANCE_DATE: 1558714380000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133014,
        },
      },
      {
        type: 'Feature',
        id: 71130485,
        geometry: {
          type: 'Point',
          coordinates: [-12503234.425583618, 7724465.9865625976],
        },
        properties: {
          LABEL: 'MWF-061-2019',
          LATITUDE: 56.825150000000001,
          LONGITUDE: -112.31845,
          FIRE_NUMBER: 'MWF061',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 11:55:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.75,
          ASSESSMENT_ASSISTANCE_DATE: 1563493380000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71130485,
        },
      },
      {
        type: 'Feature',
        id: 71131347,
        geometry: {
          type: 'Point',
          coordinates: [-13209142.919459663, 7471913.2681037439],
        },
        properties: {
          LABEL: 'GWF-020-2019',
          LATITUDE: 55.563017000000002,
          LONGITUDE: -118.659733,
          FIRE_NUMBER: 'GWF020',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/24 16:57:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.75,
          ASSESSMENT_ASSISTANCE_DATE: 1558407600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71131347,
        },
      },
      {
        type: 'Feature',
        id: 71132902,
        geometry: {
          type: 'Point',
          coordinates: [-12364198.168939542, 7399818.4861244727],
        },
        properties: {
          LABEL: 'LWF-109-2019',
          LATITUDE: 55.195067000000002,
          LONGITUDE: -111.069467,
          FIRE_NUMBER: 'LWF109',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/23 11:14:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.72999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1558551900000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132902,
        },
      },
      {
        type: 'Feature',
        id: 71133019,
        geometry: {
          type: 'Point',
          coordinates: [-12338491.919629324, 7340724.695207187],
        },
        properties: {
          LABEL: 'LWF-091-2019',
          LATITUDE: 54.890912999999998,
          LONGITUDE: -110.838544,
          FIRE_NUMBER: 'LWF091',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/15 14:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1557871860000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133019,
        },
      },
      {
        type: 'Feature',
        id: 71133447,
        geometry: {
          type: 'Point',
          coordinates: [-13174487.40279977, 7596187.2924042065],
        },
        properties: {
          LABEL: 'PWF-068-2019',
          LATITUDE: 56.189267000000001,
          LONGITUDE: -118.348417,
          FIRE_NUMBER: 'PWF068',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/26 14:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1558468560000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133447,
        },
      },
      {
        type: 'Feature',
        id: 71133936,
        geometry: {
          type: 'Point',
          coordinates: [-12864215.671144066, 7195301.0342684658],
        },
        properties: {
          LABEL: 'WWF-010-2019',
          LATITUDE: 54.132550000000002,
          LONGITUDE: -115.5612,
          FIRE_NUMBER: 'WWF010',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/25 13:05:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1556153700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71133936,
        },
      },
      {
        type: 'Feature',
        id: 71133937,
        geometry: {
          type: 'Point',
          coordinates: [-12852903.719169537, 7210359.6972064897],
        },
        properties: {
          LABEL: 'WWF-017-2019',
          LATITUDE: 54.211733000000002,
          LONGITUDE: -115.45958299999999,
          FIRE_NUMBER: 'WWF017',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/12 20:32:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1557700800000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71133937,
        },
      },
      {
        type: 'Feature',
        id: 71134099,
        geometry: {
          type: 'Point',
          coordinates: [-12819925.424421472, 7712326.9773008879],
        },
        properties: {
          LABEL: 'SWF-113-2019',
          LATITUDE: 56.765433000000002,
          LONGITUDE: -115.16333299999999,
          FIRE_NUMBER: 'SWF113',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/17 16:26:57',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1560723900000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134099,
        },
      },
      {
        type: 'Feature',
        id: 71134293,
        geometry: {
          type: 'Point',
          coordinates: [-12665907.489156764, 7549870.2854637699],
        },
        properties: {
          LABEL: 'SWF-008-2019',
          LATITUDE: 55.957042999999999,
          LONGITUDE: -113.77976700000001,
          FIRE_NUMBER: 'SWF008',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/15 19:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1555376460000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134293,
        },
      },
      {
        type: 'Feature',
        id: 71134223,
        geometry: {
          type: 'Point',
          coordinates: [-12843237.58483471, 7545263.0584553825],
        },
        properties: {
          LABEL: 'SWF-017-2019',
          LATITUDE: 55.933866999999999,
          LONGITUDE: -115.37275,
          FIRE_NUMBER: 'SWF017',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/24 19:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1556147760000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134223,
        },
      },
      {
        type: 'Feature',
        id: 71132346,
        geometry: {
          type: 'Point',
          coordinates: [-12354483.503514457, 7235291.6087493664],
        },
        properties: {
          LABEL: 'LWF-026-2019',
          LATITUDE: 54.342498999999997,
          LONGITUDE: -110.98219899999999,
          FIRE_NUMBER: 'LWF026',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/07 11:09:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.69999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1555637400000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132346,
        },
      },
      {
        type: 'Feature',
        id: 71134024,
        geometry: {
          type: 'Point',
          coordinates: [-12660617.93724589, 7627760.5758891515],
        },
        properties: {
          LABEL: 'SWF-086-2019',
          LATITUDE: 56.346767,
          LONGITUDE: -113.73224999999999,
          FIRE_NUMBER: 'SWF086',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/02 17:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.67000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1559496480000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71134024,
        },
      },
      {
        type: 'Feature',
        id: 71133458,
        geometry: {
          type: 'Point',
          coordinates: [-13058908.032985309, 7630523.0517762918],
        },
        properties: {
          LABEL: 'PWF-042-2019',
          LATITUDE: 56.360517000000002,
          LONGITUDE: -117.31014999999999,
          FIRE_NUMBER: 'PWF042',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/06 09:17:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1557117300000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133458,
        },
      },
      {
        type: 'Feature',
        id: 71133027,
        geometry: {
          type: 'Point',
          coordinates: [-12326607.3791635, 7543057.5660639275],
        },
        properties: {
          LABEL: 'LWF-016-2019',
          LATITUDE: 55.922767,
          LONGITUDE: -110.73178299999999,
          FIRE_NUMBER: 'LWF016',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/14 16:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1555182000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71133027,
        },
      },
      {
        type: 'Feature',
        id: 71134174,
        geometry: {
          type: 'Point',
          coordinates: [-13008907.173308602, 8383208.539440616],
        },
        properties: {
          LABEL: 'HWF-107-2019',
          LATITUDE: 59.925666999999997,
          LONGITUDE: -116.860983,
          FIRE_NUMBER: 'HWF107',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/11 08:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1562532900000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71134174,
        },
      },
      {
        type: 'Feature',
        id: 71134294,
        geometry: {
          type: 'Point',
          coordinates: [-12396156.323862307, 8315240.4398212079],
        },
        properties: {
          LABEL: 'MWF-029-2019',
          LATITUDE: 59.618282999999998,
          LONGITUDE: -111.35655,
          FIRE_NUMBER: 'MWF029',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/20 15:30:21',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1560897840000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71134294,
        },
      },
      {
        type: 'Feature',
        id: 71130506,
        geometry: {
          type: 'Point',
          coordinates: [-12625478.571148215, 7517239.8905948754],
        },
        properties: {
          LABEL: 'SWF-037-2019',
          LATITUDE: 55.7926,
          LONGITUDE: -113.416588,
          FIRE_NUMBER: 'SWF037',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/17 13:00:50',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1557980100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130506,
        },
      },
      {
        type: 'Feature',
        id: 71130412,
        geometry: {
          type: 'Point',
          coordinates: [-12840172.494049625, 6926177.4855489358],
        },
        properties: {
          LABEL: 'RWF-014-2019',
          LATITUDE: 52.691716999999997,
          LONGITUDE: -115.34521700000001,
          FIRE_NUMBER: 'RWF014',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/25 19:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1556233560000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71130412,
        },
      },
      {
        type: 'Feature',
        id: 71130422,
        geometry: {
          type: 'Point',
          coordinates: [-12326867.199328167, 7544951.7668357519],
        },
        properties: {
          LABEL: 'LWF-012-2019',
          LATITUDE: 55.932299999999998,
          LONGITUDE: -110.734117,
          FIRE_NUMBER: 'LWF012',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/15 13:41:43',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1555097580000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130422,
        },
      },
      {
        type: 'Feature',
        id: 71130323,
        geometry: {
          type: 'Point',
          coordinates: [-13097254.114346346, 8340157.7131629977],
        },
        properties: {
          LABEL: 'HWF-122-2019',
          LATITUDE: 59.731299999999997,
          LONGITUDE: -117.654617,
          FIRE_NUMBER: 'HWF122',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/17 17:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.59999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1563327000000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130323,
        },
      },
      {
        type: 'Feature',
        id: 71130300,
        geometry: {
          type: 'Point',
          coordinates: [-12591154.585932517, 7747040.1291333148],
        },
        properties: {
          LABEL: 'SWF-126-2019',
          LATITUDE: 56.935949999999998,
          LONGITUDE: -113.10825,
          FIRE_NUMBER: 'SWF126',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/23 11:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.57999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1561256700000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130300,
        },
      },
      {
        type: 'Feature',
        id: 71130347,
        geometry: {
          type: 'Point',
          coordinates: [-12893190.228697961, 7100430.6265461603],
        },
        properties: {
          LABEL: 'EWF-037-2019',
          LATITUDE: 53.630200000000002,
          LONGITUDE: -115.821483,
          FIRE_NUMBER: 'EWF037',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/20 23:48:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.57999999999999996,
          ASSESSMENT_ASSISTANCE_DATE: 1558401000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71130347,
        },
      },
      {
        type: 'Feature',
        id: 71133564,
        geometry: {
          type: 'Point',
          coordinates: [-12850827.66047512, 7443311.1701213149],
        },
        properties: {
          LABEL: 'SWF-061-2019',
          LATITUDE: 55.417450000000002,
          LONGITUDE: -115.440933,
          FIRE_NUMBER: 'SWF061',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/21 19:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.56999999999999995,
          ASSESSMENT_ASSISTANCE_DATE: 1558470120000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133564,
        },
      },
      {
        type: 'Feature',
        id: 71133516,
        geometry: {
          type: 'Point',
          coordinates: [-12412058.333747875, 8378352.7643971667],
        },
        properties: {
          LABEL: 'MWF-030-2019',
          LATITUDE: 59.903799999999997,
          LONGITUDE: -111.49939999999999,
          FIRE_NUMBER: 'MWF030',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/19 18:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.54000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1560957240000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71133516,
        },
      },
      {
        type: 'Feature',
        id: 71130340,
        geometry: {
          type: 'Point',
          coordinates: [-12836832.93418939, 7055109.150957441],
        },
        properties: {
          LABEL: 'EWF-028-2019',
          LATITUDE: 53.388083000000002,
          LONGITUDE: -115.315217,
          FIRE_NUMBER: 'EWF028',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 15:28:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.54000000000000004,
          ASSESSMENT_ASSISTANCE_DATE: 1558212000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71130340,
        },
      },
      {
        type: 'Feature',
        id: 71130305,
        geometry: {
          type: 'Point',
          coordinates: [-12607668.83144201, 7725869.8023743303],
        },
        properties: {
          LABEL: 'SWF-154-2019',
          LATITUDE: 56.832050000000002,
          LONGITUDE: -113.25660000000001,
          FIRE_NUMBER: 'SWF154',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 12:19:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563492000000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130305,
        },
      },
      {
        type: 'Feature',
        id: 71130303,
        geometry: {
          type: 'Point',
          coordinates: [-13040818.74691597, 8169272.6516276915],
        },
        properties: {
          LABEL: 'HWF-108-2019',
          LATITUDE: 58.948532999999998,
          LONGITUDE: -117.14765,
          FIRE_NUMBER: 'HWF108',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/09 20:08:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1562692620000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130303,
        },
      },
      {
        type: 'Feature',
        id: 71130379,
        geometry: {
          type: 'Point',
          coordinates: [-12402683.176474502, 7716631.0262737656],
        },
        properties: {
          LABEL: 'MWF-020-2019',
          LATITUDE: 56.786617,
          LONGITUDE: -111.415183,
          FIRE_NUMBER: 'MWF020',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/31 12:35:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1559236500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71130379,
        },
      },
      {
        type: 'Feature',
        id: 71130369,
        geometry: {
          type: 'Point',
          coordinates: [-13328525.973200407, 8340606.644942903],
        },
        properties: {
          LABEL: 'HWF-109-2019',
          LATITUDE: 59.733333000000002,
          LONGITUDE: -119.732167,
          FIRE_NUMBER: 'HWF109',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/12 13:40:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1562894760000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130369,
        },
      },
      {
        type: 'Feature',
        id: 71130469,
        geometry: {
          type: 'Point',
          coordinates: [-12644621.258168586, 7333941.0085372524],
        },
        properties: {
          LABEL: 'LWF-077-2019',
          LATITUDE: 54.855849999999997,
          LONGITUDE: -113.58855,
          FIRE_NUMBER: 'LWF077',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/10 13:10:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1557507660000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130469,
        },
      },
      {
        type: 'Feature',
        id: 71130523,
        geometry: {
          type: 'Point',
          coordinates: [-12327290.213005252, 7542663.4049935844],
        },
        properties: {
          LABEL: 'LWF-017-2019',
          LATITUDE: 55.920783,
          LONGITUDE: -110.737917,
          FIRE_NUMBER: 'LWF017',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/16 18:56:08',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1555270200000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130523,
        },
      },
      {
        type: 'Feature',
        id: 71130559,
        geometry: {
          type: 'Point',
          coordinates: [-12780314.046668345, 6648350.3465529038],
        },
        properties: {
          LABEL: 'CWF-011-2019',
          LATITUDE: 51.152700000000003,
          LONGITUDE: -114.8075,
          FIRE_NUMBER: 'CWF011',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/03 16:39:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1554329460000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71130559,
        },
      },
      {
        type: 'Feature',
        id: 71130476,
        geometry: {
          type: 'Point',
          coordinates: [-12788431.131640054, 6648152.1099382751],
        },
        properties: {
          LABEL: 'CWF-005-2019',
          LATITUDE: 51.151583000000002,
          LONGITUDE: -114.88041699999999,
          FIRE_NUMBER: 'CWF005',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/01/31 16:50:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1548977400000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71130476,
        },
      },
      {
        type: 'Feature',
        id: 71130440,
        geometry: {
          type: 'Point',
          coordinates: [-12303840.781493274, 7657776.925378425],
        },
        properties: {
          LABEL: 'MWF-035-2019',
          LATITUDE: 56.495899999999999,
          LONGITUDE: -110.52726699999999,
          FIRE_NUMBER: 'MWF035',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/23 14:05:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1561236360000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Fort McMurray Forest Area',
          OBJECTID: 71130440,
        },
      },
      {
        type: 'Feature',
        id: 71130424,
        geometry: {
          type: 'Point',
          coordinates: [-12327190.025311574, 7542074.1879527122],
        },
        properties: {
          LABEL: 'LWF-014-2019',
          LATITUDE: 55.917816999999999,
          LONGITUDE: -110.73701699999999,
          FIRE_NUMBER: 'LWF014',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/13 11:54:41',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1555104600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130424,
        },
      },
      {
        type: 'Feature',
        id: 71130434,
        geometry: {
          type: 'Point',
          coordinates: [-13210193.268169886, 8118099.0186324548],
        },
        properties: {
          LABEL: 'HWF-013-2019',
          LATITUDE: 58.710599999999999,
          LONGITUDE: -118.669167,
          FIRE_NUMBER: 'HWF013',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/15 12:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1555119060000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130434,
        },
      },
      {
        type: 'Feature',
        id: 71132220,
        geometry: {
          type: 'Point',
          coordinates: [-12758577.440465994, 6513199.6529831737],
        },
        properties: {
          LABEL: 'CWF-006-2019',
          LATITUDE: 50.384877000000003,
          LONGITUDE: -114.61223699999999,
          FIRE_NUMBER: 'CWF006',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/03/04 12:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1551457800000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71132220,
        },
      },
      {
        type: 'Feature',
        id: 71132199,
        geometry: {
          type: 'Point',
          coordinates: [-12992698.824260641, 7478983.5239534853],
        },
        properties: {
          LABEL: 'PWF-060-2019',
          LATITUDE: 55.598917,
          LONGITUDE: -116.715383,
          FIRE_NUMBER: 'PWF060',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/16 18:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1558045200000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132199,
        },
      },
      {
        type: 'Feature',
        id: 71132437,
        geometry: {
          type: 'Point',
          coordinates: [-12860896.59193306, 7794696.3996166969],
        },
        properties: {
          LABEL: 'SWF-141-2019',
          LATITUDE: 57.168782999999998,
          LONGITUDE: -115.53138300000001,
          FIRE_NUMBER: 'SWF141',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 10:58:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563303840000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132437,
        },
      },
      {
        type: 'Feature',
        id: 71132505,
        geometry: {
          type: 'Point',
          coordinates: [-12975565.1772097, 8311444.4643712742],
        },
        properties: {
          LABEL: 'HWF-147-2019',
          LATITUDE: 59.601033000000001,
          LONGITUDE: -116.56146699999999,
          FIRE_NUMBER: 'HWF147',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 17:50:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563844500000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132505,
        },
      },
      {
        type: 'Feature',
        id: 71132512,
        geometry: {
          type: 'Point',
          coordinates: [-12985774.798015537, 7684079.0060978858],
        },
        properties: {
          LABEL: 'PWF-112-2019',
          LATITUDE: 56.626100000000001,
          LONGITUDE: -116.653183,
          FIRE_NUMBER: 'PWF112',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/26 11:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563924000000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71132512,
        },
      },
      {
        type: 'Feature',
        id: 71132513,
        geometry: {
          type: 'Point',
          coordinates: [-13112239.449091941, 8144459.3614647957],
        },
        properties: {
          LABEL: 'HWF-146-2019',
          LATITUDE: 58.833367000000003,
          LONGITUDE: -117.789233,
          FIRE_NUMBER: 'HWF146',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/23 15:33:43',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1563832500000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132513,
        },
      },
      {
        type: 'Feature',
        id: 71133430,
        geometry: {
          type: 'Point',
          coordinates: [-13168106.906433452, 7613932.4543955252],
        },
        properties: {
          LABEL: 'PWF-043-2019',
          LATITUDE: 56.277867000000001,
          LONGITUDE: -118.2911,
          FIRE_NUMBER: 'PWF043',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/22 17:45:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1557374700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133430,
        },
      },
      {
        type: 'Feature',
        id: 71134065,
        geometry: {
          type: 'Point',
          coordinates: [-13113897.525276775, 7084617.4529361082],
        },
        properties: {
          LABEL: 'EWF-065-2019',
          LATITUDE: 53.545879999999997,
          LONGITUDE: -117.80413,
          FIRE_NUMBER: 'EWF065',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/10/08 13:15:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1570394700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71134065,
        },
      },
      {
        type: 'Feature',
        id: 71132999,
        geometry: {
          type: 'Point',
          coordinates: [-12325438.524433618, 7544054.1841294477],
        },
        properties: {
          LABEL: 'LWF-015-2019',
          LATITUDE: 55.927782999999998,
          LONGITUDE: -110.721283,
          FIRE_NUMBER: 'LWF015',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/15 11:56:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1555178400000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132999,
        },
      },
      {
        type: 'Feature',
        id: 71133193,
        geometry: {
          type: 'Point',
          coordinates: [-12749669.982877934, 8066716.9230709998],
        },
        properties: {
          LABEL: 'HWF-027-2019',
          LATITUDE: 58.470050000000001,
          LONGITUDE: -114.532217,
          FIRE_NUMBER: 'HWF027',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/03 10:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1556829120000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133193,
        },
      },
      {
        type: 'Feature',
        id: 71133040,
        geometry: {
          type: 'Point',
          coordinates: [-12860963.248515092, 7193540.5616235081],
        },
        properties: {
          LABEL: 'WWF-013-2019',
          LATITUDE: 54.123283000000001,
          LONGITUDE: -115.531983,
          FIRE_NUMBER: 'WWF013',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/26 16:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1556294400000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71133040,
        },
      },
      {
        type: 'Feature',
        id: 71134165,
        geometry: {
          type: 'Point',
          coordinates: [-13347381.372991113, 7429179.9608012615],
        },
        properties: {
          LABEL: 'GWF-006-2019',
          LATITUDE: 55.345332999999997,
          LONGITUDE: -119.90155,
          FIRE_NUMBER: 'GWF006',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/01 15:25:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.5,
          ASSESSMENT_ASSISTANCE_DATE: 1556312640000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71134165,
        },
      },
      {
        type: 'Feature',
        id: 71132982,
        geometry: {
          type: 'Point',
          coordinates: [-12275708.367558545, 7282781.2674948778],
        },
        properties: {
          LABEL: 'LWF-079-2019',
          LATITUDE: 54.590432999999997,
          LONGITUDE: -110.27455,
          FIRE_NUMBER: 'LWF079',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/11 16:15:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.46999999999999997,
          ASSESSMENT_ASSISTANCE_DATE: 1557543600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132982,
        },
      },
      {
        type: 'Feature',
        id: 71132244,
        geometry: {
          type: 'Point',
          coordinates: [-12779862.423856162, 6650878.4873284185],
        },
        properties: {
          LABEL: 'CWF-002-2019',
          LATITUDE: 51.166943000000003,
          LONGITUDE: -114.803443,
          FIRE_NUMBER: 'CWF002',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/01/01 11:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.45000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1546331460000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71132244,
        },
      },
      {
        type: 'Feature',
        id: 71130334,
        geometry: {
          type: 'Point',
          coordinates: [-13219744.482857881, 8119353.0067262314],
        },
        properties: {
          LABEL: 'HWF-020-2019',
          LATITUDE: 58.716450000000002,
          LONGITUDE: -118.75496699999999,
          FIRE_NUMBER: 'HWF020',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/22 13:55:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.45000000000000001,
          ASSESSMENT_ASSISTANCE_DATE: 1555915500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130334,
        },
      },
      {
        type: 'Feature',
        id: 71134211,
        geometry: {
          type: 'Point',
          coordinates: [-13034302.66984788, 7290880.2181279995],
        },
        properties: {
          LABEL: 'GWF-015-2019',
          LATITUDE: 54.632567000000002,
          LONGITUDE: -117.089117,
          FIRE_NUMBER: 'GWF015',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/17 10:53:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.44,
          ASSESSMENT_ASSISTANCE_DATE: 1558066500000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71134211,
        },
      },
      {
        type: 'Feature',
        id: 71132376,
        geometry: {
          type: 'Point',
          coordinates: [-13263684.051530978, 8025893.2318480061],
        },
        properties: {
          LABEL: 'HWF-114-2019',
          LATITUDE: 58.277749999999997,
          LONGITUDE: -119.149683,
          FIRE_NUMBER: 'HWF114',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/17 14:29:44',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.42999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563309780000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132376,
        },
      },
      {
        type: 'Feature',
        id: 71133562,
        geometry: {
          type: 'Point',
          coordinates: [-12805403.613031941, 6864975.6055284692],
        },
        properties: {
          LABEL: 'RWF-018-2019',
          LATITUDE: 52.357216999999999,
          LONGITUDE: -115.032883,
          FIRE_NUMBER: 'RWF018',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 18:23:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.41999999999999998,
          ASSESSMENT_ASSISTANCE_DATE: 1558208040000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71133562,
        },
      },
      {
        type: 'Feature',
        id: 71134112,
        geometry: {
          type: 'Point',
          coordinates: [-12326288.33757969, 7543782.7636820702],
        },
        properties: {
          LABEL: 'LWF-065-2019',
          LATITUDE: 55.926417000000001,
          LONGITUDE: -110.728917,
          FIRE_NUMBER: 'LWF065',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/29 18:02:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1556476920000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71134112,
        },
      },
      {
        type: 'Feature',
        id: 71133037,
        geometry: {
          type: 'Point',
          coordinates: [-12858870.508859176, 7503276.3138274113],
        },
        properties: {
          LABEL: 'SWF-084-2019',
          LATITUDE: 55.722017000000001,
          LONGITUDE: -115.513183,
          FIRE_NUMBER: 'SWF084',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/02 13:50:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1559423760000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133037,
        },
      },
      {
        type: 'Feature',
        id: 71132519,
        geometry: {
          type: 'Point',
          coordinates: [-12691685.348145129, 7741111.5767500661],
        },
        properties: {
          LABEL: 'SWF-149-2019',
          LATITUDE: 56.906883000000001,
          LONGITUDE: -114.01133299999999,
          FIRE_NUMBER: 'SWF149',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 13:35:15',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563402480000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132519,
        },
      },
      {
        type: 'Feature',
        id: 71132236,
        geometry: {
          type: 'Point',
          coordinates: [-12324557.319781885, 7547101.6626488231],
        },
        properties: {
          LABEL: 'LWF-053-2019',
          LATITUDE: 55.943117000000001,
          LONGITUDE: -110.71336700000001,
          FIRE_NUMBER: 'LWF053',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/26 12:46:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1556240280000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132236,
        },
      },
      {
        type: 'Feature',
        id: 71132269,
        geometry: {
          type: 'Point',
          coordinates: [-12775431.097155299, 8054288.5652725827],
        },
        properties: {
          LABEL: 'HWF-025-2019',
          LATITUDE: 58.411617,
          LONGITUDE: -114.763633,
          FIRE_NUMBER: 'HWF025',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/02 13:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1556766600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132269,
        },
      },
      {
        type: 'Feature',
        id: 71132198,
        geometry: {
          type: 'Point',
          coordinates: [-13085073.601050835, 7260588.7248254493],
        },
        properties: {
          LABEL: 'WWF-016-2019',
          LATITUDE: 54.474758000000001,
          LONGITUDE: -117.54519999999999,
          FIRE_NUMBER: 'WWF016',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/12 16:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1557621900000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Whitecourt Forest Area',
          OBJECTID: 71132198,
        },
      },
      {
        type: 'Feature',
        id: 71132167,
        geometry: {
          type: 'Point',
          coordinates: [-12870104.220682986, 7048630.5643457333],
        },
        properties: {
          LABEL: 'EWF-036-2019',
          LATITUDE: 53.353360000000002,
          LONGITUDE: -115.614098,
          FIRE_NUMBER: 'EWF036',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/20 18:35:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1558395300000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71132167,
        },
      },
      {
        type: 'Feature',
        id: 71131360,
        geometry: {
          type: 'Point',
          coordinates: [-12973525.992450919, 7745353.4244155912],
        },
        properties: {
          LABEL: 'PWF-104-2019',
          LATITUDE: 56.927683000000002,
          LONGITUDE: -116.54315,
          FIRE_NUMBER: 'PWF104',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 14:38:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563397620000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71131360,
        },
      },
      {
        type: 'Feature',
        id: 71131535,
        geometry: {
          type: 'Point',
          coordinates: [-13273418.968977353, 8123595.1860615378],
        },
        properties: {
          LABEL: 'HWF-162-2019',
          LATITUDE: 58.736232999999999,
          LONGITUDE: -119.237133,
          FIRE_NUMBER: 'HWF162',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/01 19:58:56',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1564707900000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131535,
        },
      },
      {
        type: 'Feature',
        id: 71131401,
        geometry: {
          type: 'Point',
          coordinates: [-13020573.426378148, 8265677.861564477],
        },
        properties: {
          LABEL: 'HWF-156-2019',
          LATITUDE: 59.39235,
          LONGITUDE: -116.965783,
          FIRE_NUMBER: 'HWF156',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/25 11:44:42',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1564016940000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131401,
        },
      },
      {
        type: 'Feature',
        id: 71130290,
        geometry: {
          type: 'Point',
          coordinates: [-13214256.430578522, 8118566.9350559637],
        },
        properties: {
          LABEL: 'HWF-016-2019',
          LATITUDE: 58.712783000000002,
          LONGITUDE: -118.70566700000001,
          FIRE_NUMBER: 'HWF016',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/17 16:15:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1555531260000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130290,
        },
      },
      {
        type: 'Feature',
        id: 71130472,
        geometry: {
          type: 'Point',
          coordinates: [-13321145.281292614, 7536295.4292642353],
        },
        properties: {
          LABEL: 'GWF-013-2019',
          LATITUDE: 55.888717,
          LONGITUDE: -119.66586700000001,
          FIRE_NUMBER: 'GWF013',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/16 12:12:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1557625320000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Grande Prairie Forest Area',
          OBJECTID: 71130472,
        },
      },
      {
        type: 'Feature',
        id: 71130540,
        geometry: {
          type: 'Point',
          coordinates: [-12680816.784803251, 7267420.066684166],
        },
        properties: {
          LABEL: 'LWF-105-2019',
          LATITUDE: 54.510399999999997,
          LONGITUDE: -113.91370000000001,
          FIRE_NUMBER: 'LWF105',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/20 20:45:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1558387980000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71130540,
        },
      },
      {
        type: 'Feature',
        id: 71130543,
        geometry: {
          type: 'Point',
          coordinates: [-13137984.009983478, 8257625.4968571411],
        },
        properties: {
          LABEL: 'HWF-131-2019',
          LATITUDE: 59.355499999999999,
          LONGITUDE: -118.0205,
          FIRE_NUMBER: 'HWF131',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 18:41:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563562500000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130543,
        },
      },
      {
        type: 'Feature',
        id: 71130507,
        geometry: {
          type: 'Point',
          coordinates: [-12751044.679397428, 7655572.3161346661],
        },
        properties: {
          LABEL: 'SWF-041-2019',
          LATITUDE: 56.484966999999997,
          LONGITUDE: -114.544567,
          FIRE_NUMBER: 'SWF041',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/16 15:10:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.40000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1558025100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130507,
        },
      },
      {
        type: 'Feature',
        id: 71130426,
        geometry: {
          type: 'Point',
          coordinates: [-12968830.268209642, 8015054.4142049393],
        },
        properties: {
          LABEL: 'HWF-044-2019',
          LATITUDE: 58.226517000000001,
          LONGITUDE: -116.500967,
          FIRE_NUMBER: 'HWF044',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/16 16:51:19',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.38,
          ASSESSMENT_ASSISTANCE_DATE: 1557953280000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130426,
        },
      },
      {
        type: 'Feature',
        id: 71130285,
        geometry: {
          type: 'Point',
          coordinates: [-12775527.539047144, 7760017.9058105377],
        },
        properties: {
          LABEL: 'SWF-146-2019',
          LATITUDE: 56.999499999999998,
          LONGITUDE: -114.7645,
          FIRE_NUMBER: 'SWF146',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/17 18:29:01',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.38,
          ASSESSMENT_ASSISTANCE_DATE: 1563315420000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71130285,
        },
      },
      {
        type: 'Feature',
        id: 71131458,
        geometry: {
          type: 'Point',
          coordinates: [-12784117.527617695, 6795581.7674063863],
        },
        properties: {
          LABEL: 'RWF-020-2019',
          LATITUDE: 51.974857,
          LONGITUDE: -114.841667,
          FIRE_NUMBER: 'RWF020',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/19 20:14:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.35999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1558310640000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71131458,
        },
      },
      {
        type: 'Feature',
        id: 71130446,
        geometry: {
          type: 'Point',
          coordinates: [-13308939.277189964, 8242306.0438209521],
        },
        properties: {
          LABEL: 'HWF-120-2019',
          LATITUDE: 59.285283,
          LONGITUDE: -119.556217,
          FIRE_NUMBER: 'HWF120',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/17 15:47:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.34000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1563325920000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130446,
        },
      },
      {
        type: 'Feature',
        id: 71130341,
        geometry: {
          type: 'Point',
          coordinates: [-12964301.141465336, 7134937.5595079446],
        },
        properties: {
          LABEL: 'EWF-030-2019',
          LATITUDE: 53.813617000000001,
          LONGITUDE: -116.460283,
          FIRE_NUMBER: 'EWF030',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/18 19:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.33000000000000002,
          ASSESSMENT_ASSISTANCE_DATE: 1558218840000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71130341,
        },
      },
      {
        type: 'Feature',
        id: 71130332,
        geometry: {
          type: 'Point',
          coordinates: [-12791047.196234237, 6946191.1910487702],
        },
        properties: {
          LABEL: 'RWF-030-2019',
          LATITUDE: 52.800550000000001,
          LONGITUDE: -114.90391700000001,
          FIRE_NUMBER: 'RWF030',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/28 12:38:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1561397160000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71130332,
        },
      },
      {
        type: 'Feature',
        id: 71130289,
        geometry: {
          type: 'Point',
          coordinates: [-13048260.458485, 8176538.3983130045],
        },
        properties: {
          LABEL: 'HWF-015-2019',
          LATITUDE: 58.982182999999999,
          LONGITUDE: -117.2145,
          FIRE_NUMBER: 'HWF015',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/18 12:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1555529700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130289,
        },
      },
      {
        type: 'Feature',
        id: 71130467,
        geometry: {
          type: 'Point',
          coordinates: [-12783421.754091559, 6651386.9426022591],
        },
        properties: {
          LABEL: 'CWF-001-2019',
          LATITUDE: 51.169806999999999,
          LONGITUDE: -114.83541700000001,
          FIRE_NUMBER: 'CWF001',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/01/01 11:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1546329000000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Calgary Forest Area',
          OBJECTID: 71130467,
        },
      },
      {
        type: 'Feature',
        id: 71130521,
        geometry: {
          type: 'Point',
          coordinates: [-13214056.055221567, 8117702.5002969941],
        },
        properties: {
          LABEL: 'HWF-009-2019',
          LATITUDE: 58.708750000000002,
          LONGITUDE: -118.703867,
          FIRE_NUMBER: 'HWF009',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/10 15:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1554927600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71130521,
        },
      },
      {
        type: 'Feature',
        id: 71130527,
        geometry: {
          type: 'Point',
          coordinates: [-13025998.355017742, 7813908.1536738388],
        },
        properties: {
          LABEL: 'PWF-074-2019',
          LATITUDE: 57.262233000000002,
          LONGITUDE: -117.014517,
          FIRE_NUMBER: 'PWF074',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/09 16:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1560013500000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71130527,
        },
      },
      {
        type: 'Feature',
        id: 71131526,
        geometry: {
          type: 'Point',
          coordinates: [-12325804.097761832, 7544190.096474004],
        },
        properties: {
          LABEL: 'LWF-050-2019',
          LATITUDE: 55.928466999999998,
          LONGITUDE: -110.72456699999999,
          FIRE_NUMBER: 'LWF050',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/25 14:09:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1556154900000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71131526,
        },
      },
      {
        type: 'Feature',
        id: 71131532,
        geometry: {
          type: 'Point',
          coordinates: [-12805575.724378221, 6923934.3474728214],
        },
        properties: {
          LABEL: 'RWF-037-2019',
          LATITUDE: 52.679501999999999,
          LONGITUDE: -115.034429,
          FIRE_NUMBER: 'RWF037',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/09/17 10:41:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1568413200000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71131532,
        },
      },
      {
        type: 'Feature',
        id: 71131551,
        geometry: {
          type: 'Point',
          coordinates: [-12456201.853924569, 7380982.0049763247],
        },
        properties: {
          LABEL: 'LWF-139-2019',
          LATITUDE: 55.098367000000003,
          LONGITUDE: -111.89595,
          FIRE_NUMBER: 'LWF139',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/06 15:00:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1565030400000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71131551,
        },
      },
      {
        type: 'Feature',
        id: 71131555,
        geometry: {
          type: 'Point',
          coordinates: [-13027847.852420731, 7954152.2294468163],
        },
        properties: {
          LABEL: 'HWF-169-2019',
          LATITUDE: 57.937266999999999,
          LONGITUDE: -117.031131,
          FIRE_NUMBER: 'HWF169',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/08/29 11:47:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1566827700000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71131555,
        },
      },
      {
        type: 'Feature',
        id: 71131357,
        geometry: {
          type: 'Point',
          coordinates: [-12923437.634557413, 7107279.324349761],
        },
        properties: {
          LABEL: 'EWF-021-2019',
          LATITUDE: 53.666666999999997,
          LONGITUDE: -116.0932,
          FIRE_NUMBER: 'EWF021',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/12 17:25:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557697440000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Edson Forest Area',
          OBJECTID: 71131357,
        },
      },
      {
        type: 'Feature',
        id: 71131315,
        geometry: {
          type: 'Point',
          coordinates: [-12325484.944592929, 7543683.4176887348],
        },
        properties: {
          LABEL: 'LWF-047-2019',
          LATITUDE: 55.925916999999998,
          LONGITUDE: -110.7217,
          FIRE_NUMBER: 'LWF047',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/24 14:53:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1556138100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71131315,
        },
      },
      {
        type: 'Feature',
        id: 71132164,
        geometry: {
          type: 'Point',
          coordinates: [-12398054.086195463, 7295336.9591987124],
        },
        properties: {
          LABEL: 'LWF-146-2019',
          LATITUDE: 54.655732999999998,
          LONGITUDE: -111.3736,
          FIRE_NUMBER: 'LWF146',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/10/17 13:05:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1571250600000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132164,
        },
      },
      {
        type: 'Feature',
        id: 71132212,
        geometry: {
          type: 'Point',
          coordinates: [-12325774.375538204, 7544597.450716964],
        },
        properties: {
          LABEL: 'LWF-020-2019',
          LATITUDE: 55.930517000000002,
          LONGITUDE: -110.7243,
          FIRE_NUMBER: 'LWF020',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/17 16:20:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1555445460000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132212,
        },
      },
      {
        type: 'Feature',
        id: 71132215,
        geometry: {
          type: 'Point',
          coordinates: [-12757790.585839627, 7405007.9078746838],
        },
        properties: {
          LABEL: 'SWF-055-2019',
          LATITUDE: 55.221666999999997,
          LONGITUDE: -114.60516699999999,
          FIRE_NUMBER: 'SWF055',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/19 12:55:29',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1558289100000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132215,
        },
      },
      {
        type: 'Feature',
        id: 71132234,
        geometry: {
          type: 'Point',
          coordinates: [-12326607.378490811, 7539935.6254909737],
        },
        properties: {
          LABEL: 'LWF-051-2019',
          LATITUDE: 55.907049999999998,
          LONGITUDE: -110.73178299999999,
          FIRE_NUMBER: 'LWF051',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/04/25 11:27:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1556212860000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Lac La Biche Forest Area',
          OBJECTID: 71132234,
        },
      },
      {
        type: 'Feature',
        id: 71132365,
        geometry: {
          type: 'Point',
          coordinates: [-13049327.276986126, 8337191.6767892046],
        },
        properties: {
          LABEL: 'HWF-125-2019',
          LATITUDE: 59.717866999999998,
          LONGITUDE: -117.22408299999999,
          FIRE_NUMBER: 'HWF125',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/18 13:31:33',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563403200000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71132365,
        },
      },
      {
        type: 'Feature',
        id: 71132372,
        geometry: {
          type: 'Point',
          coordinates: [-12606967.519697033, 7731114.5169319725],
        },
        properties: {
          LABEL: 'SWF-153-2019',
          LATITUDE: 56.857816999999997,
          LONGITUDE: -113.2503,
          FIRE_NUMBER: 'SWF153',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/19 13:05:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1563490680000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132372,
        },
      },
      {
        type: 'Feature',
        id: 71132387,
        geometry: {
          type: 'Point',
          coordinates: [-12772123.044220297, 7717393.0812695911],
        },
        properties: {
          LABEL: 'SWF-132-2019',
          LATITUDE: 56.790367000000003,
          LONGITUDE: -114.73391700000001,
          FIRE_NUMBER: 'SWF132',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/07/12 17:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1562971200000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71132387,
        },
      },
      {
        type: 'Feature',
        id: 71133074,
        geometry: {
          type: 'Point',
          coordinates: [-13169263.776843365, 8202277.2643879764],
        },
        properties: {
          LABEL: 'HWF-077-2019',
          LATITUDE: 59.101123999999999,
          LONGITUDE: -118.301491,
          FIRE_NUMBER: 'HWF077',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/06/13 21:30:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1560461280000,
          GENERAL_CAUSE: 'Lightning',
          RESP_AREA: 'High Level Forest Area',
          OBJECTID: 71133074,
        },
      },
      {
        type: 'Feature',
        id: 71133170,
        geometry: {
          type: 'Point',
          coordinates: [-12900177.537670217, 7635049.0229997113],
        },
        properties: {
          LABEL: 'SWF-024-2019',
          LATITUDE: 56.383032999999998,
          LONGITUDE: -115.88424999999999,
          FIRE_NUMBER: 'SWF024',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/07 17:11:56',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557100380000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Slave Lake Forest Area',
          OBJECTID: 71133170,
        },
      },
      {
        type: 'Feature',
        id: 71133172,
        geometry: {
          type: 'Point',
          coordinates: [-12999511.614158504, 7633276.5798048759],
        },
        properties: {
          LABEL: 'PWF-057-2019',
          LATITUDE: 56.374217000000002,
          LONGITUDE: -116.776583,
          FIRE_NUMBER: 'PWF057',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/14 20:28:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1557883320000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Peace River Forest Area',
          OBJECTID: 71133172,
        },
      },
      {
        type: 'Feature',
        id: 71133197,
        geometry: {
          type: 'Point',
          coordinates: [-12897310.920518149, 6988396.0815458],
        },
        properties: {
          LABEL: 'RWF-024-2019',
          LATITUDE: 53.029167000000001,
          LONGITUDE: -115.85850000000001,
          FIRE_NUMBER: 'RWF024',
          FIRE_YEAR: 2019,
          FIRE_TYPE: 'Wildfire',
          FIRE_STATUS: 'EX',
          FIRE_STATUS_DATE: '2019/05/27 16:15:00',
          SIZE_CLASS: 'B',
          AREA_ESTIMATE: 0.29999999999999999,
          ASSESSMENT_ASSISTANCE_DATE: 1558912440000,
          GENERAL_CAUSE: 'Human',
          RESP_AREA: 'Rocky Mountain House Forest Area',
          OBJECTID: 71133197,
        },
      },
    ],
    exceededTransferLimit: true,
    properties: { exceededTransferLimit: true },
  },
};

<template>
  <div id="app-container">
    <v-app>
      <v-progress-circular
        id="loading-spinner"
        v-if="!dataReady"
        indeterminate
        color="blue"
      ></v-progress-circular>
      <map-component v-if="dataReady"/>
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MapComponent from './components/MapComponent.vue';

export default {
  name: 'App',
  components: { MapComponent },

  computed: {
    ...mapState([
      'dataReady',
    ]),
  },

  beforeCreate() {
    this.$store.commit('INITALIZE_STORE');
  },
};
</script>

<style>
@import 'https://js.arcgis.com/4.19/@arcgis/core/assets/esri/themes/light/main.css';
html,
body,
#app, #app-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

span.esri-layer-list__item-title {
    text-align: left;
}

.esri-widget__table.three-wide tr th {
   width: 33% !important;
}
.esri-widget__table.three-wide tr td {
   width: 33% !important;
}
/* .esri-popup__main-container.esri-widget {
    margin-top: auto;
    margin-bottom: auto;
} */
#loading-spinner {
  margin: auto auto auto auto;
}
</style>

/* eslint-disable no-unused-vars */
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import Graphic from '@arcgis/core/Graphic';
import { DateTime } from 'luxon';
import * as MeeusSunMoon from 'meeussunmoon';

export default {
  data() {
    // return {
    //   calculationMatrix: calculationMatrixJSON,
    // };
  },
  created() {
  },
  methods: {
    convertDispatchToGeoJson(json) {
      const geoJson = {
        type: 'FeatureCollection',
        // crs: { type: 'name', properties: { name: 'EPSG:3857' } },
        features: [],
      };
      json.forEach((element) => {
        const feature = {
          type: 'Feature',
          id: element.id,
          geometry: {
            type: 'Point',
            // coordinates: [-13055513.442549346, 8027971.9682196463],
            coordinates: [element.longitude, element.latitude,
              element.altitude ? element.altitude * 0.304 : null],
          },
          properties: element,
        };
        // itterate through the links and add the title property to the properties object
        // eslint-disable-next-line no-underscore-dangle
        Object.keys(element._links).forEach((key) => {
          // eslint-disable-next-line no-underscore-dangle
          feature.properties[key] = element._links[key].title;
        });
        geoJson.features.push(feature);
      });
      return geoJson;
    },
    convertDispatchToESRIJson(json) {
      return json;
    },
    // eslint-disable-next-line no-unused-vars
    geoJSONRenderer(field, index) {
      const renderer = {
        type: 'class-breaks', // autocasts as new ClassBreaksRenderer()
        legendOptions: {
          title: 'Fire Forecast Model',
        },
        defaultSymbol: {
          type: 'simple-fill', // autocasts as new SimpleFillSymbol()
          color: 'black',
          style: 'backward-diagonal',
          outline: {
            width: 0.5,
            color: [50, 50, 50, 0.6],
          },
        },
        defaultLabel: 'no data',
      };
      return renderer;
    },

    createBufferedGeometry(layer, geometry, symbol) {
      // create buffers
      const buffered = geometryEngine.buffer(
        geometry,
        this.bufferDistance,
        this.bufferUnit,
      );
      layer.add(
        new Graphic({
          geometry: buffered,
          symbol,
        }),
      );
      return buffered;
    },

    labelExpressionInfo(title, field, index) {
      // "Sorted By " + "${field}" + TextFormatting.NewLine +
      // "Cost: $" + $feature.COST_${index} +  TextFormatting.NewLine + "${title} - "
      return {
        expression: `
        return $feature.FIRE_NUMBER + TextFormatting.NewLine +
        "Priority: " + $feature.PRIORITY_${index} +  TextFormatting.NewLine;
        `,
      };
    },

    solarNoon(longitude) {
      const l = parseFloat(longitude);
      return MeeusSunMoon.solarNoon(
        DateTime.now(), l,
      );
    },

    firePopupTemplate() {
      const popupTemplate = {
        title: 'Fire: {LABEL}',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'FIRE_NUMBER',
                label: 'FIRE NUMBER',
                visible: true,
              },
              {
                fieldName: 'FIRE_STATUS',
                label: 'FIRE_STATUS',
                visible: true,
              },
              {
                fieldName: 'FIRE_STATUS_DATE',
                format: {
                  dateFormat: 'short-date-short-time',
                  digitSeparator: false,
                },
                label: 'FIRE STATUS DATE',
                visible: true,
              },
              {
                fieldName: 'FIRE_TYPE',
                label: 'FIRE TYPE',
                visible: true,
              },
              {
                fieldName: 'RESP_AREA',
                label: 'RESP_AREA',
                visible: true,
              },
              {
                fieldName: 'SIZE_CLASS',
                label: 'SIZE_CLASS',
                visible: true,
              },
              {
                fieldName: 'FIRE_YEAR',
                format: {
                  digitSeparator: false,
                  places: 0,
                },
                label: 'FIRE YEAR',
                visible: true,
              },
              {
                fieldName: 'AREA_ESTIMATE',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'AREA ESTIMATE',
                visible: true,
              },
              {
                fieldName: 'ASSESSMENT_ASSISTANCE_DATE',
                format: {
                  dateFormat: 'short-date-short-time',
                  digitSeparator: false,
                },
                label: 'ASSESSMENT_ASSISTANCE_DATE',
                visible: true,
              },
              {
                fieldName: 'COST_0',
                format: {
                  digitSeparator: true,
                  places: 2,
                },
                label: 'COST 4HR Model',
                visible: true,
              },
              {
                fieldName: 'COST_1',
                format: {
                  digitSeparator: true,
                  places: 2,
                },
                label: 'COST 8HR Model',
                visible: true,
              },
              {
                fieldName: 'COST_2',
                format: {
                  digitSeparator: true,
                  places: 2,
                },
                label: 'COST 12HR Model',
                visible: true,
              },
              {
                fieldName: 'BACK_0',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'BACK_0',
                visible: true,
              },
              {
                fieldName: 'BACK_1',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'BACK_1',
                visible: true,
              },
              {
                fieldName: 'BACK_2',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'BACK_2',
                visible: true,
              },
              {
                fieldName: 'FLANK_0',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'FLANK_0',
                visible: true,
              },
              {
                fieldName: 'FLANK_1',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'FLANK_1',
                visible: true,
              },
              {
                fieldName: 'FLANK_2',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'FLANK_2',
                visible: true,
              },
              {
                fieldName: 'GENERAL_CAUSE',
                label: 'GENERAL_CAUSE',
                visible: true,
              },
              {
                fieldName: 'HEAD_0',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'HEAD_0',
                visible: true,
              },
              {
                fieldName: 'HEAD_1',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'HEAD_1',
                visible: true,
              },
              {
                fieldName: 'HEAD_2',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'HEAD_2',
                visible: true,
              },
              {
                fieldName: 'PRIORITY_0',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'PRIORITY_0',
                visible: true,
              },
              {
                fieldName: 'PRIORITY_1',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'PRIORITY_1',
                visible: true,
              },
              {
                fieldName: 'PRIORITY_2',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'PRIORITY_2',
                visible: true,
              },
              {
                fieldName: 'RESOURCES_0',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'RESOURCES_0',
                visible: true,
              },
              {
                fieldName: 'RESOURCES_1',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'RESOURCES_1',
                visible: true,
              },
              {
                fieldName: 'RESOURCES_2',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'RESOURCES_2',
                visible: true,
              },
              {
                fieldName: 'WINDDIRECTION_0',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'WINDDIRECTION_0',
                visible: true,
              },
              {
                fieldName: 'WINDDIRECTION_1',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'WINDDIRECTION_1',
                visible: true,
              },
              {
                fieldName: 'WINDDIRECTION_2',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'WINDDIRECTION_2',
                visible: true,
              },
              {
                fieldName: 'WINDSPEED_0',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'WINDSPEED_0',
                visible: true,
              },
              {
                fieldName: 'WINDSPEED_1',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'WINDSPEED_1',
                visible: true,
              },
              {
                fieldName: 'WINDSPEED_2',
                format: {
                  digitSeparator: true,
                  places: 0,
                },
                label: 'WINDSPEED_2',
                visible: true,
              },
            ],
          },
          this.resourceTableWidget,
        ],
      };
      return popupTemplate;
    },

    resourcePopupTemplate() {
      const popupTemplate = {
        title: '{displayLabel}',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'creationTimestamp',
                label: 'Created: ',
                format: {
                  dateFormat: 'short-date-short-time',
                },
              },
              {
                fieldName: 'positionTimestamp',
                label: 'Position Time: ',
                format: {
                  dateFormat: 'short-date-short-time',
                },
              },
              {
                fieldName: 'callsign',
                label: 'Call Sign: ',
              },
              {
                fieldName: 'frequency',
                label: 'Frequency: ',
              },
            ],
          },
          this.fireTableWidget,
        ],
      };
      return popupTemplate;
    },

    modelPopupTemplate(index, config) {
      const popupTemplate = {
        title: `Model PM Actual ${config.modelTimeframes[index].title} - {FIRE_NUMBER}`,
        actions: [
          {
            // This text is displayed as a tooltip
            title: 'Filter',
            // The ID by which to reference the action in the event handler
            id: 'filter-fires',
            // Sets the icon font used to style the action button
            className: 'esri-icon-filter',
          },
          {
            // This text is displayed as a tooltip
            title: 'Historic Perimiters',
            // The ID by which to reference the action in the event handler
            id: 'historic-fires',
            // Sets the icon font used to style the action button
            className: 'esri-icon-lasso',
          },
        ],
        expressionInfos: [
          {
            name: 'model-inputs',
            title: '<b>Model Inputs: </b>',
            expression: '',
          },
          {
            name: 'model-outputs',
            title: '<b>Model Outputs: </b>',
            expression: '',
          },
          {
            name: 'values-at-risk',
            title: 'Values at Risk',
            expression: `
            var values = Intersects(
              $feature,
              FeatureSetByName($map, "Human Life Factor", ["desc_"])
            );
            return Count(values);
            `,
          },
        ],
        content: [
          this.contentWidget,
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'FIRE_STATUS_DATE',
                label: 'FIRE STATUS DATE ',
              },
              {
                fieldName: 'RESP_AREA',
                label: 'Responsible Area ',
              },
              {
                fieldName: `PRIORITY_${index}`,
                label: 'PRIORITY',
              },
              {
                fieldName: `COST_${index}`,
                label: 'COST',
              },
              {
                fieldName: `RESOURCES_${index}`,
                label: 'RESOURCES',
              },
              {
                fieldName: 'AREA_ESTIMATE',
                label: 'Area ',
              },
              {
                fieldName: 'expression/values-at-risk',
                label: 'Values at Risk',
              },
              {
                fieldName: 'expression/model-inputs',
                label: '<b>Model Inputs: </b>',
              },
              {
                fieldName: `HEAD_${index}`,
                label: 'Head (km)',
              },
              {
                fieldName: `FLANK_${index}`,
                label: 'Flank (km)',
              },
              {
                fieldName: `BACK_${index}`,
                label: 'Back (km)',
              },
              {
                fieldName: `WINDDIRECTION_${index}`,
                label: 'Wind Direction',
              },
              {
                fieldName: `WINDSPEED_${index}`,
                label: 'Wind Speed (km/h)',
              },
            ],
          },
        ],
      };

      // add solar noon to popup
      Object.keys(config.fbpCalculationDefaults).forEach((key) => {
        console.log('fbpCalculationDefaults', key, config.fbpCalculationDefaults[key]);
        // eslint-disable-next-line no-unused-vars
        const expressionInfo = {
          name: key,
          title: key,
          expression: `Concatenate("${config.fbpCalculationDefaults[key]}", "")`,
        };
        popupTemplate.expressionInfos.push(expressionInfo);
        popupTemplate.content[1].fieldInfos.push({
          label: `expression/${key}`,
          fieldName: `expression/${key}`,
        });
      });
      popupTemplate.content[1].fieldInfos.push(
        {
          fieldName: 'expression/model-outputs',
          label: '<b>Model Outputs: </b>',
        },
      );
      return popupTemplate;
    },

  },
};

<template>
  <v-card class="column">
    <v-card-title>
      <v-row cols="12" ma-4>
      <v-text-field
        dense
        v-model="search"
        append-icon="mdi-magnify"
        label="Search Recommend Fires"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer/>
        <v-dialog
            v-model="dialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="assignDisabled"
                color="primary"
                v-bind="attrs"
              v-on="on"
              >
                Assign
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </template>

            <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Assign to Dispatch for {{ selectedFeatureId }}
        </v-card-title>

        <v-card-text>
          <v-alert
            text
            type="success"
          >
          <ul>
            <li v-for="resource in selected" :key="resource.id">
              {{ resource.displayLabel }}
            </li>
          </ul>
        </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Send to Dispatch
          </v-btn>
        </v-card-actions>
      </v-card>

      </v-dialog>
    </v-row>
    </v-card-title>
      <v-data-table
        v-model="selected"
        item-key="id"
        show-select
        dense
        hide-default-footer
        disable-pagination
        multi-sort
        :headers="headers"
        :items="resources"
        class="elevation-1"
        :search="search"
        @click:row="clickHandler"
        >
      </v-data-table>
  </v-card>
</template>

<script>
import Point from '@arcgis/core/geometry/Point';
import { mapActions } from 'vuex';
import config from './config';
// eslint-disable-next-line no-unused-vars, import/no-unresolved, import/extensions
// import vuetify from '@/plugins/vuetify';

export default {
  name: 'FiressTableComponent',
  // eslint-disable-next-line vue/no-unused-components
  components: { },
  props: {
    features: [],
    selectedFeatureId: String,
    featureDisplayLabel: String,
  },

  data() {
    return {
      dialog: false,
      selected: [],
      search: '',
      headers: [
        {
          text: 'Display Label',
          value: 'displayLabel',
          align: 'left',
          sortable: false,
        },
        {
          text: `Distance (${config.units})`,
          value: 'range',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Cause',
          value: 'cause',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Class',
          value: 'class',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Area',
          value: 'respArea',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Priority',
          value: 'priority',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Cost',
          value: 'cost',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Resources',
          value: 'resources',
          align: 'left',
          sortable: true,
        },
      ],
    };
  },

  async mounted() {
    // this.chartCanvas = this.$refs.valuesChart;
  },

  computed: {
    resources() {
      return this.features.map((feature) => {
        const { attributes } = feature;
        return {
          id: attributes.id,
          latitude: attributes.latitude,
          longitude: attributes.longitude,
          displayLabel: attributes.LABEL,
          range: attributes.range,
          cause: attributes.GENERAL_CAUSE,
          class: attributes.SIZE_CLASS,
          respArea: attributes.RESP_AREA,
          priority: attributes.PRIORITY_0,
          cost: attributes.COST_0,
          resources: attributes.RESOURCES_0,
        };
      });
    },
    assignDisabled() {
      return this.selected.length === 0;
    },
    logSelected() {
      console.log('selected:', this.selected);
      return this.selected;
    },
    logFeatures() {
      console.log('Features:', this.features);
      return this.features;
    },
  },

  watch: {
    features: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        console.log('Features:', newValue, oldValue);
      },
      deep: true,
    },
    selected(newValue, oldValue) {
      console.log('Selected:', newValue, oldValue);
    },
  },

  methods: {
    ...mapActions([
      'setSelectedResource',
    ]),
    clickHandler(data, item, e) {
      const newCenter = new Point({
        longitude: data.longitude,
        latitude: data.latitude,
      });
      this.setSelectedResource(newCenter);
      // this.selected = [data];
      console.log('Click Row:', newCenter, data, item, e);
    },
  },
};
</script>

<style lang="less">
.resourceTableWidget {
  height: 100%;

  .v-data-table__wrapper tbody {
    cursor: pointer;
  }

  .column {
    height: 100%;
  }

  .v-data-table {
    height: calc(100% - 45px);
    overflow: auto;
  }

  .v-data-table__wrapper {
    tr:nth-child(even) {
      background-color: rgba(76,76,76,.02);
    }
    tr:nth-child(odd) {
      background-color: rgba(76,76,76,.1);
    }
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    font-size: 12px;
  }

  .theme--light.v-data-table > .v-data-table__wrapper > table >tbody >
    tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: #cbdeef;
    }

  .theme--light.v-data-table tbody tr.v-data-table__selected {
      background: #cbdeef;
  }
}
</style>

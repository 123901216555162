<template>
  <div>
    <canvas class="material-chart" ref="valuesChart" width="400" height="250" />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js';
// eslint-disable-next-line no-unused-vars
import config from './config';

export default {
  name: 'ValuesChartComponent',
  // eslint-disable-next-line vue/no-unused-components
  components: { },
  props: {
    allStats: Object,
  },

  data() {
    return {
      chartCanvas: null,
      valuesChart: null,
    };
  },

  async mounted() {
    this.chartCanvas = this.$refs.valuesChart;
    this.valuesChart = new Chart(this.chartCanvas.getContext('2d'), this.chartData());
  },

  computed: {},

  watch: {
    allStats: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.

        this.valuesChart.data.datasets[0] = this.chartData();
        this.valuesChart.update();
      },
      deep: true,
    },
  },

  methods: {
    chartData() {
      return {
        type: 'pie',
        data: {
          labels: [
            `${this.allStats.value_residential_site || 0} Residential Site`,
            `${this.allStats.value_commercial_site || 0} Commercial Site`,
            `${this.allStats.value_community_site || 0} Community Site`,
            `${this.allStats.value_industrial_site || 0} Industrial Site`,
            `${this.allStats.value_government_site || 0} Government Site`,
            `${this.allStats.value_population_cluster || 0} Population Cluster`,
            `${this.allStats.value_cabin || 0} Cabin`,
            `${this.allStats.value_recreation_site || 0} Recreation Site`,
            `${this.allStats.value_other || 0} Other`],
          datasets: [
            {
              backgroundColor: [
                '#d73027',
                '#f46d43',
                '#fdae61',
                '#fee090',
                '#ffffbf',
                '#e0f3f8',
                '#abd9e9',
                '#74add1',
                '#4575b4'],
              borderColor: [
                '#CCCCCC',
                '#CCCCCC',
                '#CCCCCC',
                '#CCCCCC',
                '#CCCCCC',
                '#CCCCCC',
                '#CCCCCC',
                '#CCCCCC',
                '#CCCCCC',
              ],
              borderWidth: 1,
              data: [
                this.allStats.value_residential_site,
                this.allStats.value_commercial_site,
                this.allStats.value_community_site,
                this.allStats.value_industrial_site,
                this.allStats.value_government_site,
                this.allStats.value_population_cluster,
                this.allStats.value_cabin,
                this.allStats.value_recreation_site,
                this.allStats.value_other,
              ],
            },
          ],
        },
        options: {
          responsive: false,
          cutoutPercentage: 35,
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: 'Values at Risk',
          },
        },
      };
    },
  },
};
</script>

<style>
</style>

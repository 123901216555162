<template>
  <div class="feature-table-container flex-column" v-show="getIsTableVisible">
    <div @click="closeTable()"
      class="esri-widget--button esri-widget esri-interactive end"
      role="button" tabindex="0" title="Close">
      <span aria-hidden="true" role="presentation" class="esri-icon esri-icon-close"></span>
      <span class="esri-icon-font-fallback-text">Close</span>
    </div>
    <div
      id="featureTableContainer"
      ref="featureTableContainer"
      class="feature-table-container"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// eslint-disable-next-line no-unused-vars
import FeatureTable from '@arcgis/core/widgets/FeatureTable';

export default {
  name: 'FeatureTableComponent',
  props: {
    featureTable: {
      type: [Object],
      default: null,
    },
    tableDivNode: {
      type: [Object, Element],
      default: null,
    },
  },

  watch: {
    tableDivNode: {
      handler() {
        this.$refs.featureTableContainer.appendChild(this.tableDivNode);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['getIsTableVisible', 'getIsDataReady']),
  },

  methods: {
    ...mapActions(['setIsTableVisible']),

    closeTable() {
      this.setIsTableVisible(false);
    },
  },
};
</script>

<style>
.flex-column {
  display: flex;
  flex-direction: column;
}

.end {
  align-self: flex-end;
}

.feature-table-container {
  display: flex;
  flex: 1 1 50%;
  width: 100%;
  padding: 0 8px;
}
</style>

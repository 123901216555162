<template>
  <div class="esri-layer-list esri-widget esri-widget--panel">
    <h3 class="esri-widget__heading" role="heading" aria-level="3">Filters</h3>
    <ul
      aria-label="Layer List"
      class="esri-layer-list__list esri-layer-list__list--root esri-layer-list__list--independent"
    >
      <li
        class="esri-layer-list__item"
        aria-labelledby="187d8ff957d-widget-15_187d8ff973f-object-42__title"
      >
        <div class="esri-layer-list__item-container">
          <div
            class="esri-layer-list__item-label"
            tabindex="0"
            aria-checked="true"
            title="Hide layer"
            role="switch"
            aria-labelledby="187d8ff957d-widget-15_187d8ff973f-object-42__title"
          >
            <span
              id="187d8ff957d-widget-15_187d8ff973f-object-42__title"
              title="Mock New Start Points v0_1"
              aria-label="Mock New Start Points v0_1"
              class="esri-layer-list__item-title"
              ><b>Fires:</b>Cause</span
            >
            <span><span class="esri-icon-radio-unchecked" aria-hidden="true"></span>
             Lightning</span>
            <span><span class="esri-icon-radio-unchecked" aria-hidden="true"></span>
             Human</span>
            <span><span class="esri-icon-radio-unchecked" aria-hidden="true"></span>
             Unknown</span>
             <span><span class="esri-icon-radio-checked" aria-hidden="true"></span>
             All</span>
          </div>
        </div>
      </li>
      <li
        class="esri-layer-list__item"
        aria-labelledby="187d8ff957d-widget-15_187d8ff973f-object-42__title"
      >
        <div class="esri-layer-list__item-container">
          <div
            class="esri-layer-list__item-label"
            tabindex="0"
            aria-checked="true"
            title="Hide layer"
            role="switch"
            aria-labelledby="187d8ff957d-widget-15_187d8ff973f-object-42__title"
          >
            <span
              class="esri-layer-list__item-title"
              >Resources</span
            >
            <span><span class="esri-icon-radio-unchecked" aria-hidden="true"></span>
             Assigned</span>
            <span><span class="esri-icon-radio-unchecked" aria-hidden="true"></span>
             None Assigned</span>
            <span><span class="esri-icon-radio-checked" aria-hidden="true"></span>
             All</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FilterComponent',
  props: {},

  watch: {},

  computed: {
    ...mapGetters(['getIsDataReady']),
  },

  methods: {
    ...mapActions(['setIsTableVisible']),
  },
};
</script>

<style lang="less" scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}
.esri-ui-corner .esri-expand .esri-widget--panel {
  width: 450px;
}
span span {
  margin-left: 10px;
}
</style>

<!-- eslint-disable no-unused-vars -->
<template>
  <div id="appContainer">
    <div id="viewContainer">
      <div id="viewDiv" class="map-div flex-view" ref="mapView"></div>
      <div id="viewToggle">
        <input
          class="esri-component esri-widget--button esri-widget esri-interactive"
          type="button"
          id="switch-btn"
          :value="is3D ? '3D' : '2D'"
          @click="toggle2D3D()"
        />
      </div>
    </div>
    <div id="forecastControls" class="esri-widget">
      <div>
        <div><b>Solar Noon:</b> {{ currentSolarNoon }}</div>
        <v-select
          class="forecastControl"
          v-model="selectedForecast"
          :items="forecasts"
          label="Forecast"
          dense
        ></v-select>
      </div>
        <v-btn-toggle
          dense
          v-model="selectedTimeframe"
          color="primary"
          >
          <v-btn
            v-for="(modelTimeframe) in modelTimeframes" :key="modelTimeframe.id"
            @click="clearPlayer"
            :value="modelTimeframe.id"
            >
            {{ modelTimeframe.title }}
          </v-btn>
          <v-btn
            value="all">All</v-btn>
          <v-btn aria-label="Play"
            v-bind:class="{ active: isPlaying }"
            value="play">
            <v-icon>{{isPlaying ? 'mdi-stop' : 'mdi-play'}}</v-icon>
          </v-btn>
        </v-btn-toggle>
    </div>
    <div id="settingsControl" ref="settingsControl" class="esri-widget">
      <div class="esri-layer-list esri-widget esri-widget--panel">

              <v-card elevation="1" class="pa-3 ma-1">
              <v-select
                  :items="mapSelectStyles"
                  v-model="selectedMapStyle"
                  label="Map Style"
                  dense
                ></v-select>
              </v-card>

              <v-card elevation="1" class="pa-3 ma-1">
                <v-slider
                  v-model="queryRange"
                  label="Range"
                  step="50"
                  thumb-label
                  :hint="rangeHint"
                  max="2000"
                  min="50"
                  persistent-hint
                ></v-slider>
              </v-card>

      </div>
    </div>
    <div id="sortControl" class="esri-widget">
      <v-select
          :items="selectedSortOptions"
          v-model="selectedSort"
          label="Sort by:"
          dense
        ></v-select>
    </div>
    <div id="filterControl" ref="filterControl" class="esri-widget"><filter-component/></div>
    <feature-table-component
      :tableDivNode="tableDivNode"
      :featureTable="featureTable"
    ></feature-table-component>
    <Teleport :to="teleportResourceId" v-if="isResourceTableVisible">
      <resources-table-component
        :features="filteredFeatures"
        :selectedFeatureId="selectedFeatureId"/>
    </Teleport>
    <!-- <Teleport :to="teleportFireId" v-if="isFireTableVisible">
      <fires-table-component
        :features="filteredFeatures"
        :selectedFeatureId="selectedFeatureId"/>
    </Teleport> -->
  </div>
</template>

<script lang="js">
import { mapState, mapGetters, mapActions } from 'vuex';
import Vue from 'vue';

import WebMap from '@arcgis/core/WebMap';
import WebScene from '@arcgis/core/WebScene';
import Graphic from '@arcgis/core/Graphic';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import GraphicLayer from '@arcgis/core/layers/GraphicsLayer';
import Handles from '@arcgis/core/core/Handles';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import MapView from '@arcgis/core/views/MapView';
// eslint-disable-next-line no-unused-vars
import SceneView from '@arcgis/core/views/SceneView';
import esriConfig from '@arcgis/core/config';
import LayerList from '@arcgis/core/widgets/LayerList';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import Bookmarks from '@arcgis/core/widgets/Bookmarks';
import Legend from '@arcgis/core/widgets/Legend';
import Search from '@arcgis/core/widgets/Search';
// eslint-disable-next-line no-unused-vars
import Query from '@arcgis/core/rest/support/Query';
// eslint-disable-next-line no-unused-vars
import Feature from '@arcgis/core/widgets/Feature';
import FeatureTable from '@arcgis/core/widgets/FeatureTable';
import Point from '@arcgis/core/geometry/Point';
import TableList from '@arcgis/core/widgets/TableList';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
// eslint-disable-next-line no-unused-vars
import { identify } from '@arcgis/core/rest/identify';
// eslint-disable-next-line no-unused-vars
import IdentifyParameters from '@arcgis/core/rest/support/IdentifyParameters';
import CustomContent from '@arcgis/core/popup/content/CustomContent';
import ellipse from '@turf/ellipse';
import destination from '@turf/destination';
// eslint-disable-next-line no-unused-vars
import cloneDeep from 'lodash.clonedeep';
import { DateTime } from 'luxon';
// eslint-disable-next-line no-unused-vars
import Teleport from 'vue2-teleport';
import * as geodesicUtils from '@arcgis/core/geometry/support/geodesicUtils';
import config from './config';
import mapSymbols from './mapSymbols';
// eslint-disable-next-line no-unused-vars
import newStartPoints from '../assets/newStartPoints';
// eslint-disable-next-line no-unused-vars
import newStartPerimeters from '../assets/newStartPerimeters';

import FeatureTableComponent from './FeatureTableComponent.vue';
import ValuesChartComponent from './ValuesChartComponent.vue';
import ResourcesTableComponent from './ResourcesTableComponent.vue';
import FiresTableComponent from './FiresTableComponent.vue';
import FilterComponent from './FilterComponent.vue';
import geoUtils from '../mixins/geoUtils';

let webmap;
// eslint-disable-next-line no-unused-vars
let webscene;
let params;
let legend = null;
// eslint-disable-next-line no-unused-vars
let candidate;
let tableList;
// let highlights;
const bufferLayer = new GraphicLayer();
bufferLayer.alias = 'Buffer Layer';
bufferLayer.title = 'Buffer Layer';
bufferLayer.listMode = 'hide';
let valueLayer;
const timeframeLayersArray = [];
const handles = new Handles();

// eslint-disable-next-line no-unused-vars
const bufferSymbol = {
  type: 'simple-fill', // autocasts as new SimpleFillSymbol()
  color: [215, 48, 39, 0.25],
  outline: {
    color: [255, 255, 255, 0.5],
    width: 2,
  },
};

// eslint-disable-next-line no-unused-vars
const modelSymbol = {
  type: 'simple-fill', // autocasts as new SimpleFillSymbol()
  color: [255, 0, 0, 0.25],
  outline: {
    color: [255, 255, 255, 0.5],
    width: 2,
  },
};

// eslint-disable-next-line no-unused-vars
const pointGraphic = new Graphic({
  symbol: {
    type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
    color: [0, 121, 193],
    outline: {
      color: [255, 255, 255],
      width: 0.5,
    },
  },
});

let popupFeaturesContainer;
// eslint-disable-next-line no-unused-vars
let ValuesChartComponentClass;

export default {
  name: 'MapComponent',

  mixins: [geoUtils],

  components: {
    FeatureTableComponent,
    // eslint-disable-next-line vue/no-unused-components
    ValuesChartComponent,
    FilterComponent,
    ResourcesTableComponent,
    // eslint-disable-next-line vue/no-unused-components
    FiresTableComponent,
    // eslint-disable-next-line vue/no-unused-components
    Teleport,
  },
  props: {
    msg: String,
  },

  data() {
    return {
      selectedFilterPoint: null,
      filteredFeatures: [],
      popupResourceTableNodes: [],
      selectedFeatureId: '',
      selectedFeatureSet: [],
      featureLayers: [],
      featureTable: null,
      isTableVisible: false,
      tableDivNode: null,
      bufferDistance: 1000,
      bufferUnit: 'meters',
      forecasts: config.forecasts,
      modelTimeframes: config.modelTimeframes,
      selectedTimeframe: '4hr',
      selectedForecast: 'pmactual',
      selectedSort: 'PRIORITY',
      selectedMapStyle: 'traditional',
      mapSelectStyles: config.mapSelectStyles,
      selectedSortOptions: config.selectedSortOptions,
      mapStyles: config.mapStyle,
      isPlaying: false,
      playerCounter: 0,
      is3D: false,
      timer: null,
      isResourceTableVisible: false,
      isFireTableVisible: false,
    };
  },

  created() {
    this.contentWidget = new CustomContent({
      outFields: ['*'],
      creator: (event) => {
        const chartDiv = document.createElement('div');
        chartDiv.setAttribute('id', `chart_${event.graphic.attributes.FIRE_NUMBER}`);
        const buffer = this.createBufferedGeometry(
          bufferLayer, event.graphic.geometry, bufferSymbol,
        );
        // let returnedFeatures = null;
        const query = valueLayer.createQuery();
        query.geometry = buffer;
        query.outStatistics = config.valuesStatsDefinitions;

        valueLayer.queryFeatures(query)
          .then((result) => {
            // create chart
            console.log('result.features.length', result.features.length);
            if (result.features.length) {
              const chartInstance = new ValuesChartComponentClass({
                propsData: {
                  allStats: result.features[0].attributes,
                },
              });
              chartInstance.$mount();
              chartDiv.append(chartInstance.$el);
            }
          }).catch((error) => {
            console.log(error);
            return null;
          }).finally({
          // show chart
          });
        const solarNoon = this.solarNoon(event.graphic.geometry.centroid.longitude)
          .toLocaleString(DateTime.DATETIME_SHORT);
        const solarDiv = document.createElement('div');
        solarDiv.setAttribute('class', 'solarNoonWidget');
        solarDiv.innerHTML = `<b>Solar Noon: </b>${solarNoon}`;
        chartDiv.append(solarDiv);
        return chartDiv;
      },
    });

    this.resourceTableWidget = new CustomContent({
      outFields: ['*'],
      creator: (event) => {
        const div = document.createElement('div');
        console.log('resourceTableWidget: ', event.graphic.attributes);
        const id = `resourceTable_${event.graphic.attributes.FIRE_NUMBER}`;
        div.setAttribute('id', id);
        div.setAttribute('class', 'resourceTableWidget');
        // Return the table container that is added to the popup content
        return div;
      },
    });

    this.fireTableWidget = new CustomContent({
      outFields: ['*'],
      // eslint-disable-next-line no-unused-vars
      creator: (event) => {
        const div = document.createElement('div');
        console.log('fireTableWidget: ', event.graphic.attributes);
        div.setAttribute('id', `fireTable_${event.graphic.attributes.id}`);
        div.setAttribute('class', 'fireTableWidget');
        div.innerHTML = `
          <p>
            <h1>Fire Table: fireTable_${event.graphic.attributes.id}</h1>
            <ul>
              <li>Table of Fires in Range</li>
            </ul>
          </p>`;
        // Return the table container that is added to the popup content
        return div;
      },
    });

    // create reference variables within Vue context
    this.appConfig = {
      mapView: null,
      sceneView: null,
      activeView: null,
      container: this.$refs.mapView,
    };
    this.fireLayer = null;
    this.resourcesLayer = null;
  },

  mounted() {
    esriConfig.apiKey = config.apiKeyESRI;
    popupFeaturesContainer = this.$refs.featuesContainer;

    ValuesChartComponentClass = Vue.extend(ValuesChartComponent);

    webmap = new WebMap({
      portalItem: {
        id: config.webMapPortalID,
      },
    });

    webscene = new WebScene({
      portalItem: {
        id: config.webScenePortalID,
      },
    });

    newStartPoints.points.features.forEach((feature) => {
      config.modelProperties.forEach((modelProperty) => {
        const { name } = modelProperty;
        const { seedRange } = modelProperty;
        const { seedGrowthRange } = modelProperty;

        let seed = this.randomInt(seedRange[0], seedRange[1]);

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < 3; index++) {
          const seedGrowth = this.randomInt(seedGrowthRange[0], seedGrowthRange[1]);
          // eslint-disable-next-line no-param-reassign
          feature.properties[`${name}_${index}`] = seed + seedGrowth;
          seed += seedGrowth;
        }
      });
    });

    config.modelTimeframes.forEach((timeframe, index) => {
      // create a geoJSON model for each timeframe
      const modelGeoJSON = cloneDeep(newStartPoints);

      modelGeoJSON.points.features.forEach((feature) => {
        // eslint-disable-next-line no-param-reassign
        feature.geometry = this.getModelGeometry(feature, index);
      });

      // create a new blob from geojson featurecollection
      const blob = new Blob([JSON.stringify(modelGeoJSON.points)], {
        type: 'application/json',
      });

      // URL reference to the blob
      const url = URL.createObjectURL(blob);
      const modelLabelInfo = cloneDeep(config.modelLabelInfo);
      modelLabelInfo.labelExpressionInfo = this.labelExpressionInfo(timeframe.title, 'PRIORITY', index);
      // create a layer for each timeframe
      const timeFrameLayer = new GeoJSONLayer({
        url,
        copyright: 'Selkirk Systems',
        labelingInfo: [modelLabelInfo],
        alias: timeframe.id,
        title: timeframe.title,
        renderer: this.geoJSONRenderer('PRIORITY', index),
        orderBy: {
          field: `PRIORITY_${index}`,
          order: 'descending',
        },
        popupTemplate: this.modelPopupTemplate(index, config),
        popupEnabled: true,
      });
      timeFrameLayer.renderer.classBreakInfos = this.classBreakInfos();
      timeFrameLayer.renderer.valueExpression = `
        var x = 0;
        array = [$feature.${this.selectedSort}_0/1, $feature.${this.selectedSort}_1/1, $feature.${this.selectedSort}_2/1];
        // return Round( ( $feature.${this.selectedSort}_${index} / 100), 1);
        return Round( ( Max(array) / 100), 1);
        `;
      timeframeLayersArray.push(timeFrameLayer);
      timeFrameLayer.visible = this.selectedTimeframe === timeframe.id;
    });

    const forecastGroupLayer = new GroupLayer({
      title: 'PM Actual',
      visible: true,
      layers: timeframeLayersArray,
      opacity: 1,
      listMode: 'hide',
    });

    this.appConfig.mapView = new MapView({
      map: webmap,
      container: this.$refs.mapView,
      popup: {
        defaultPopupTemplateEnabled: false,
        dockEnabled: true,
        dockOptions: {
          position: 'bottom-left',
          breakpoint: false,
          buttonEnabled: false,
        },
        breakpoint: false,
        autoOpenEnabled: true,
      },
    });

    webmap.add(bufferLayer);
    webmap.add(forecastGroupLayer);

    const dispatchGeoJson = this.convertDispatchToGeoJson(this.getResources);
    // create a new blob from geojson featurecollection
    this.getResources.forEach((feature) => {
      // eslint-disable-next-line no-param-reassign
      console.log('feature.imageURL :: ', feature.type, feature.imageURL, feature.makeModel);
    });
    const resourcesBlob = new Blob([JSON.stringify(dispatchGeoJson)], {
      type: 'application/json',
    });
    // URL reference to the blob
    const reourcesURL = URL.createObjectURL(resourcesBlob);
    this.resourcesLayer = new GeoJSONLayer({
      id: 'resourcesLayer',
      url: reourcesURL,
      copyright: 'Selkirk Systems',
      alias: 'Resources',
      title: 'Resources Layer',
      elevationInfo: {
        mode: 'relative-to-ground',
        offset: 0,
      },
      renderer: {
        type: 'unique-value', // autocasts as new UniqueValueRenderer()
        field: 'type',
        defaultSymbol: {
          type: 'simple-marker',
          color: '#fbc800',
          size: '20px',
          outline: {
            color: '#5b5958',
            width: 1,
          },
        },
        visualVariables: [
          {
            type: 'rotation',
            field: 'heading',
            rotationType: 'geographic',
          },
        ],
        uniqueValueInfos: [
          {
            value: 'Fixed wing transport',
            label: 'Fixed Wing Transport',
            symbol: {
              type: 'cim',
              data: mapSymbols.resourceSymbols.Aircraft,
            },
          },
          {
            value: 'Personnel',
            label: 'Personnel',
            symbol: {
              type: 'cim',
              data: mapSymbols.resourceSymbols.Crew,
            },
          },
          {
            value: 'UAV',
            label: 'UAV',
            symbol: {
              type: 'cim',
              data: mapSymbols.resourceSymbols.UAV,
            },
          },
          {
            value: 'Rotary',
            label: 'Rotary',
            symbol: {
              type: 'cim',
              data: mapSymbols.resourceSymbols.Rotary,
            },
          },
        ],
      },
      popupTemplate: this.resourcePopupTemplate(),
      popupEnabled: true,
      labelingInfo: {
        symbol: config.modelLabelInfo.symbol,
        labelExpressionInfo: {
          expression: `
          var l = $feature.displayLabel  + TextFormatting.NewLine;
          if (!IsEmpty($feature.type)) {
            l = l + 'Type: ' + $feature.type + TextFormatting.NewLine;
          };
          if (!IsEmpty($feature.heading)) {
            l = l + 'Heading: ' + $feature.heading + TextFormatting.NewLine;
          };
          if (!IsEmpty($feature.altitude)) {
            l = l + 'Altitude: ' + Round($feature.altitude, 1) + TextFormatting.NewLine;
          };
          // if (!IsEmpty($feature.assignedActivity)) {
          //   l = l + 'Activity: ' + $feature.assignedActivity + TextFormatting.NewLine;
          // };
          return l;`,
        },
      },
    });
    webmap.add(this.resourcesLayer);

    this.appConfig.sceneView = new SceneView({
      // container: this.$refs.mapView,
      container: null,
      map: this.appConfig.mapView.map,
      // map: webscene,
      popup: {
        defaultPopupTemplateEnabled: false,
        dockEnabled: true,
        dockOptions: {
          position: 'bottom-right',
          breakpoint: false,
          buttonEnabled: false,
        },
        autoOpenEnabled: true,
        breakpoint: false,
      },
    });
    this.appConfig.sceneView.map.ground = 'world-elevation';

    const layerList = new LayerList({
      view: this.appConfig.mapView,
    });

    const layerListExpand = new Expand({
      view: this.appConfig.mapView,
      content: layerList,
      expanded: false,
      group: 'top-left',
    });

    const basemapGallery = new BasemapGallery({
      view: this.appConfig.mapView,
    });

    const basemapGalleryExpand = new Expand({
      view: this.appConfig.mapView,
      content: basemapGallery,
      expanded: false,
      group: 'top-left',
    });

    const bookmarks = new Bookmarks({
      view: this.appConfig.mapView,
      editingEnabled: true,
    });

    const search = new Search({
      view: this.appConfig.mapView,
      popupEnabled: false,
    });

    const bookmarksExpand = new Expand({
      view: this.appConfig.mapView,
      content: bookmarks,
      expanded: false,
      group: 'top-left',
    });

    const filterExpand = new Expand({
      view: this.appConfig.mapView,
      content: this.$refs.filterControl,
      expandIconClass: 'esri-icon-filter',
      group: 'top-left',
    });

    legend = new Legend({
      view: this.appConfig.mapView,
    });

    const legendExpand = new Expand({
      view: this.appConfig.mapView,
      content: legend,
      expanded: false,
      group: 'top-left',
    });

    const settingExpand = new Expand({
      view: this.appConfig.mapView,
      content: this.$refs.settingsControl,
      expandIconClass: 'esri-icon-settings',
      group: 'top-left',
    });

    // eslint-disable-next-line no-undef
    this.appConfig.mapView.ui.add([forecastControls, search, sortControl], 'top-right');
    this.appConfig.mapView.ui.add([layerListExpand, legendExpand, filterExpand, bookmarksExpand, settingExpand], 'top-left');
    this.appConfig.mapView.ui.add(basemapGalleryExpand, 'bottom-left');

    tableList = new TableList({
      map: webmap,
      selectionEnabled: true,
      listItemCreatedFunction: this.createActions,
    });

    const tableExpand = new Expand({
      view: this.appConfig.mapView,
      content: tableList,
      expanded: false,
      group: 'bottom-right',
    });

    this.appConfig.mapView.ui.add(tableExpand, 'bottom-right');

    webmap.when(() => {
      // Find feature layers for tables
      webmap.allLayers.items.forEach((layer) => {
        if (config.tableLayers.includes(layer.title)) {
          layer.load().then(() => {
            layer.set('outFields', ['*']);
            // this.featureLayers.push(layer);
            webmap.tables.add(layer);
          });
        }
      });

      valueLayer = webmap.findLayerById(config.valuesLayers[0]);
      this.fireLayer = webmap.findLayerById(config.fireLayers[0]);
      this.fireLayer.popupTemplate = this.firePopupTemplate();
      // console.log('this.fireLayer.defaultPopupTemplate', this.fireLayer.defaultPopupTemplate);

      tableList.on('trigger-action', this.handleTableListTriggerAction);
    });

    this.appConfig.mapView.when(() => {
      webmap.allLayers.items.forEach((layer) => {
        console.log('layer: ', layer.title, ', ', layer.id, ', ', layer.type);
      });

      // this.appConfig.mapView.on('click', this.handleClick);

      // update webmap zoom and center from mapViewState  if it exists
      if (this.mapViewState.zoom) {
        this.appConfig.mapView.zoom = this.mapViewState.zoom;
      }
      if (this.mapViewState.center) {
        this.appConfig.mapView.center = this.mapViewState.center;
      }
      reactiveUtils.when(
        () => this.appConfig.mapView.stationary === true,
        () => {
          // Get the new center of the view only when view is stationary.
          if (this.appConfig.mapView.center
            && this.appConfig.mapView.center.x !== this.mapViewState.center.x) {
            this.setMapViewStateCenter(this.appConfig.mapView.center);
          }
          if (this.appConfig.mapView.center
            && this.appConfig.mapView.center.y !== this.mapViewState.center.y) {
            this.setMapViewStateCenter(this.appConfig.mapView.center);
          }
          if (this.appConfig.mapView.zoom
            && this.appConfig.mapView.zoom !== this.mapViewState.zoom) {
            this.setMapViewStateZoom(this.appConfig.mapView.zoom);
          }
        },
      );

      this.appConfig.mapView.popup.watch('visible', (visible) => {
        console.log('view popup visible ', visible);
        if (!visible) {
          // clear resource Layer exluded effect
          this.resourcesLayer.featureEffect = {
            filter: null,
            excludedEffect: null,
            includedEffect: null,
          };
          this.selectedFilterPoint = null;
          this.setFilteredFeatures([]);
          this.filteredFeatures = [];
          this.selectedFeatureId = '';
          this.isResourceTableVisible = false;
          this.isFireTableVisible = false;
          this.popupResourceTableNodes = [];
        }
      });

      this.appConfig.mapView.popup.watch('selectedFeature', async (graphic) => {
        if (graphic) {
          this.setSelectedFeature(graphic);
          this.selectedFeature = graphic;

          // create filter geometry for resources
          const firePoint = new Point({
            longitude: graphic.geometry.longitude,
            latitude: graphic.geometry.latitude,
          });

          this.selectedFilterPoint = firePoint;
          this.selectedFeatureId = graphic.attributes.FIRE_NUMBER;

          const filterBufferGeometry = geometryEngine.buffer(
            graphic.geometry,
            this.getQueryRange,
            config.units,
          );

          const featureFilter = {
            geometry: graphic.geometry,
            spatialRelationship: 'intersects',
            distance: this.getQueryRange,
            units: config.units,
          };

          this.resourcesLayer.featureEffect = {
            filter: featureFilter,
            excludedEffect: 'grayscale(100%) opacity(30%)',
            includedEffect: 'drop-shadow(3px, 3px, 4px) brightness(150%)',
          };

          if (graphic.layer.id === config.fireLayers[0]) {
            console.log('>>>>>> Fire Layer');
            this.filteredFeatures = await this.queryWithinRange(
              this.resourcesLayer,
              graphic.geometry,
              this.getQueryRange,
              config.units,
            );
          }
          if (graphic.layer.id === 'resourcesLayer') {
            this.selectedFeatureId = graphic.attributes.id;
            this.filteredFeatures = await this.queryWithinRange(
              this.fireLayer,
              graphic.geometry,
              this.getQueryRange,
              config.units,
            );
          }

          this.setFilteredFeatures(this.filteredFeatures);

          this.selectedFeatureId = graphic.attributes.FIRE_NUMBER;

          // Hackity, hack, hack
          setTimeout(() => {
            this.isResourceTableVisible = true;
          }, 200);
          // Move Map to the extend of buffered features
          this.appConfig.mapView.extent = filterBufferGeometry.extent;
        }
      });

      const { popup } = this.appConfig.mapView;
      popup.viewModel.on('trigger-action', (event) => {
        const { attributes } = popup.viewModel.selectedFeature;
        if (event.action.id === 'historic-fires') {
          console.log('Popup historic-fires clicked', attributes);
        }
        if (event.action.id === 'filter-fires') {
          console.log('Popup filter-fires clicked', attributes);
        }
      });

      // Set the parameters for the identify
      params = new IdentifyParameters();
      params.tolerance = 3;
      params.layerOption = 'all'; // Identify from all layers in the service
      params.width = this.appConfig.mapView.width;
      params.height = this.appConfig.mapView.height;
    });
  },

  watch: {
    getSelectedResource: {
      deep: true,
      handler(newVal, oldVal) {
        console.log('getSelectedResource: ', newVal, oldVal);
        if (newVal) {
          this.appConfig.mapView.goTo(this.offsetForPopup(newVal));
        }
      },
    },
    searchRange(newVal, oldVal) {
      console.log('searchRange: ', newVal, oldVal);
      this.setQueryRange(newVal);
    },
    selectedMapStyle(newVal, oldVal) {
      console.log('selectedMapStyle', newVal, oldVal);
      timeframeLayersArray.forEach((timeFrameLayer) => {
        // eslint-disable-next-line no-param-reassign
        timeFrameLayer.renderer.classBreakInfos = this.classBreakInfos();
      });
      legend = new Legend({
        view: this.appConfig.mapView,
      });
    },

    selectedTimeframe(newVal, oldVal) {
      console.log('selectedTimeframe', this.selectedTimeframe, newVal, oldVal);
      if (this.selectedTimeframe === 'all') {
        clearInterval(this.timer);
        this.isPlaying = false;
        timeframeLayersArray.forEach((timeFrameLayer) => {
          // eslint-disable-next-line no-param-reassign
          timeFrameLayer.visible = true;
        });
      } else if (this.selectedTimeframe === 'play') {
        this.isPlaying = !this.isPlaying;
        if (this.isPlaying) {
          this.timer = setInterval(() => {
            if (this.playerCounter >= config.modelTimeframes.length) this.playerCounter = 0;
            this.selectedTimeframe = config.modelTimeframes[this.playerCounter].id;
            this.playerCounter += 1;
          }, 1 * 1000);
        } else {
          this.isPlaying = false;
          clearInterval(this.timer);
          this.playerCounter = 0;
          this.selectedTimeframe = config.modelTimeframes[this.playerCounter].id;
        }
      } else {
        timeframeLayersArray.forEach((timeFrameLayer) => {
          console.log('timeFrameLayer', timeFrameLayer);
          // eslint-disable-next-line no-param-reassign
          timeFrameLayer.visible = timeFrameLayer.alias === this.selectedTimeframe;
        });
      }
    },
  },

  computed: {
    ...mapState([
      'dataReady', 'mapViewState', 'queryRange',
    ]),
    ...mapGetters([
      'getIsTableVisible',
      'getIsDataReady',
      'getQueryRange',
      'getResources',
      'getTypes',
      'getFilteredFeatures',
      'getSelectedFeature',
      'getSelectedResource',
    ]),
    currentSolarNoon() {
      const p = new Point(this.mapViewState.center);
      const sN = this.solarNoon(p.longitude); // found in geoUtils
      return sN.toLocaleString(DateTime.DATETIME_SHORT);
    },
    teleportResourceId() {
      return `#resourceTable_${this.selectedFeatureId}`;
    },
    teleportFireId() {
      return `#fireTable_${this.selectedFeatureId}`;
    },
    rangeHint() {
      return `Resource & Fire search radius: ${this.getQueryRange} km`;
    },
    queryRange: {
      get() {
        return this.getQueryRange;
      },
      set(value) {
        this.setQueryRange(value);
      },
    },
  },

  methods: {
    ...mapActions([
      'setIsTableVisible',
      'setMapViewStateCenter',
      'setMapViewStateZoom',
      'setFilteredFeatures',
      'setSelectedFeature',
      'setQueryRange',
    ]),

    offsetForPopup(center) {
      // this.appConfig.mapView.center = center;
      const { y } = this.appConfig.mapView.toScreen(center);
      const { x } = this.appConfig.mapView.toScreen(center);
      // now calculate offset if the pop-up is open
      // const xO = this.appConfig.mapView.width * 0.50;
      const yO = this.appConfig.mapView.height * 0.25;
      return this.appConfig.mapView.toMap({ x, y: (y + yO) });
    },

    toggle2D3D() {
      this.is3D = !this.is3D;
      if (this.is3D) {
        const activeViewpoint = this.appConfig.mapView.viewpoint.clone();
        console.log('activeViewpoint: ', activeViewpoint);
        this.appConfig.sceneView.viewpoint = activeViewpoint;
        this.appConfig.mapView.container = null;
        this.appConfig.sceneView.container = this.$refs.mapView;
        this.appConfig.activeView = this.appConfig.sceneView;
      } else {
        const activeViewpoint = this.appConfig.sceneView.viewpoint.clone();
        console.log('activeViewpoint: ', activeViewpoint);
        this.appConfig.mapView.viewpoint = activeViewpoint;
        this.appConfig.sceneView.container = null;
        this.appConfig.mapView.container = this.$refs.mapView;
        this.appConfig.activeView = this.appConfig.mapView;
      }
    },

    clearPlayer() {
      this.isPlaying = false;
      clearInterval(this.timer);
      this.playerCounter = 0;
    },

    async queryWithinRange(layer, geometry, distance, units) {
      try {
        const query = layer.createQuery();
        query.geometry = geometry;
        query.distance = distance;
        query.units = units;

        const firePoint = new Point({
          longitude: geometry.longitude,
          latitude: geometry.latitude,
        });
        const result = await layer.queryFeatures(query);
        result.features.forEach((feature) => {
          const resourcePoint = new Point({
            longitude: feature.geometry.longitude,
            latitude: feature.geometry.latitude,
          });
          const range = geodesicUtils.geodesicDistance(firePoint, resourcePoint, config.units);
          // give range two decimal places
          // eslint-disable-next-line no-param-reassign
          feature.attributes.range = range.distance.toFixed(2);
        });
        result.features.sort((a, b) => a.attributes.range - b.attributes.range);
        return result.features;
      } catch (error) {
        console.log(error);
        return null;
      }
    },

    getModelGeometry(feature, index) {
      const center = [feature.properties.LONGITUDE, feature.properties.LATITUDE];
      const pivot = destination(center, feature.properties[`BACK_${index}`], -90, { units: 'kilometers' });
      // HEAD plus BACK = the full length
      const xSemiAxis = feature.properties[`HEAD_${index}`] + feature.properties[`BACK_${index}`];
      const ySemiAxis = feature.properties[`FLANK_${index}`]; // FLACK on both sides
      const ellipseFeature = ellipse(pivot, xSemiAxis, ySemiAxis, {
        steps: 32,
        pivot: center, // hack the pivot point to the fire location
        angle: feature.properties[`WINDDIRECTION_${index}`],
        units: config.units,
      });
      // eslint-disable-next-line no-param-reassign
      return ellipseFeature.geometry;
    },

    classBreakInfos() {
      return [
        {
          minValue: 0,
          maxValue: 0.3499,
          symbol: config.mapStyle[this.selectedMapStyle].low,
          label: '< 35% - Low',
        },
        {
          minValue: 0.35,
          maxValue: 0.4999,
          symbol: config.mapStyle[this.selectedMapStyle].medium,
          label: '35 - 50% - Medium',
        },
        {
          minValue: 0.5,
          maxValue: 0.7499,
          symbol: config.mapStyle[this.selectedMapStyle].high,
          label: '50 - 75% - High',
        },
        {
          minValue: 0.75,
          maxValue: 1.0,
          symbol: config.mapStyle[this.selectedMapStyle].extreme,
          label: '> 75% - Extreme',
        },
      ];
    },

    createActions(event) {
      const { item } = event;
      // item.actionsOpen = true;
      item.actionsSections = [
        [
          {
            // This allows the user to toggle the table on/off within the FeatureTable widget
            title: 'Show table',
            className: 'esri-icon-table',
            id: 'table',
          },
        ],
      ];
    },

    handleTableListTriggerAction(event) {
      const { item } = event;
      const { id } = event.action;
      if (id === 'table') {
        // Toggle the feature table widget based on the selected table,
        // If the toggled on
        // if (event.action.value) {
        // Check if the Feature Table is already created if so, don't recreate
        if (this.featureTable) {
          // If the table is already created, make sure that the featuretable
          // toggle reflects the correct layer.
          // If toggling on a different layer, create the featuretable for that
          // layer and toggle on
          if (item.layer.title !== this.featureTable.layer.title) {
            // destroy the featuretable and recreate with new tablediv
            this.featureTable.destroy();

            // Load the FeatureTable based on whatever is clicked
            this.createTable(item.layer).then(() => {
              this.isTableVisible = true;
              this.setIsTableVisible(true);
            });
          } else {
            // if the table is the same one stored in featureTable,
            // i.e. toggling on/of the same table, no need to recreate.
            this.isTableVisible = true;
            this.setIsTableVisible(true);
          }

          // If the featuretable is not already created, create a new one and toggle it on
        } else {
          // Create the table if not already created
          this.createTable(item.layer).then(() => {
            this.isTableVisible = true;
            this.setIsTableVisible(true);
          });
        }
      }
    },

    async createTable(layer) {
      const tableDiv = document.createElement('div');
      this.featureTable = new FeatureTable({
        view: this.appConfig.mapView,
        layer,
        multiSortEnabled: true,
        hiddenFields: ['OBJECTID', 'LATITUDE', 'LONGITUDE'],
        visibleElements: {
          selectionColumn: false,
        },
        container: tableDiv,
        autoRefreshEnabled: true,
      });
      this.tableDivNode = tableDiv; // this updates in FeatureTableComponent
    },

    handleClick(event) {
      // Clean up the display and data from previous click
      handles.removeAll(); // Remove existing highlighted features from the map
      this.selectedFeatureSet = [];
      popupFeaturesContainer.innerHTML = ''; // Clear HTML from the Pop-up Display
      bufferLayer.removeAll();
      this.appConfig.mapView.graphics.remove(pointGraphic);

      // Call fetchFeatures and pass in the click event location
      this.appConfig.mapView.popup.fetchFeatures(event).then((response) => {
        response.promisesPerLayerView.forEach((fetchResult) => {
          // eslint-disable-next-line no-unused-vars
          const { layerView } = fetchResult;

          // Iterate through the promise results to access its graphics
          fetchResult.promise.then((graphics) => {
            // There is no associated popupTemplate with the basemap layer. If
            // the returned graphics.length is more than 0, create divs from
            // the returned graphics.
            if (graphics.length > 0) {
              const groupDiv = document.createElement('div');
              groupDiv.className = 'popup-container';
              popupFeaturesContainer.appendChild(groupDiv);

              // Iterate through all of the returned graphics from the resulting
              // layer views, create a new feature, and append a new div for it.
              graphics.forEach(async (graphic) => {
                // Only highlight features from feature or geojson layer
                // if (
                //   (
                //     layerView.layer.type === 'feature'
                //     || layerView.layer.type === 'geojson'
                //   )
                //   && typeof layerView.highlight === 'function'
                // ) {
                //   handles.add(layerView.highlight(graphic));
                // }
                if (graphic.layer.id === config.fireLayers[0]) {
                  // Highlight the recommended resources
                  // and grayscale excluded resources
                  // const firePoint = new Point({
                  //   longitude: graphic.geometry.longitude,
                  //   latitude: graphic.geometry.latitude,
                  // });

                  // const featureFilter = {
                  //   geometry: firePoint,
                  //   spatialRelationship: 'intersects',
                  //   distance: config.queryRange,
                  //   units: config.units,
                  // };

                  // const query = new Query(featureFilter);
                  // this.resourcesLayer.queryFeatures(query)
                  //   .then((result) => {
                  //     this.setFilteredFeatures(result.features);
                  //   });
                }

                const fcContainer = document.createElement('div');

                const featureChild = new Feature({
                  container: fcContainer,
                  graphic,
                  map: this.appConfig.mapView.map,
                  spatialReference: this.appConfig.mapView.spatialReference,
                });
                groupDiv.appendChild(fcContainer);

                const buffer = this.createBufferedGeometry(
                  bufferLayer, graphic.geometry, bufferSymbol,
                );

                const query = valueLayer.createQuery();
                query.geometry = buffer;
                query.outStatistics = config.valuesStatsDefinitions;

                valueLayer.queryFeatures(query)
                // eslint-disable-next-line consistent-return
                  .then((result) => {
                    if (result.features.length) {
                      // eslint-disable-next-line no-param-reassign
                      graphic.attributes = {
                        ...graphic.attributes,
                        ...result.features[0].attributes,
                      };
                      const chartInstance = new ValuesChartComponentClass({
                        propsData: {
                          allStats: result.features[0].attributes,
                        },
                      });
                      chartInstance.$mount();
                      fcContainer.prepend(chartInstance.$el);
                    }
                  }).catch((error) => {
                    console.log(error);
                  }).finally({
                    // show chart
                  });

                this.selectedFeatureSet.push(featureChild);
              });
            }
          });
        });
      });
    },

    randomInt(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
};

</script>

<style lang="less">
@sidebar-width: 278px;

h3 {
  margin: 40px 0 0;
}

ul {
  // list-style-type: none;
  padding: 0;
}

li {
  text-align: left;
}

a {
  color: #42b983;
}

#appContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#viewContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex: 1 1 50%;
  width: 100%;
}

#viewDiv {
  flex: 1;
  width: 100%;
}

.values-chart-container {
  min-width: 250px;
  font-size: 14px;
  padding: 10px;
  // display: none;
  overflow-y: auto;
  overflow-x: hidden;
}

#sidePanelContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: @sidebar-width;
  height: 100%;
  overflow-y: auto;
  background: #fff;
}

.side-panel {
  flex: 1;
  padding: 1em;
  overflow: auto;
}

#sortControl,  #styleControl {
  padding: 16px 8px 0px 8px;
  width: @sidebar-width;
}

.esri-component.esri-search.esri-widget {
  width: @sidebar-width;
}

#forecastControls{
  width: @sidebar-width;
  padding: 12px;

  .forecastControl {
    margin-top: 16px;
  }
}

#playButton {
  border: 1px solid #ccc;
  &.active {
    border-color: #0091ff;
  }
}

#viewToggle {
  position: absolute;
  top: 15px;
  left: 60px;
  input {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
  }
}

.solarNoonWidget {
  margin: 10px;
}

.esri-popup--is-docked-top-left .esri-popup__main-container,
.esri-popup--is-docked-top-right .esri-popup__main-container,
.esri-popup--is-docked-bottom-left .esri-popup__main-container,
.esri-popup--is-docked-bottom-right .esri-popup__main-container {
    max-height: 50%;
}

.esri-popup__main-container {
    width: 100% !important;
    max-height: 40% !important;

    &.esri-widget {
      height: 100%;
    }

    .esri-feature-fields {
        height: 100%;
        overflow: auto;
    }

    .esri-feature.esri-widget {
        height: 100%;
    }
    article.esri-popup__content {
      height: 100%;
      overflow: hidden;

      > div {
        height: 100%;
      }
    }

    .esri-feature__size-container {
      height: 100%;

        .esri-feature__main-container {
          height: 100%;
        }

        .esri-feature__main-container > div {
          height: 100%;
          display: flex;
          flex-direction: row;

          .esri-feature-content > div {
              height: 100%;
          }

          .esri-feature__content-element {
              flex: 0 0 50%;

              .esri-feature-content {
                  height: 100%;

                  > div {
                      height: 100%;
                  }
              }
          }
        }
    }
}
</style>

export default {
  apiKeyESRI: 'AAPK55d915b4501a4f2f95a26121d8593f14NLjZAECeGoLCnSobUIO8rcRgKOzxQxr5O-WANLBGsh1IMbv8EW47nMyM2wpddtXG',
  webMapPortalID: '458b5fff6474475aac433ed26abd65e5',
  webScenePortalID: 'e49d78ae78b74a5ea7a7a60032759867',
  units: 'kilometers',
  fireLayers: ['186043f0f83-layer-8'],
  tableLayers: ['Resources Layer', 'Human Life Factor', 'Mock New Start Points v0_1', 'Facilities - Industrial Wildfire Control Plan'],
  // Filter metadata stub
  filters: [
    {
      layerName: 'Mock New Start Points v0_1',
      layerId: '186043f0f83-layer-8',
      filterName: 'Fire Cause',
      filterField: 'General_Cause',
    },
  ],
  mapSelectStyles: [
    { text: 'Linear', value: 'linear' },
    { text: 'Traditional', value: 'traditional' },
    { text: 'Diverging', value: 'diverging' },
    { text: 'Custom', value: 'custom' },
  ],
  selectedSortOptions: [
    { value: 'PRIORITY', text: 'Priority' },
    { value: 'COST', text: 'Cost' },
    { value: 'RESOURCES', text: 'Resources' },
  ],
  mapStyle: {
    linear: {
      low: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [214, 181, 216, 0.25],
        style: 'solid',
        outline: {
          width: 0.5,
          color: [214, 181, 216, 0.25],
        },
      },
      medium: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [223, 101, 176, 0.25],
        style: 'solid',
        outline: {
          width: 1,
          color: [223, 101, 176, 0.50],
        },
      },
      high: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [221, 29, 119, 0.25],
        style: 'solid',
        outline: {
          width: 2,
          color: [221, 29, 119, 0.75],
        },
      },
      extreme: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        style: 'solid',
        color: [152, 12, 67, 0.25],
        outline: {
          width: 4,
          color: [152, 12, 67, 1],
        },
      },
    },
    traditional: {
      low: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [254, 204, 92, 0.5],
        style: 'solid',
        outline: {
          width: 0.5,
          color: [254, 204, 92, 0.75],
        },
      },
      medium: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [253, 141, 60, 0.5],
        style: 'solid',
        outline: {
          width: 1,
          color: [253, 141, 60, 0.75],
        },
      },
      high: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [240, 59, 32, 0.5],
        style: 'solid',
        outline: {
          width: 2,
          color: [240, 59, 32, 0.75],
        },
      },
      extreme: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        style: 'solid',
        color: [189, 0, 38, 0.5],
        outline: {
          width: 4,
          color: [189, 0, 255, 1],
        },
      },
    },
    diverging: {
      low: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [146, 197, 222, 0.25],
        style: 'solid',
        outline: {
          width: 0.5,
          color: [146, 197, 222, 0.25],
        },
      },
      medium: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [5, 113, 176, 0.25],
        style: 'solid',
        outline: {
          width: 1,
          color: [5, 113, 176, 0.50],
        },
      },
      high: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [241, 182, 218, 0.50],
        style: 'solid',
        outline: {
          width: 2,
          color: [241, 182, 218, 0.75],
        },
      },
      extreme: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        style: 'solid',
        color: [208, 28, 139, 0.75],
        outline: {
          width: 4,
          color: [208, 28, 139, 1],
        },
      },
    },
    custom: {
      low: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [44, 123, 182, 0.25],
        style: 'solid',
        outline: {
          width: 0.5,
          color: [224, 243, 248, 0.50],
        },
      },
      medium: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [255, 255, 191, 0.50],
        style: 'solid',
        outline: {
          width: 1,
          color: [255, 255, 191, 0.50],
        },
      },
      high: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        color: [253, 174, 97, 0.50],
        style: 'solid',
        outline: {
          width: 2,
          color: [253, 174, 97, 0.75],
        },
      },
      extreme: {
        type: 'simple-fill', // autocasts as new SimpleFillSymbol()
        style: 'solid',
        color: [215, 25, 28, 0.50],
        outline: {
          width: 4,
          color: [215, 25, 28, 0.99],
        },
      },
    },
  },
  modelLabelInfo: {
    symbol: {
      type: 'text', // autocasts as new TextSymbol()
      font: {
        size: 9,
        family: 'Noto Sans',
      },
      color: 'black',
      haloSize: 1,
      haloColor: 'white',
    },
    maxScale: 0,
    minScale: 320000,
  },
  modelProperties: [
    {
      name: 'WINDSPEED',
      seedRange: [5, 50],
      seedGrowthRange: [0, 40],
    },
    {
      name: 'WINDDIRECTION',
      seedRange: [0, 45],
      seedGrowthRange: [0, 45],
    },
    {
      name: 'HEAD',
      seedRange: [0, 5],
      seedGrowthRange: [1, 3],
    }, {
      name: 'FLANK',
      seedRange: [0, 4],
      seedGrowthRange: [1, 3],
    }, {
      name: 'BACK',
      seedRange: [0, 4],
      seedGrowthRange: [1, 3],
    },
    {
      name: 'PRIORITY',
      seedRange: [0, 40],
      seedGrowthRange: [0, 20],
    },
    {
      name: 'RESOURCES',
      seedRange: [0, 15],
      seedGrowthRange: [0, 15],
    },
    {
      name: 'COST',
      seedRange: [0, 100000],
      seedGrowthRange: [0, 25000],
    },
  ],
  forecasts: [
    {
      text: 'PM Actual',
      value: 'pmactual',
      index: 0,
    },
    {
      text: 'PM Forecast',
      value: 'pmforecast',
      index: 1,
    },
    {
      text: 'AM Revised',
      value: 'amrevised',
      index: 2,
    },
  ],
  modelTimeframes: [
    {
      id: '4hr',
      title: '4hr',
    },
    {
      id: '8hr',
      title: '8hr',
    },
    {
      id: '12hr',
      title: '12hr',
    },
  ],
  fbpCalculationDefaults: {
    fuelType: 'C-1',
    elevation: 500,
    useSlope: false,
    slopeValue: 0,
    aspect: 0,
    useLine: false,
    startTime: '2019-01-01T12:00:00',
    elapsedTime: 60,
    ffmc: 85,
    bui: 40,
    useBui: true,
    // windSpeed: 10,
    // windDirection: 0,
    // latitude: 62.454,
    // longitude: -114.3718,
  },
  valuesLayers: ['dispatch_point_5449'],
  valuesStatsDefinitions: [
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Cabin' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_cabin',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Commercial Site' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_commercial_site',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Community Site' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_community_site',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Government Site' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_government_site',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Industrial Site' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_industrial_site',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Population Cluster' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_population_cluster',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Recreation Site' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_recreation_site',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY = 'Residential Site' THEN 1 ELSE 0 END",
      outStatisticFieldName: 'value_residential_site',
      statisticType: 'sum',
    },
    {
      onStatisticField:
      "CASE WHEN CATEGORY IN ('Residential Site', 'Recreation Site', 'Population Cluster', 'Industrial Site', 'Government Site', 'Community Site', 'Commercial Site', 'Cabin') THEN 0 ELSE 1 END",
      outStatisticFieldName: 'value_other',
      statisticType: 'sum',
    },
  ],
};

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

// eslint-disable-next-line no-unused-vars
const vuexLocalStorage = new VuexPersist({
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    mapViewState: state.mapViewState,
    queryRange: state.queryRange,
  }),
  // Function that passes a mutation and lets you decide if it should
  // update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  state: {
    dataReady: false,
    queryRange: 600, // km default
    isTableVisible: false,
    selectedFeature: null,
    selectedResource: null,
    filteredFeatures: [],
    auth: null,
    resources: null,
    types: null,
    mapViewState: {
      center: {
        spatialReference: {
          latestWkid: 3857,
          wkid: 102100,
        },
        x: -12796115.608548934,
        y: 6637230.788292748,
      },
      zoom: parseInt(process.env.MAP_ZOOM, 10) || 10,
    },
    filterLayer: null,
    filter: null,
  },

  plugins: [vuexLocalStorage.plugin],

  getters: {
    getDataReady: (state) => state.dataReady,
    getQueryRange: (state) => state.queryRange,
    getAuth: (state) => state.auth,
    getResources: (state) => state.resources,
    getTypes: (state) => state.types,
    getIsTableVisible: (state) => state.isTableVisible,
    getFilteredFeatures: (state) => state.filteredFeatures,
    getSelectedFeature: (state) => state.selectedFeature,
    getSelectedResource: (state) => state.selectedResource,
    getMapViewState: (state) => state.mapViewState,
    getMapViewStateCenter: (state) => state.mapViewState.center,
  },

  mutations: {
    INITALIZE_STORE(state) {
      state.dataReady = false;

      fetch('/api/getAuth')
        .then((response) => response.json())
        .then((data) => {
          // data!
          state.auth = data;

          Promise.all([
            `/api/getDispatchJSON?token=${state.auth.access_token}&url=https://api-dev.ab.selkirksystems.com/resource-api/v1/resources`,
            `/api/getDispatchJSON?token=${state.auth.access_token}&url=https://api-dev.ab.selkirksystems.com/resource-api/v1/types`,
          ].map(
            (uri) => fetch(uri).then((resp) => resp.json()),
          )).then((dataArray) => {
            // final array of promised data
            console.log('dataArray', dataArray);
            dataArray.forEach((element) => {
              // eslint-disable-next-line no-underscore-dangle
              const key = [Object.keys(element._embedded)];
              // eslint-disable-next-line no-underscore-dangle
              state[key] = element._embedded[key];
            });
            state.dataReady = true;
          })
            .catch((error) => {
            // catch error for the initiation data load.
              console.log(error);
            });
        });
    },

    SET_DATA_READY(state, isReady) {
      state.dataReady = isReady;
    },

    SET_QUERY_RANGE(state, range) {
      state.queryRange = range;
    },

    SET_IS_TABLE_VISIBLE(state, isTableVisible) {
      state.isTableVisible = isTableVisible;
    },

    SET_FILTERED_FEATURES(state, filteredFeatures) {
      state.filteredFeatures = filteredFeatures;
    },

    SET_SELECTED_FEATURE(state, feature) {
      state.selectedFeature = feature;
    },

    SET_SELECTED_RESOURCE(state, feature) {
      state.selectedResource = feature;
    },

    SET_MAP_VIEW_STATE_CENTER(state, center) {
      state.mapViewState.center = center;
    },
    SET_MAP_VIEW_STATE_ZOOM(state, zoom) {
      state.mapViewState.zoom = zoom;
    },
  },

  actions: {
    setSelectedFeature: ({ commit }, feature) => {
      commit('SET_SELECTED_FEATURE', feature);
    },

    setSelectedResource: ({ commit }, feature) => {
      commit('SET_SELECTED_RESOURCE', feature);
    },

    setDataReady: ({ commit }, isReady) => {
      commit('SET_DATA_READY', isReady);
    },

    setQueryRange: ({ commit }, range) => {
      commit('SET_QUERY_RANGE', range);
      console.log('store :: setQueryRange', range);
    },

    setIsTableVisible: ({ commit }, isTableVisible) => {
      commit('SET_IS_TABLE_VISIBLE', isTableVisible);
    },

    setFilteredFeatures: ({ commit }, filteredFeatures) => {
      commit('SET_FILTERED_FEATURES', filteredFeatures);
    },

    setMapViewStateCenter: ({ commit }, center) => {
      console.log('setMapViewStateCenter', center);
      commit('SET_MAP_VIEW_STATE_CENTER', center);
    },

    setMapViewStateZoom: ({ commit }, zoom) => {
      commit('SET_MAP_VIEW_STATE_ZOOM', zoom);
    },
  },
});

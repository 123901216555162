const backgroundCircle = {
  type: 'CIMPolygonSymbol',
  symbolLayers: [
    {
      type: 'CIMSolidStroke',
      enable: true,
      capStyle: 'Round',
      joinStyle: 'Round',
      lineStyle3D: 'Strip',
      miterLimit: 10,
      width: 1,
      color: [
        90,
        88,
        88,
        255,
      ],
    },
    {
      type: 'CIMSolidFill',
      enable: true,
      color: [
        255,
        200,
        0,
        255,
      ],
    },
  ],
};

const markerCircleGraphic = [
  {
    type: 'CIMMarkerGraphic',
    geometry: {
      rings: [
        [
          [
            8.5,
            0,
          ],
          [
            7.02,
            0.13,
          ],
          [
            5.59,
            0.51,
          ],
          [
            4.25,
            1.14,
          ],
          [
            3.04,
            1.99,
          ],
          [
            1.99,
            3.04,
          ],
          [
            1.14,
            4.25,
          ],
          [
            0.51,
            5.59,
          ],
          [
            0.13,
            7.02,
          ],
          [
            0,
            8.5,
          ],
          [
            0.13,
            9.98,
          ],
          [
            0.51,
            11.41,
          ],
          [
            1.14,
            12.75,
          ],
          [
            1.99,
            13.96,
          ],
          [
            3.04,
            15.01,
          ],
          [
            4.25,
            15.86,
          ],
          [
            5.59,
            16.49,
          ],
          [
            7.02,
            16.87,
          ],
          [
            8.5,
            17,
          ],
          [
            9.98,
            16.87,
          ],
          [
            11.41,
            16.49,
          ],
          [
            12.75,
            15.86,
          ],
          [
            13.96,
            15.01,
          ],
          [
            15.01,
            13.96,
          ],
          [
            15.86,
            12.75,
          ],
          [
            16.49,
            11.41,
          ],
          [
            16.87,
            9.98,
          ],
          [
            17,
            8.5,
          ],
          [
            16.87,
            7.02,
          ],
          [
            16.49,
            5.59,
          ],
          [
            15.86,
            4.25,
          ],
          [
            15.01,
            3.04,
          ],
          [
            13.96,
            1.99,
          ],
          [
            12.75,
            1.14,
          ],
          [
            11.41,
            0.51,
          ],
          [
            9.98,
            0.13,
          ],
          [
            8.5,
            0,
          ],
        ],
      ],
    },
    symbol: backgroundCircle,
  },
];

export default {
  resourceSymbols: {
    Crew: {
      type: 'CIMSymbolReference',
      symbol: {
        type: 'CIMPointSymbol',
        symbolLayers: [
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Z',
            size: 14,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 24,
              ymax: 24,
            },
            markerGraphics: [
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        16.67,
                        10.87,
                      ],
                      [
                        17.21,
                        10.47,
                      ],
                      [
                        17.69,
                        10.04,
                      ],
                      [
                        18.09,
                        9.58,
                      ],
                      [
                        18.41,
                        9.11,
                      ],
                      [
                        18.67,
                        8.61,
                      ],
                      [
                        18.85,
                        8.1,
                      ],
                      [
                        18.96,
                        7.56,
                      ],
                      [
                        19,
                        7,
                      ],
                      [
                        19,
                        4,
                      ],
                      [
                        23,
                        4,
                      ],
                      [
                        23,
                        7,
                      ],
                      [
                        22.97,
                        7.32,
                      ],
                      [
                        22.89,
                        7.64,
                      ],
                      [
                        22.76,
                        7.95,
                      ],
                      [
                        22.57,
                        8.25,
                      ],
                      [
                        22.04,
                        8.82,
                      ],
                      [
                        21.31,
                        9.35,
                      ],
                      [
                        20.36,
                        9.85,
                      ],
                      [
                        19.24,
                        10.27,
                      ],
                      [
                        17.99,
                        10.62,
                      ],
                      [
                        16.67,
                        10.87,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        9,
                        12,
                      ],
                      [
                        9.69,
                        12.06,
                      ],
                      [
                        10.37,
                        12.24,
                      ],
                      [
                        11,
                        12.54,
                      ],
                      [
                        11.57,
                        12.94,
                      ],
                      [
                        12.06,
                        13.43,
                      ],
                      [
                        12.46,
                        14,
                      ],
                      [
                        12.76,
                        14.63,
                      ],
                      [
                        12.94,
                        15.31,
                      ],
                      [
                        13,
                        16,
                      ],
                      [
                        12.94,
                        16.69,
                      ],
                      [
                        12.76,
                        17.37,
                      ],
                      [
                        12.46,
                        18,
                      ],
                      [
                        12.06,
                        18.57,
                      ],
                      [
                        11.57,
                        19.06,
                      ],
                      [
                        11,
                        19.46,
                      ],
                      [
                        10.37,
                        19.76,
                      ],
                      [
                        9.69,
                        19.94,
                      ],
                      [
                        9,
                        20,
                      ],
                      [
                        8.31,
                        19.94,
                      ],
                      [
                        7.63,
                        19.76,
                      ],
                      [
                        7,
                        19.46,
                      ],
                      [
                        6.43,
                        19.06,
                      ],
                      [
                        5.94,
                        18.57,
                      ],
                      [
                        5.54,
                        18,
                      ],
                      [
                        5.24,
                        17.37,
                      ],
                      [
                        5.06,
                        16.69,
                      ],
                      [
                        5,
                        16,
                      ],
                      [
                        5.06,
                        15.31,
                      ],
                      [
                        5.24,
                        14.63,
                      ],
                      [
                        5.54,
                        14,
                      ],
                      [
                        5.94,
                        13.43,
                      ],
                      [
                        6.43,
                        12.94,
                      ],
                      [
                        7,
                        12.54,
                      ],
                      [
                        7.63,
                        12.24,
                      ],
                      [
                        8.31,
                        12.06,
                      ],
                      [
                        9,
                        12,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        15,
                        12,
                      ],
                      [
                        15.78,
                        12.08,
                      ],
                      [
                        16.53,
                        12.3,
                      ],
                      [
                        17.22,
                        12.67,
                      ],
                      [
                        17.83,
                        13.17,
                      ],
                      [
                        18.33,
                        13.78,
                      ],
                      [
                        18.7,
                        14.47,
                      ],
                      [
                        18.92,
                        15.22,
                      ],
                      [
                        19,
                        16,
                      ],
                      [
                        18.92,
                        16.78,
                      ],
                      [
                        18.7,
                        17.53,
                      ],
                      [
                        18.33,
                        18.22,
                      ],
                      [
                        17.83,
                        18.83,
                      ],
                      [
                        17.22,
                        19.33,
                      ],
                      [
                        16.53,
                        19.7,
                      ],
                      [
                        15.78,
                        19.92,
                      ],
                      [
                        15,
                        20,
                      ],
                      [
                        14.36,
                        19.94,
                      ],
                      [
                        13.67,
                        19.76,
                      ],
                      [
                        14.24,
                        18.92,
                      ],
                      [
                        14.66,
                        17.99,
                      ],
                      [
                        14.91,
                        17.01,
                      ],
                      [
                        15,
                        16,
                      ],
                      [
                        14.91,
                        14.99,
                      ],
                      [
                        14.66,
                        14.01,
                      ],
                      [
                        14.24,
                        13.08,
                      ],
                      [
                        13.67,
                        12.24,
                      ],
                      [
                        14.36,
                        12.06,
                      ],
                      [
                        15,
                        12,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        9,
                        11,
                      ],
                      [
                        7.57,
                        10.9,
                      ],
                      [
                        6,
                        10.62,
                      ],
                      [
                        4.53,
                        10.2,
                      ],
                      [
                        3.26,
                        9.67,
                      ],
                      [
                        2.28,
                        9.08,
                      ],
                      [
                        1.57,
                        8.43,
                      ],
                      [
                        1.14,
                        7.74,
                      ],
                      [
                        1,
                        7,
                      ],
                      [
                        1,
                        4,
                      ],
                      [
                        17,
                        4,
                      ],
                      [
                        17,
                        7,
                      ],
                      [
                        16.86,
                        7.74,
                      ],
                      [
                        16.43,
                        8.43,
                      ],
                      [
                        15.72,
                        9.08,
                      ],
                      [
                        14.74,
                        9.67,
                      ],
                      [
                        13.47,
                        10.2,
                      ],
                      [
                        12,
                        10.62,
                      ],
                      [
                        10.43,
                        10.9,
                      ],
                      [
                        9,
                        11,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
            ],
            scaleSymbolsProportionally: true,
            respectFrame: true,
            clippingPath: {
              type: 'CIMClippingPath',
              clippingType: 'Intersect',
              path: {
                rings: [
                  [
                    [
                      0,
                      0,
                    ],
                    [
                      24,
                      0,
                    ],
                    [
                      24,
                      24,
                    ],
                    [
                      0,
                      24,
                    ],
                    [
                      0,
                      0,
                    ],
                  ],
                ],
              },
            },
          },
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPoint: {
              x: 0,
              y: 0,
            },
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Y',
            size: 16,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 17,
              ymax: 17,
            },
            markerGraphics: markerCircleGraphic,
            scaleSymbolsProportionally: true,
            respectFrame: true,
          },
        ],
      },
    },
    Rotary: {
      type: 'CIMSymbolReference',
      symbol: {
        type: 'CIMPointSymbol',
        symbolLayers: [
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Z',
            size: 16,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 100,
              ymax: 100,
            },
            markerGraphics: [
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  x: 0,
                  y: 0,
                },
                symbol: {
                  type: 'CIMPointSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMVectorMarker',
                      enable: true,
                      anchorPointUnits: 'Relative',
                      dominantSizeAxis3D: 'Z',
                      offsetX: 50,
                      offsetY: 50,
                      size: 100,
                      billboardMode3D: 'FaceNearPlane',
                      frame: {
                        xmin: 0,
                        ymin: 0,
                        xmax: 100,
                        ymax: 100,
                      },
                      markerGraphics: [
                        {
                          type: 'CIMMarkerGraphic',
                          geometry: {
                            x: 0,
                            y: 0,
                          },
                          symbol: {
                            type: 'CIMPointSymbol',
                            symbolLayers: [
                              {
                                type: 'CIMVectorMarker',
                                enable: true,
                                anchorPointUnits: 'Relative',
                                dominantSizeAxis3D: 'Z',
                                offsetX: 50,
                                offsetY: 50,
                                size: 100,
                                billboardMode3D: 'FaceNearPlane',
                                frame: {
                                  xmin: 0,
                                  ymin: 0,
                                  xmax: 100,
                                  ymax: 100,
                                },
                                markerGraphics: [
                                  {
                                    type: 'CIMMarkerGraphic',
                                    geometry: {
                                      rings: [
                                        [
                                          [
                                            77.9,
                                            30.2,
                                          ],
                                          [
                                            80.2,
                                            32.5,
                                          ],
                                          [
                                            80.2,
                                            32.7,
                                          ],
                                          [
                                            55,
                                            60.2,
                                          ],
                                          [
                                            55,
                                            60.5,
                                          ],
                                          [
                                            80.2,
                                            88,
                                          ],
                                          [
                                            80.2,
                                            88.3,
                                          ],
                                          [
                                            77.9,
                                            90.5,
                                          ],
                                          [
                                            77.6,
                                            90.5,
                                          ],
                                          [
                                            50.2,
                                            65.4,
                                          ],
                                          [
                                            49.8,
                                            65.4,
                                          ],
                                          [
                                            22.3,
                                            90.6,
                                          ],
                                          [
                                            22.1,
                                            90.6,
                                          ],
                                          [
                                            19.8,
                                            88.3,
                                          ],
                                          [
                                            19.8,
                                            88,
                                          ],
                                          [
                                            45,
                                            60.5,
                                          ],
                                          [
                                            45,
                                            60.2,
                                          ],
                                          [
                                            19.8,
                                            32.7,
                                          ],
                                          [
                                            19.8,
                                            32.4,
                                          ],
                                          [
                                            22,
                                            30.2,
                                          ],
                                          [
                                            22.4,
                                            30.2,
                                          ],
                                          [
                                            49.8,
                                            55.3,
                                          ],
                                          [
                                            50.2,
                                            55.3,
                                          ],
                                          [
                                            77.7,
                                            30.2,
                                          ],
                                          [
                                            77.9,
                                            30.2,
                                          ],
                                        ],
                                      ],
                                    },
                                    symbol: {
                                      type: 'CIMPolygonSymbol',
                                      symbolLayers: [
                                        {
                                          type: 'CIMSolidFill',
                                          enable: true,
                                          color: [
                                            0,
                                            0,
                                            0,
                                            255,
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'CIMMarkerGraphic',
                                    geometry: {
                                      rings: [
                                        [
                                          [
                                            42.79,
                                            75.5,
                                          ],
                                          [
                                            42.81,
                                            76.83,
                                          ],
                                          [
                                            42.99,
                                            78.06,
                                          ],
                                          [
                                            43.33,
                                            79.18,
                                          ],
                                          [
                                            43.83,
                                            80.2,
                                          ],
                                          [
                                            44.48,
                                            81.11,
                                          ],
                                          [
                                            45.3,
                                            81.91,
                                          ],
                                          [
                                            46.27,
                                            82.61,
                                          ],
                                          [
                                            47.4,
                                            83.2,
                                          ],
                                          [
                                            50,
                                            83.71,
                                          ],
                                          [
                                            52.55,
                                            83.18,
                                          ],
                                          [
                                            53.69,
                                            82.64,
                                          ],
                                          [
                                            54.67,
                                            81.98,
                                          ],
                                          [
                                            55.49,
                                            81.2,
                                          ],
                                          [
                                            56.16,
                                            80.31,
                                          ],
                                          [
                                            56.66,
                                            79.3,
                                          ],
                                          [
                                            57.01,
                                            78.17,
                                          ],
                                          [
                                            57.19,
                                            76.94,
                                          ],
                                          [
                                            57.22,
                                            75.58,
                                          ],
                                          [
                                            57.46,
                                            75.48,
                                          ],
                                          [
                                            61.95,
                                            80.04,
                                          ],
                                          [
                                            62.01,
                                            80.3,
                                          ],
                                          [
                                            60.98,
                                            82.66,
                                          ],
                                          [
                                            59.6,
                                            84.67,
                                          ],
                                          [
                                            57.89,
                                            86.33,
                                          ],
                                          [
                                            55.85,
                                            87.65,
                                          ],
                                          [
                                            53.34,
                                            88.65,
                                          ],
                                          [
                                            50.72,
                                            89.09,
                                          ],
                                          [
                                            47.95,
                                            88.96,
                                          ],
                                          [
                                            45.32,
                                            88.2,
                                          ],
                                          [
                                            42.9,
                                            86.9,
                                          ],
                                          [
                                            40.88,
                                            85.2,
                                          ],
                                          [
                                            39.27,
                                            83.09,
                                          ],
                                          [
                                            38.07,
                                            80.59,
                                          ],
                                          [
                                            38.23,
                                            79.79,
                                          ],
                                          [
                                            42.65,
                                            75.44,
                                          ],
                                          [
                                            42.79,
                                            75.5,
                                          ],
                                        ],
                                      ],
                                    },
                                    symbol: {
                                      type: 'CIMPolygonSymbol',
                                      symbolLayers: [
                                        {
                                          type: 'CIMSolidFill',
                                          enable: true,
                                          color: [
                                            0,
                                            0,
                                            0,
                                            255,
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'CIMMarkerGraphic',
                                    geometry: {
                                      rings: [
                                        [
                                          [
                                            50.2,
                                            68.22,
                                          ],
                                          [
                                            55.12,
                                            73.18,
                                          ],
                                          [
                                            55.08,
                                            73.29,
                                          ],
                                          [
                                            44.99,
                                            73.35,
                                          ],
                                          [
                                            44.9,
                                            73.12,
                                          ],
                                          [
                                            49.83,
                                            68.22,
                                          ],
                                          [
                                            50.2,
                                            68.22,
                                          ],
                                        ],
                                      ],
                                    },
                                    symbol: {
                                      type: 'CIMPolygonSymbol',
                                      symbolLayers: [
                                        {
                                          type: 'CIMSolidFill',
                                          enable: true,
                                          color: [
                                            0,
                                            0,
                                            0,
                                            255,
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'CIMMarkerGraphic',
                                    geometry: {
                                      rings: [
                                        [
                                          [
                                            42.17,
                                            60.19,
                                          ],
                                          [
                                            42.17,
                                            60.53,
                                          ],
                                          [
                                            37.66,
                                            65.08,
                                          ],
                                          [
                                            37.4,
                                            64.98,
                                          ],
                                          [
                                            37.4,
                                            55.72,
                                          ],
                                          [
                                            37.62,
                                            55.64,
                                          ],
                                          [
                                            42.17,
                                            60.19,
                                          ],
                                        ],
                                      ],
                                    },
                                    symbol: {
                                      type: 'CIMPolygonSymbol',
                                      symbolLayers: [
                                        {
                                          type: 'CIMSolidFill',
                                          enable: true,
                                          color: [
                                            0,
                                            0,
                                            0,
                                            255,
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'CIMMarkerGraphic',
                                    geometry: {
                                      rings: [
                                        [
                                          [
                                            62.62,
                                            55.74,
                                          ],
                                          [
                                            62.62,
                                            65.04,
                                          ],
                                          [
                                            62.41,
                                            65.12,
                                          ],
                                          [
                                            57.8,
                                            60.51,
                                          ],
                                          [
                                            57.8,
                                            60.22,
                                          ],
                                          [
                                            62.4,
                                            55.65,
                                          ],
                                          [
                                            62.62,
                                            55.74,
                                          ],
                                        ],
                                      ],
                                    },
                                    symbol: {
                                      type: 'CIMPolygonSymbol',
                                      symbolLayers: [
                                        {
                                          type: 'CIMSolidFill',
                                          enable: true,
                                          color: [
                                            0,
                                            0,
                                            0,
                                            255,
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    type: 'CIMMarkerGraphic',
                                    geometry: {
                                      rings: [
                                        [
                                          [
                                            41.7,
                                            8.8,
                                          ],
                                          [
                                            41.7,
                                            15.7,
                                          ],
                                          [
                                            41.9,
                                            16,
                                          ],
                                          [
                                            46.9,
                                            16,
                                          ],
                                          [
                                            47.2,
                                            15.7,
                                          ],
                                          [
                                            47.3,
                                            14.4,
                                          ],
                                          [
                                            47.5,
                                            13.1,
                                          ],
                                          [
                                            48.5,
                                            11.8,
                                          ],
                                          [
                                            50.1,
                                            11.4,
                                          ],
                                          [
                                            51.8,
                                            12,
                                          ],
                                          [
                                            52.6,
                                            13.7,
                                          ],
                                          [
                                            52.9,
                                            15.8,
                                          ],
                                          [
                                            53.1,
                                            16,
                                          ],
                                          [
                                            59.9,
                                            16,
                                          ],
                                          [
                                            60.2,
                                            16.3,
                                          ],
                                          [
                                            60.2,
                                            22.9,
                                          ],
                                          [
                                            60,
                                            23.2,
                                          ],
                                          [
                                            53.7,
                                            23.2,
                                          ],
                                          [
                                            53.5,
                                            23.4,
                                          ],
                                          [
                                            55.1,
                                            41.4,
                                          ],
                                          [
                                            55.3,
                                            41.7,
                                          ],
                                          [
                                            58.6,
                                            43.9,
                                          ],
                                          [
                                            58.6,
                                            44.1,
                                          ],
                                          [
                                            50.2,
                                            52.5,
                                          ],
                                          [
                                            49.8,
                                            52.5,
                                          ],
                                          [
                                            41.4,
                                            44.1,
                                          ],
                                          [
                                            41.4,
                                            43.9,
                                          ],
                                          [
                                            44.7,
                                            41.7,
                                          ],
                                          [
                                            44.9,
                                            41.5,
                                          ],
                                          [
                                            46.5,
                                            23.5,
                                          ],
                                          [
                                            46.2,
                                            23.2,
                                          ],
                                          [
                                            41.9,
                                            23.2,
                                          ],
                                          [
                                            41.8,
                                            23.4,
                                          ],
                                          [
                                            41.7,
                                            30.5,
                                          ],
                                          [
                                            41.5,
                                            30.7,
                                          ],
                                          [
                                            38.3,
                                            30.7,
                                          ],
                                          [
                                            38.1,
                                            30.5,
                                          ],
                                          [
                                            38.1,
                                            22.9,
                                          ],
                                          [
                                            38,
                                            22.7,
                                          ],
                                          [
                                            36.7,
                                            21.1,
                                          ],
                                          [
                                            36.3,
                                            19.6,
                                          ],
                                          [
                                            36.7,
                                            18.1,
                                          ],
                                          [
                                            37.9,
                                            16.6,
                                          ],
                                          [
                                            38.1,
                                            16.3,
                                          ],
                                          [
                                            38.1,
                                            8.9,
                                          ],
                                          [
                                            38.4,
                                            8.6,
                                          ],
                                          [
                                            41.4,
                                            8.5,
                                          ],
                                          [
                                            41.7,
                                            8.8,
                                          ],
                                        ],
                                      ],
                                    },
                                    symbol: {
                                      type: 'CIMPolygonSymbol',
                                      symbolLayers: [
                                        {
                                          type: 'CIMSolidFill',
                                          enable: true,
                                          color: [
                                            0,
                                            0,
                                            0,
                                            255,
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                ],
                                scaleSymbolsProportionally: true,
                                respectFrame: true,
                                clippingPath: {
                                  type: 'CIMClippingPath',
                                  clippingType: 'Intersect',
                                  path: {
                                    rings: [
                                      [
                                        [
                                          0,
                                          0,
                                        ],
                                        [
                                          100,
                                          0,
                                        ],
                                        [
                                          100,
                                          100,
                                        ],
                                        [
                                          0,
                                          100,
                                        ],
                                        [
                                          0,
                                          0,
                                        ],
                                      ],
                                    ],
                                  },
                                },
                              },
                            ],
                            haloSize: 1,
                            scaleX: 1,
                            angleAlignment: 'Display',
                          },
                        },
                      ],
                      scaleSymbolsProportionally: true,
                      respectFrame: true,
                    },
                  ],
                  haloSize: 1,
                  scaleX: 1,
                  angleAlignment: 'Display',
                },
              },
            ],
            scaleSymbolsProportionally: true,
            respectFrame: true,
            clippingPath: {
              type: 'CIMClippingPath',
              clippingType: 'Intersect',
              path: {
                rings: [
                  [
                    [
                      0,
                      0,
                    ],
                    [
                      100,
                      0,
                    ],
                    [
                      100,
                      100,
                    ],
                    [
                      0,
                      100,
                    ],
                    [
                      0,
                      0,
                    ],
                  ],
                ],
              },
            },
          },
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPoint: {
              x: 0,
              y: 0,
            },
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Y',
            size: 16,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 17,
              ymax: 17,
            },
            markerGraphics: markerCircleGraphic,
            scaleSymbolsProportionally: true,
            respectFrame: true,
          },
        ],
      },
    },
    UAV: {
      type: 'CIMSymbolReference',
      symbol: {
        type: 'CIMPointSymbol',
        symbolLayers: [
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Z',
            size: 13,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 100,
              ymax: 100,
            },
            markerGraphics: [
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        79.54,
                        73.37,
                      ],
                      [
                        78.86,
                        74.51,
                      ],
                      [
                        78.82,
                        75.18,
                      ],
                      [
                        78.38,
                        76.54,
                      ],
                      [
                        77.63,
                        77.6,
                      ],
                      [
                        76.59,
                        78.36,
                      ],
                      [
                        75.24,
                        78.83,
                      ],
                      [
                        74.49,
                        78.9,
                      ],
                      [
                        73.52,
                        79.34,
                      ],
                      [
                        73.11,
                        80.24,
                      ],
                      [
                        73.31,
                        81.12,
                      ],
                      [
                        74.1,
                        81.72,
                      ],
                      [
                        75.08,
                        81.78,
                      ],
                      [
                        76.04,
                        81.61,
                      ],
                      [
                        78.19,
                        80.79,
                      ],
                      [
                        79.96,
                        79.3,
                      ],
                      [
                        80.72,
                        78.26,
                      ],
                      [
                        81.27,
                        77.12,
                      ],
                      [
                        81.6,
                        75.91,
                      ],
                      [
                        81.71,
                        74.6,
                      ],
                      [
                        81.47,
                        73.72,
                      ],
                      [
                        80.76,
                        73.2,
                      ],
                      [
                        79.54,
                        73.37,
                      ],
                    ],
                    [
                      [
                        87.26,
                        73.75,
                      ],
                      [
                        86.88,
                        73.35,
                      ],
                      [
                        86.42,
                        73.16,
                      ],
                      [
                        85.26,
                        73.42,
                      ],
                      [
                        84.78,
                        73.94,
                      ],
                      [
                        84.68,
                        74.6,
                      ],
                      [
                        84.28,
                        77.22,
                      ],
                      [
                        83.28,
                        79.61,
                      ],
                      [
                        80.73,
                        82.54,
                      ],
                      [
                        79.4,
                        83.41,
                      ],
                      [
                        77.94,
                        84.05,
                      ],
                      [
                        76.37,
                        84.47,
                      ],
                      [
                        74.67,
                        84.66,
                      ],
                      [
                        73.57,
                        85.01,
                      ],
                      [
                        73.27,
                        85.4,
                      ],
                      [
                        73.13,
                        85.93,
                      ],
                      [
                        73.49,
                        87.1,
                      ],
                      [
                        74.78,
                        87.55,
                      ],
                      [
                        76.78,
                        87.34,
                      ],
                      [
                        78.67,
                        86.86,
                      ],
                      [
                        80.43,
                        86.11,
                      ],
                      [
                        82.08,
                        85.08,
                      ],
                      [
                        84.37,
                        83.01,
                      ],
                      [
                        86.04,
                        80.61,
                      ],
                      [
                        87.08,
                        77.89,
                      ],
                      [
                        87.5,
                        74.84,
                      ],
                      [
                        87.26,
                        73.75,
                      ],
                    ],
                    [
                      [
                        70.85,
                        62.5,
                      ],
                      [
                        72.69,
                        61.94,
                      ],
                      [
                        74.54,
                        61.63,
                      ],
                      [
                        76.4,
                        61.57,
                      ],
                      [
                        78.27,
                        61.74,
                      ],
                      [
                        81.99,
                        62.88,
                      ],
                      [
                        84.47,
                        64.3,
                      ],
                      [
                        86.55,
                        66.1,
                      ],
                      [
                        88.22,
                        68.28,
                      ],
                      [
                        89.5,
                        70.84,
                      ],
                      [
                        90.42,
                        74.62,
                      ],
                      [
                        90.49,
                        76.51,
                      ],
                      [
                        90.33,
                        78.35,
                      ],
                      [
                        89.91,
                        80.13,
                      ],
                      [
                        89.26,
                        81.87,
                      ],
                      [
                        87.66,
                        84.64,
                      ],
                      [
                        85.64,
                        86.89,
                      ],
                      [
                        83.17,
                        88.63,
                      ],
                      [
                        80.27,
                        89.85,
                      ],
                      [
                        76.44,
                        90.49,
                      ],
                      [
                        73.56,
                        90.3,
                      ],
                      [
                        70.87,
                        89.56,
                      ],
                      [
                        68.37,
                        88.28,
                      ],
                      [
                        66.04,
                        86.45,
                      ],
                      [
                        64.55,
                        84.82,
                      ],
                      [
                        63.35,
                        83.01,
                      ],
                      [
                        62.45,
                        81.04,
                      ],
                      [
                        61.85,
                        78.9,
                      ],
                      [
                        61.59,
                        76.89,
                      ],
                      [
                        61.6,
                        74.89,
                      ],
                      [
                        61.89,
                        72.9,
                      ],
                      [
                        62.46,
                        70.91,
                      ],
                      [
                        62.66,
                        70.87,
                      ],
                      [
                        66.07,
                        74.28,
                      ],
                      [
                        68.84,
                        76.61,
                      ],
                      [
                        70.64,
                        77.19,
                      ],
                      [
                        72.57,
                        77.1,
                      ],
                      [
                        75.2,
                        75.82,
                      ],
                      [
                        76.9,
                        73.3,
                      ],
                      [
                        77.05,
                        70.12,
                      ],
                      [
                        76.45,
                        68.58,
                      ],
                      [
                        75.4,
                        67.23,
                      ],
                      [
                        70.82,
                        62.67,
                      ],
                      [
                        70.85,
                        62.5,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        18.36,
                        73.88,
                      ],
                      [
                        18.19,
                        74.87,
                      ],
                      [
                        18.4,
                        76.35,
                      ],
                      [
                        18.89,
                        77.69,
                      ],
                      [
                        19.65,
                        78.91,
                      ],
                      [
                        20.69,
                        80,
                      ],
                      [
                        21.69,
                        80.74,
                      ],
                      [
                        22.79,
                        81.29,
                      ],
                      [
                        23.98,
                        81.63,
                      ],
                      [
                        25.26,
                        81.78,
                      ],
                      [
                        26.39,
                        81.35,
                      ],
                      [
                        26.86,
                        80.4,
                      ],
                      [
                        26.78,
                        79.91,
                      ],
                      [
                        26.49,
                        79.43,
                      ],
                      [
                        25.47,
                        78.89,
                      ],
                      [
                        24.67,
                        78.83,
                      ],
                      [
                        23.37,
                        78.38,
                      ],
                      [
                        22.36,
                        77.66,
                      ],
                      [
                        21.61,
                        76.66,
                      ],
                      [
                        21.15,
                        75.38,
                      ],
                      [
                        21.06,
                        74.5,
                      ],
                      [
                        20.51,
                        73.45,
                      ],
                      [
                        19.19,
                        73.2,
                      ],
                      [
                        18.36,
                        73.88,
                      ],
                    ],
                    [
                      [
                        12.64,
                        73.77,
                      ],
                      [
                        12.42,
                        74.81,
                      ],
                      [
                        12.63,
                        77,
                      ],
                      [
                        13.2,
                        79.07,
                      ],
                      [
                        14.12,
                        81,
                      ],
                      [
                        15.4,
                        82.8,
                      ],
                      [
                        17.53,
                        84.89,
                      ],
                      [
                        19.93,
                        86.38,
                      ],
                      [
                        22.6,
                        87.26,
                      ],
                      [
                        25.54,
                        87.54,
                      ],
                      [
                        26.14,
                        87.34,
                      ],
                      [
                        26.82,
                        86.27,
                      ],
                      [
                        26.47,
                        85.19,
                      ],
                      [
                        25.21,
                        84.66,
                      ],
                      [
                        23.31,
                        84.46,
                      ],
                      [
                        21.54,
                        83.92,
                      ],
                      [
                        19.91,
                        83.05,
                      ],
                      [
                        18.41,
                        81.84,
                      ],
                      [
                        17.08,
                        80.3,
                      ],
                      [
                        16.11,
                        78.62,
                      ],
                      [
                        15.52,
                        76.78,
                      ],
                      [
                        15.3,
                        74.8,
                      ],
                      [
                        14.81,
                        73.53,
                      ],
                      [
                        13.64,
                        73.15,
                      ],
                      [
                        12.64,
                        73.77,
                      ],
                    ],
                    [
                      [
                        26.12,
                        61.7,
                      ],
                      [
                        29.06,
                        62.52,
                      ],
                      [
                        29.09,
                        62.62,
                      ],
                      [
                        24.63,
                        67.14,
                      ],
                      [
                        23.68,
                        68.26,
                      ],
                      [
                        23.05,
                        69.47,
                      ],
                      [
                        22.75,
                        70.79,
                      ],
                      [
                        22.77,
                        72.21,
                      ],
                      [
                        23.18,
                        73.76,
                      ],
                      [
                        24.01,
                        75.1,
                      ],
                      [
                        26.48,
                        76.85,
                      ],
                      [
                        27.61,
                        77.16,
                      ],
                      [
                        28.73,
                        77.24,
                      ],
                      [
                        29.86,
                        77.07,
                      ],
                      [
                        30.98,
                        76.66,
                      ],
                      [
                        32.64,
                        75.44,
                      ],
                      [
                        37.26,
                        70.87,
                      ],
                      [
                        37.47,
                        70.92,
                      ],
                      [
                        38.17,
                        73.47,
                      ],
                      [
                        38.39,
                        76.02,
                      ],
                      [
                        38.14,
                        78.57,
                      ],
                      [
                        37.41,
                        81.12,
                      ],
                      [
                        36.5,
                        83.11,
                      ],
                      [
                        35.33,
                        84.89,
                      ],
                      [
                        33.9,
                        86.47,
                      ],
                      [
                        32.21,
                        87.85,
                      ],
                      [
                        29.39,
                        89.4,
                      ],
                      [
                        26.34,
                        90.24,
                      ],
                      [
                        23.65,
                        90.44,
                      ],
                      [
                        21.09,
                        90.18,
                      ],
                      [
                        18.66,
                        89.46,
                      ],
                      [
                        16.35,
                        88.29,
                      ],
                      [
                        13.35,
                        85.8,
                      ],
                      [
                        11.96,
                        84.07,
                      ],
                      [
                        10.88,
                        82.19,
                      ],
                      [
                        10.11,
                        80.15,
                      ],
                      [
                        9.64,
                        77.96,
                      ],
                      [
                        9.62,
                        74.07,
                      ],
                      [
                        10.34,
                        71.1,
                      ],
                      [
                        11.65,
                        68.41,
                      ],
                      [
                        12.7,
                        66.93,
                      ],
                      [
                        13.87,
                        65.63,
                      ],
                      [
                        15.15,
                        64.51,
                      ],
                      [
                        16.55,
                        63.56,
                      ],
                      [
                        18.06,
                        62.8,
                      ],
                      [
                        19.69,
                        62.21,
                      ],
                      [
                        21.44,
                        61.8,
                      ],
                      [
                        23.3,
                        61.57,
                      ],
                      [
                        26.12,
                        61.7,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        50,
                        45.7,
                      ],
                      [
                        48.3,
                        46,
                      ],
                      [
                        46.9,
                        46.9,
                      ],
                      [
                        46,
                        48.3,
                      ],
                      [
                        45.6,
                        50,
                      ],
                      [
                        46,
                        51.6,
                      ],
                      [
                        46.9,
                        53.1,
                      ],
                      [
                        48.3,
                        54,
                      ],
                      [
                        50,
                        54.3,
                      ],
                      [
                        51.6,
                        54,
                      ],
                      [
                        53,
                        53.1,
                      ],
                      [
                        54,
                        51.6,
                      ],
                      [
                        54.3,
                        50,
                      ],
                      [
                        54,
                        48.3,
                      ],
                      [
                        53,
                        46.9,
                      ],
                      [
                        51.6,
                        46,
                      ],
                      [
                        50,
                        45.7,
                      ],
                    ],
                    [
                      [
                        71.5,
                        25.6,
                      ],
                      [
                        72.6,
                        25.9,
                      ],
                      [
                        73.6,
                        26.7,
                      ],
                      [
                        74.2,
                        27.9,
                      ],
                      [
                        74.2,
                        29.4,
                      ],
                      [
                        73.2,
                        30.8,
                      ],
                      [
                        63.5,
                        40.5,
                      ],
                      [
                        61.7,
                        42.6,
                      ],
                      [
                        60.5,
                        44.8,
                      ],
                      [
                        59.7,
                        47.3,
                      ],
                      [
                        59.4,
                        49.9,
                      ],
                      [
                        59.9,
                        53.7,
                      ],
                      [
                        61.5,
                        57,
                      ],
                      [
                        64.1,
                        60,
                      ],
                      [
                        73.4,
                        69.3,
                      ],
                      [
                        74.1,
                        70.5,
                      ],
                      [
                        74.3,
                        71.7,
                      ],
                      [
                        73.6,
                        73.4,
                      ],
                      [
                        71.9,
                        74.3,
                      ],
                      [
                        70.5,
                        74.2,
                      ],
                      [
                        69.2,
                        73.4,
                      ],
                      [
                        59.9,
                        64.1,
                      ],
                      [
                        57,
                        61.5,
                      ],
                      [
                        55.1,
                        60.5,
                      ],
                      [
                        53.1,
                        59.8,
                      ],
                      [
                        51.1,
                        59.5,
                      ],
                      [
                        48.9,
                        59.5,
                      ],
                      [
                        45.5,
                        60.2,
                      ],
                      [
                        42.5,
                        61.9,
                      ],
                      [
                        39.6,
                        64.5,
                      ],
                      [
                        30.7,
                        73.3,
                      ],
                      [
                        29.6,
                        74.1,
                      ],
                      [
                        28.3,
                        74.3,
                      ],
                      [
                        27.2,
                        74,
                      ],
                      [
                        26.2,
                        73.2,
                      ],
                      [
                        25.6,
                        71.9,
                      ],
                      [
                        25.8,
                        70.4,
                      ],
                      [
                        26.6,
                        69.4,
                      ],
                      [
                        36.2,
                        59.7,
                      ],
                      [
                        37.8,
                        58,
                      ],
                      [
                        39,
                        56.1,
                      ],
                      [
                        39.8,
                        54.1,
                      ],
                      [
                        40.3,
                        51.9,
                      ],
                      [
                        40.3,
                        48,
                      ],
                      [
                        39.8,
                        45.9,
                      ],
                      [
                        39,
                        44,
                      ],
                      [
                        37.9,
                        42.2,
                      ],
                      [
                        36.4,
                        40.5,
                      ],
                      [
                        26.6,
                        30.7,
                      ],
                      [
                        25.8,
                        29.4,
                      ],
                      [
                        25.7,
                        27.9,
                      ],
                      [
                        26.4,
                        26.5,
                      ],
                      [
                        27.8,
                        25.7,
                      ],
                      [
                        29.7,
                        25.9,
                      ],
                      [
                        31.2,
                        27.1,
                      ],
                      [
                        38.9,
                        34.9,
                      ],
                      [
                        41.7,
                        37.6,
                      ],
                      [
                        43.5,
                        38.8,
                      ],
                      [
                        45.4,
                        39.7,
                      ],
                      [
                        47.5,
                        40.3,
                      ],
                      [
                        49.7,
                        40.5,
                      ],
                      [
                        53.4,
                        40,
                      ],
                      [
                        57,
                        38.5,
                      ],
                      [
                        59.9,
                        36,
                      ],
                      [
                        68.9,
                        27,
                      ],
                      [
                        70.4,
                        25.9,
                      ],
                      [
                        71.5,
                        25.6,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        50.971,
                        48.987,
                      ],
                      [
                        51.388,
                        49.996,
                      ],
                      [
                        51.361,
                        50.274,
                      ],
                      [
                        51.28,
                        50.541,
                      ],
                      [
                        51.148,
                        50.787,
                      ],
                      [
                        50.971,
                        51.004,
                      ],
                      [
                        50.754,
                        51.182,
                      ],
                      [
                        50.508,
                        51.313,
                      ],
                      [
                        50.241,
                        51.394,
                      ],
                      [
                        49.962,
                        51.421,
                      ],
                      [
                        49.684,
                        51.394,
                      ],
                      [
                        49.417,
                        51.313,
                      ],
                      [
                        49.171,
                        51.181,
                      ],
                      [
                        48.954,
                        51.004,
                      ],
                      [
                        48.776,
                        50.787,
                      ],
                      [
                        48.644,
                        50.541,
                      ],
                      [
                        48.564,
                        50.274,
                      ],
                      [
                        48.537,
                        49.996,
                      ],
                      [
                        48.564,
                        49.717,
                      ],
                      [
                        48.645,
                        49.45,
                      ],
                      [
                        48.776,
                        49.204,
                      ],
                      [
                        48.954,
                        48.987,
                      ],
                      [
                        49.171,
                        48.81,
                      ],
                      [
                        49.417,
                        48.678,
                      ],
                      [
                        49.684,
                        48.597,
                      ],
                      [
                        49.962,
                        48.57,
                      ],
                      [
                        50.971,
                        48.987,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        22.62,
                        18.76,
                      ],
                      [
                        20.56,
                        20.1,
                      ],
                      [
                        19.48,
                        21.28,
                      ],
                      [
                        18.72,
                        22.59,
                      ],
                      [
                        18.29,
                        24,
                      ],
                      [
                        18.18,
                        25.53,
                      ],
                      [
                        18.8,
                        26.63,
                      ],
                      [
                        19.93,
                        26.8,
                      ],
                      [
                        20.71,
                        26.37,
                      ],
                      [
                        21.04,
                        25.5,
                      ],
                      [
                        21.13,
                        24.69,
                      ],
                      [
                        21.6,
                        23.36,
                      ],
                      [
                        22.36,
                        22.32,
                      ],
                      [
                        23.42,
                        21.59,
                      ],
                      [
                        24.77,
                        21.15,
                      ],
                      [
                        25.46,
                        21.08,
                      ],
                      [
                        26.57,
                        20.46,
                      ],
                      [
                        26.78,
                        19.32,
                      ],
                      [
                        26.07,
                        18.39,
                      ],
                      [
                        25,
                        18.22,
                      ],
                      [
                        22.62,
                        18.76,
                      ],
                    ],
                    [
                      [
                        25.15,
                        12.45,
                      ],
                      [
                        22.84,
                        12.69,
                      ],
                      [
                        20.68,
                        13.32,
                      ],
                      [
                        18.67,
                        14.33,
                      ],
                      [
                        16.81,
                        15.72,
                      ],
                      [
                        14.97,
                        17.69,
                      ],
                      [
                        13.63,
                        19.9,
                      ],
                      [
                        12.78,
                        22.35,
                      ],
                      [
                        12.43,
                        25.04,
                      ],
                      [
                        12.49,
                        25.82,
                      ],
                      [
                        12.74,
                        26.38,
                      ],
                      [
                        13.18,
                        26.71,
                      ],
                      [
                        13.81,
                        26.82,
                      ],
                      [
                        14.89,
                        26.37,
                      ],
                      [
                        15.3,
                        25.14,
                      ],
                      [
                        15.55,
                        23.17,
                      ],
                      [
                        16.14,
                        21.36,
                      ],
                      [
                        17.08,
                        19.7,
                      ],
                      [
                        18.37,
                        18.2,
                      ],
                      [
                        19.87,
                        16.97,
                      ],
                      [
                        21.5,
                        16.08,
                      ],
                      [
                        23.27,
                        15.53,
                      ],
                      [
                        25.16,
                        15.33,
                      ],
                      [
                        26.43,
                        14.83,
                      ],
                      [
                        26.8,
                        13.67,
                      ],
                      [
                        26.19,
                        12.68,
                      ],
                      [
                        25.15,
                        12.45,
                      ],
                    ],
                    [
                      [
                        20.42,
                        9.95,
                      ],
                      [
                        23.02,
                        9.54,
                      ],
                      [
                        25.58,
                        9.6,
                      ],
                      [
                        28.09,
                        10.14,
                      ],
                      [
                        30.56,
                        11.16,
                      ],
                      [
                        32.54,
                        12.36,
                      ],
                      [
                        34.23,
                        13.79,
                      ],
                      [
                        35.65,
                        15.45,
                      ],
                      [
                        36.78,
                        17.33,
                      ],
                      [
                        37.88,
                        20.18,
                      ],
                      [
                        38.36,
                        23.09,
                      ],
                      [
                        38.23,
                        26.06,
                      ],
                      [
                        37.49,
                        29.07,
                      ],
                      [
                        37.27,
                        29.12,
                      ],
                      [
                        33.51,
                        25.37,
                      ],
                      [
                        31.2,
                        23.44,
                      ],
                      [
                        28.15,
                        22.8,
                      ],
                      [
                        25.51,
                        23.59,
                      ],
                      [
                        23.91,
                        25.01,
                      ],
                      [
                        23.36,
                        25.88,
                      ],
                      [
                        22.98,
                        26.86,
                      ],
                      [
                        22.91,
                        29.97,
                      ],
                      [
                        24.55,
                        32.78,
                      ],
                      [
                        29.1,
                        37.31,
                      ],
                      [
                        29.06,
                        37.47,
                      ],
                      [
                        25.96,
                        38.25,
                      ],
                      [
                        22.87,
                        38.35,
                      ],
                      [
                        20.57,
                        37.99,
                      ],
                      [
                        18.41,
                        37.3,
                      ],
                      [
                        16.39,
                        36.27,
                      ],
                      [
                        14.52,
                        34.91,
                      ],
                      [
                        12.84,
                        33.23,
                      ],
                      [
                        11.49,
                        31.35,
                      ],
                      [
                        10.49,
                        29.27,
                      ],
                      [
                        9.82,
                        26.97,
                      ],
                      [
                        9.53,
                        23.09,
                      ],
                      [
                        9.84,
                        20.78,
                      ],
                      [
                        10.5,
                        18.61,
                      ],
                      [
                        11.51,
                        16.58,
                      ],
                      [
                        12.87,
                        14.7,
                      ],
                      [
                        14.49,
                        13.05,
                      ],
                      [
                        16.29,
                        11.7,
                      ],
                      [
                        18.27,
                        10.67,
                      ],
                      [
                        20.42,
                        9.95,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        73.53,
                        18.63,
                      ],
                      [
                        73.19,
                        20.01,
                      ],
                      [
                        73.66,
                        20.78,
                      ],
                      [
                        74.45,
                        21.08,
                      ],
                      [
                        75.08,
                        21.14,
                      ],
                      [
                        76.51,
                        21.59,
                      ],
                      [
                        77.6,
                        22.35,
                      ],
                      [
                        78.36,
                        23.43,
                      ],
                      [
                        78.79,
                        24.82,
                      ],
                      [
                        79.08,
                        26.11,
                      ],
                      [
                        79.59,
                        26.68,
                      ],
                      [
                        80.36,
                        26.83,
                      ],
                      [
                        81.49,
                        26.24,
                      ],
                      [
                        81.77,
                        25.3,
                      ],
                      [
                        81.66,
                        24.36,
                      ],
                      [
                        81.37,
                        23.11,
                      ],
                      [
                        80.87,
                        21.97,
                      ],
                      [
                        80.17,
                        20.93,
                      ],
                      [
                        79.27,
                        20,
                      ],
                      [
                        78.27,
                        19.26,
                      ],
                      [
                        77.18,
                        18.72,
                      ],
                      [
                        76,
                        18.37,
                      ],
                      [
                        74.72,
                        18.21,
                      ],
                      [
                        73.53,
                        18.63,
                      ],
                    ],
                    [
                      [
                        73.74,
                        12.68,
                      ],
                      [
                        73.14,
                        13.53,
                      ],
                      [
                        73.27,
                        14.53,
                      ],
                      [
                        73.78,
                        15.13,
                      ],
                      [
                        74.61,
                        15.34,
                      ],
                      [
                        75.13,
                        15.34,
                      ],
                      [
                        77.28,
                        15.71,
                      ],
                      [
                        79.22,
                        16.47,
                      ],
                      [
                        80.94,
                        17.64,
                      ],
                      [
                        82.46,
                        19.21,
                      ],
                      [
                        83.39,
                        20.58,
                      ],
                      [
                        84.06,
                        22.05,
                      ],
                      [
                        84.47,
                        23.62,
                      ],
                      [
                        84.62,
                        25.29,
                      ],
                      [
                        85.13,
                        26.49,
                      ],
                      [
                        86.27,
                        26.84,
                      ],
                      [
                        87.29,
                        26.2,
                      ],
                      [
                        87.52,
                        25.11,
                      ],
                      [
                        87.19,
                        22.45,
                      ],
                      [
                        86.29,
                        19.92,
                      ],
                      [
                        85.14,
                        17.93,
                      ],
                      [
                        83.71,
                        16.22,
                      ],
                      [
                        82,
                        14.79,
                      ],
                      [
                        80.01,
                        13.65,
                      ],
                      [
                        77.24,
                        12.7,
                      ],
                      [
                        74.4,
                        12.44,
                      ],
                      [
                        73.74,
                        12.68,
                      ],
                    ],
                    [
                      [
                        79.52,
                        9.97,
                      ],
                      [
                        81.22,
                        10.51,
                      ],
                      [
                        82.81,
                        11.24,
                      ],
                      [
                        84.29,
                        12.16,
                      ],
                      [
                        85.67,
                        13.26,
                      ],
                      [
                        87.24,
                        14.91,
                      ],
                      [
                        88.5,
                        16.74,
                      ],
                      [
                        89.45,
                        18.74,
                      ],
                      [
                        90.09,
                        20.92,
                      ],
                      [
                        90.39,
                        24.8,
                      ],
                      [
                        89.96,
                        27.67,
                      ],
                      [
                        89.01,
                        30.3,
                      ],
                      [
                        87.54,
                        32.67,
                      ],
                      [
                        85.55,
                        34.8,
                      ],
                      [
                        83.08,
                        36.58,
                      ],
                      [
                        80.4,
                        37.77,
                      ],
                      [
                        77.51,
                        38.36,
                      ],
                      [
                        74.4,
                        38.34,
                      ],
                      [
                        72.62,
                        38.03,
                      ],
                      [
                        70.89,
                        37.51,
                      ],
                      [
                        70.85,
                        37.29,
                      ],
                      [
                        74.6,
                        33.53,
                      ],
                      [
                        76.45,
                        31.37,
                      ],
                      [
                        77.19,
                        28.54,
                      ],
                      [
                        76.59,
                        25.93,
                      ],
                      [
                        74.81,
                        23.83,
                      ],
                      [
                        73.4,
                        23.08,
                      ],
                      [
                        71.96,
                        22.75,
                      ],
                      [
                        70.49,
                        22.82,
                      ],
                      [
                        68.98,
                        23.31,
                      ],
                      [
                        67.77,
                        24.09,
                      ],
                      [
                        66.7,
                        25.08,
                      ],
                      [
                        62.62,
                        29.16,
                      ],
                      [
                        62.48,
                        29.13,
                      ],
                      [
                        61.68,
                        26.02,
                      ],
                      [
                        61.61,
                        22.8,
                      ],
                      [
                        62.08,
                        20.12,
                      ],
                      [
                        62.99,
                        17.66,
                      ],
                      [
                        64.35,
                        15.42,
                      ],
                      [
                        66.16,
                        13.41,
                      ],
                      [
                        68.26,
                        11.76,
                      ],
                      [
                        70.54,
                        10.56,
                      ],
                      [
                        73.01,
                        9.82,
                      ],
                      [
                        75.65,
                        9.54,
                      ],
                      [
                        79.52,
                        9.97,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        53.46,
                        62.22,
                      ],
                      [
                        54.58,
                        62.45,
                      ],
                      [
                        55.53,
                        63.08,
                      ],
                      [
                        56.17,
                        64.03,
                      ],
                      [
                        56.39,
                        65.15,
                      ],
                      [
                        56.39,
                        75.68,
                      ],
                      [
                        56.16,
                        76.82,
                      ],
                      [
                        55.53,
                        77.75,
                      ],
                      [
                        54.6,
                        78.38,
                      ],
                      [
                        54.28,
                        78.44,
                      ],
                      [
                        54.28,
                        79.51,
                      ],
                      [
                        54.13,
                        80.26,
                      ],
                      [
                        53.7,
                        80.89,
                      ],
                      [
                        53.07,
                        81.32,
                      ],
                      [
                        52.32,
                        81.46,
                      ],
                      [
                        47.68,
                        81.46,
                      ],
                      [
                        46.93,
                        81.32,
                      ],
                      [
                        46.3,
                        80.89,
                      ],
                      [
                        45.87,
                        80.26,
                      ],
                      [
                        45.72,
                        79.51,
                      ],
                      [
                        45.72,
                        78.44,
                      ],
                      [
                        45.4,
                        78.38,
                      ],
                      [
                        44.47,
                        77.75,
                      ],
                      [
                        43.84,
                        76.82,
                      ],
                      [
                        43.61,
                        75.68,
                      ],
                      [
                        43.61,
                        65.15,
                      ],
                      [
                        43.83,
                        64.03,
                      ],
                      [
                        44.47,
                        63.08,
                      ],
                      [
                        45.42,
                        62.45,
                      ],
                      [
                        46.54,
                        62.22,
                      ],
                      [
                        53.46,
                        62.22,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        0,
                        0,
                        0,
                        255,
                      ],
                    },
                  ],
                },
              },
            ],
            scaleSymbolsProportionally: true,
            respectFrame: true,
            clippingPath: {
              type: 'CIMClippingPath',
              clippingType: 'Intersect',
              path: {
                rings: [
                  [
                    [
                      0,
                      0,
                    ],
                    [
                      100,
                      0,
                    ],
                    [
                      100,
                      100,
                    ],
                    [
                      0,
                      100,
                    ],
                    [
                      0,
                      0,
                    ],
                  ],
                ],
              },
            },
          },
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPoint: {
              x: 0,
              y: 0,
            },
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Y',
            size: 16,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 17,
              ymax: 17,
            },
            markerGraphics: markerCircleGraphic,
            scaleSymbolsProportionally: true,
            respectFrame: true,
          },
        ],
      },
    },
    Aircraft: {
      type: 'CIMSymbolReference',
      symbol: {
        type: 'CIMPointSymbol',
        symbolLayers: [
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Y',
            size: 16,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 21,
              ymax: 21,
            },
            markerGraphics: [
              {
                type: 'CIMMarkerGraphic',
                geometry: {
                  rings: [
                    [
                      [
                        11.9,
                        12.17,
                      ],
                      [
                        17.42,
                        8.13,
                      ],
                      [
                        18.03,
                        7.47,
                      ],
                      [
                        18.24,
                        6.86,
                      ],
                      [
                        18.24,
                        6.05,
                      ],
                      [
                        11.9,
                        8.48,
                      ],
                      [
                        11.7,
                        6.19,
                      ],
                      [
                        11.48,
                        4.82,
                      ],
                      [
                        12.94,
                        3.42,
                      ],
                      [
                        13.24,
                        3.1,
                      ],
                      [
                        13.35,
                        2.79,
                      ],
                      [
                        13.35,
                        2,
                      ],
                      [
                        10.5,
                        2.77,
                      ],
                      [
                        7.65,
                        2,
                      ],
                      [
                        7.65,
                        2.79,
                      ],
                      [
                        7.76,
                        3.1,
                      ],
                      [
                        8.06,
                        3.42,
                      ],
                      [
                        9.52,
                        4.82,
                      ],
                      [
                        9.3,
                        6.19,
                      ],
                      [
                        9.1,
                        8.48,
                      ],
                      [
                        2.76,
                        6.05,
                      ],
                      [
                        2.76,
                        6.86,
                      ],
                      [
                        2.97,
                        7.47,
                      ],
                      [
                        3.58,
                        8.13,
                      ],
                      [
                        9.1,
                        12.17,
                      ],
                      [
                        9.1,
                        15.76,
                      ],
                      [
                        9.19,
                        16.78,
                      ],
                      [
                        9.46,
                        17.68,
                      ],
                      [
                        9.92,
                        18.45,
                      ],
                      [
                        10.5,
                        19,
                      ],
                      [
                        11.08,
                        18.45,
                      ],
                      [
                        11.54,
                        17.68,
                      ],
                      [
                        11.81,
                        16.78,
                      ],
                      [
                        11.9,
                        15.76,
                      ],
                      [
                        11.9,
                        12.17,
                      ],
                    ],
                  ],
                },
                symbol: {
                  type: 'CIMPolygonSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidStroke',
                      enable: true,
                      capStyle: 'Round',
                      joinStyle: 'Round',
                      lineStyle3D: 'Strip',
                      miterLimit: 10,
                      width: 0,
                      color: [
                        90,
                        88,
                        88,
                        255,
                      ],
                    },
                    {
                      type: 'CIMSolidFill',
                      enable: true,
                      color: [
                        51,
                        51,
                        51,
                        255,
                      ],
                    },
                  ],
                },
              },
            ],
            scaleSymbolsProportionally: true,
            respectFrame: true,
          },
          {
            type: 'CIMVectorMarker',
            enable: true,
            anchorPoint: {
              x: 0,
              y: 0,
            },
            anchorPointUnits: 'Relative',
            dominantSizeAxis3D: 'Y',
            size: 16,
            billboardMode3D: 'FaceNearPlane',
            frame: {
              xmin: 0,
              ymin: 0,
              xmax: 17,
              ymax: 17,
            },
            markerGraphics: markerCircleGraphic,
            scaleSymbolsProportionally: true,
            respectFrame: true,
          },
        ],
      },
    },
  },
};
